//functional MUI datagrid component to display timecards for payroll

import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import { darken, lighten, styled } from '@mui/material/styles';
import CustomSelect from './../../styledcomponents/CustomSelect';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import threeWeekLookBack from '../../hooks/three-week-lookback';

const columns = [
  { field: 'id', headerName: 'ID', display: false, hidden: true },
  { field: 'firstName', headerName: 'First Name', width: 130 },
  { field: 'lastName', headerName: 'Last Name', width: 130 },
  { field: 'weekNumber', headerName: 'Week', width: 50 },
  {
    field: 'regHours',
    headerName: 'Reg',
    width: 50,
    renderCell: (params) => {
      return <div className='rowitem'>{params.row.calculations.regHours}</div>;
    },
  },
  {
    field: 'overtimeHours',
    headerName: 'OT',
    width: 50,
    renderCell: (params) => {
      return (
        <div className='rowitem'>{params.row.calculations.overtimeHours}</div>
      );
    },
  },
  {
    field: 'satHours',
    headerName: 'Sat',
    width: 50,
    renderCell: (params) => {
      return <div className='rowitem'>{params.row.calculations.satHours}</div>;
    },
  },
  {
    field: 'sunHours',
    headerName: 'Sun',
    width: 50,
    renderCell: (params) => {
      return <div className='rowitem'>{params.row.calculations.sunHours}</div>;
    },
  },
  {
    field: 'totalHours',
    headerName: 'Total',
    width: 50,
    renderCell: (params) => {
      return (
        <div className='rowitem'>{params.row.calculations.totalHours}</div>
      );
    },
  },
  { field: 'approved', headerName: 'Approved', width: 130 },
];

const ChooseTimeCardsForPayroll = ({
  timecards,
  isLoading,
  selectedDataID,
  setselectedDataID,
}) => {
  const { sixWkBkDesc } = threeWeekLookBack();
  const getBackgroundColor = (color, theme, coefficient) => ({
    backgroundColor: darken(color, coefficient),
    ...theme.applyStyles('light', {
      backgroundColor: lighten(color, coefficient),
    }),
  });
  const handleWeekChange = (e) => {
    console.log(e);
  };
  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--odd': {
      ...getBackgroundColor(theme.palette.info.main, theme, 0.7),
      '&:hover': {
        ...getBackgroundColor(theme.palette.info.main, theme, 0.6),
      },
      '&.Mui-selected': {
        ...getBackgroundColor(theme.palette.info.main, theme, 0.5),
        '&:hover': {
          ...getBackgroundColor(theme.palette.info.main, theme, 0.4),
        },
      },
    },
    '& .super-app-theme--true': {
      ...getBackgroundColor(theme.palette.success.main, theme, 0.7),
      '&:hover': {
        ...getBackgroundColor(theme.palette.success.main, theme, 0.6),
      },
      '&.Mui-selected': {
        ...getBackgroundColor(theme.palette.success.main, theme, 0.5),
        '&:hover': {
          ...getBackgroundColor(theme.palette.success.main, theme, 0.4),
        },
      },
    },
    '& .super-app-theme--even': {
      ...getBackgroundColor(theme.palette.warning.main, theme, 0.7),
      '&:hover': {
        ...getBackgroundColor(theme.palette.warning.main, theme, 0.6),
      },
      '&.Mui-selected': {
        ...getBackgroundColor(theme.palette.warning.main, theme, 0.5),
        '&:hover': {
          ...getBackgroundColor(theme.palette.warning.main, theme, 0.4),
        },
      },
    },
    '& .super-app-theme--false': {
      ...getBackgroundColor(theme.palette.error.main, theme, 0.7),
      '&:hover': {
        ...getBackgroundColor(theme.palette.error.main, theme, 0.6),
      },
      '&.Mui-selected': {
        ...getBackgroundColor(theme.palette.error.main, theme, 0.5),
        '&:hover': {
          ...getBackgroundColor(theme.palette.error.main, theme, 0.4),
        },
      },
    },
  }));

  return (
    <Box width='100%'>
      <Box width='100%' display='flex' justifyContent='center'>
        <Typography variant='h7' align='center'>
          Choose Timecards for Payroll
        </Typography>
      </Box>
      <Box width='100%' display='flex' justifyContent='center' sx={{ p: 1 }}>
        <CustomSelect
          value={sixWkBkDesc[5]}
          optionsArray={sixWkBkDesc.slice(4, 6)}
          handleChange={handleWeekChange}
        />

        <FormControlLabel
          control={<Checkbox size='small' />}
          label={
            <Typography variant='body2' color='textSecondary'>
              Approved
            </Typography>
          }
          sx={{ pl: 2 }}
        />
        <FormControlLabel
          control={<Checkbox size='small' />}
          label={
            <Typography variant='body2' color='textSecondary'>
              Incomplete
            </Typography>
          }
        />
      </Box>
      <Box width='100%' height='500px'>
        <StyledDataGrid
          rows={timecards}
          columns={columns}
          pageSize={20}
          loading={isLoading}
          checkboxSelection
          rowsPerPageOptions={[20, 50, 100]}
          //   error={error}
          keepNonExistentRowsSelected
          rowHeight={40}
          onSelectionModelChange={(row) => {
            console.log(row);
            setselectedDataID(row);
          }}
          selectionModel={selectedDataID}
          getRowClassName={(params) =>
            `super-app-theme--${params.row.id & 1 ? 'odd' : 'even'}`
          }
          {...timecards}
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: 'black',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
            borderRadius: 3,
            backgroundColor: '#3d3d3d',
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
              },
            },
          }}>
          <p> {selectedDataID} </p>
        </StyledDataGrid>
      </Box>
    </Box>
  );
};

export default ChooseTimeCardsForPayroll;
