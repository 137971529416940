import { useState, useEffect } from 'react';
import DashBoardBox from '../components/DashBoardBox';
import AnalyticsBoxHeader3 from '../components/AnalyticsBoxHeader3';
import { Box, Stack, useTheme, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../theme';
import Alert from '@mui/material/Alert';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Pie,
  PieChart,
  Cell,
  Legend,
  Sector,
} from 'recharts';
import { BarChart } from '@mui/x-charts/BarChart';
import dayjs from 'dayjs';
import { useAuth } from '../context/AuthProvider';
import { projectAnalytics } from '../utils/projectAnalytics';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import threeWeekLookBack from '../hooks/three-week-lookback';
import filteredBarGraphData from '../hooks/fx-create-bar-graph-array';
import CustomSelect from '../styledcomponents/CustomSelect';

const Row1 = ({ timecardData, error, errMsg, unfilteredData, label }) => {
  console.log('Row1.js timecardData:', timecardData);
  const start = dayjs().week(label).day(0).format('MM/DD/YYYY');
  const end = dayjs().week(label).day(6).format('MM/DD/YYYY');
  const NewLabel = `Sun (${start}) - Sat (${end})`;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [tcDataRendered, settcDataRendered] = useState([]);
  const [activeIndex, setactiveIndex] = useState({
    activeIndex: 0,
  });
  const { user, setUser } = useAuth();
  const [weekSelect, setWeekSelect] = useState('Past 6 Weeks');
  const weeksObject = threeWeekLookBack();
  const handleChange = (event) => {
    setWeekSelect(event.target.value);
  };
  const { sixWkBkDesc } = threeWeekLookBack();

  useEffect(() => {
    //setAllTimecards(data);
    let renderedData = [];
    let bargraphData = [];
    let result;
    //map through array of objectsw
    if (timecardData.length > 0) {
      renderedData = timecardData.map((timecard) => ({
        id: timecard._id,
        weekEnd: dayjs(timecard.weekEnd).format('MM/DD/YYYY'),
        regularHours: timecard.regHours,
        OTHours: timecard.overtimeHours,
        saturdayHours: timecard.satHours,
        sundayHours: timecard.sunHours,
      }));
      bargraphData = timecardData.map((timecard) => ({
        id: timecard._id,
        weekEnd: dayjs(timecard.weekEnd).format('MM/DD/YYYY'),
        regHours: timecard.regHours,
        overtimeHours: timecard.overtimeHours,
        satHours: timecard.satHours,
        sunHours: timecard.sunHours,
      }));
      result = filteredBarGraphData(sixWkBkDesc, bargraphData);
      settcDataRendered(result);
    }
  }, [timecardData, user]);

  const transactionColumns = [
    {
      field: 'date',
      headerName: 'Week Ending',
      flex: 0.4,
    },
    {
      field: 'regHours',
      headerName: 'Reg',
      flex: 0.2,
      renderCell: (params) => `${params.value}`,
    },
    {
      field: 'OTHours',
      headerName: 'OT',
      flex: 0.2,
      renderCell: (params) => `${params.value}`,
    },
    {
      field: 'saturdayHours',
      headerName: 'Sat',
      flex: 0.2,
      renderCell: (params) => `${params.value}`,
    },
    {
      field: 'sundayHours',
      headerName: 'Sun',
      flex: 0.2,
      renderCell: (params) => `${params.value}`,
    },
  ];

  const returnArray = projectAnalytics(unfilteredData);

  const COLORS = [
    colors.greenAccent[500],
    colors.redAccent[500],
    colors.blueAccent[500],
    colors.grey[500],
  ];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill='none'
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill='#333'>{`PV ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill='#999'>
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const onPieEnter = (index) => {
    setactiveIndex({
      activeIndex: index,
    });
  };

  return (
    <>
      <DashBoardBox gridArea='a' theme={theme} colors={colors}>
        <AnalyticsBoxHeader3
          title={`Total Timecards Past 6 Weeks`}
          subtitle='Datagrid'
          sidetext='+0%'
        />

        {errMsg ? <Alert severity='error'>{errMsg}</Alert> : null}
        <Box
          mt='0.5rem'
          p='0 0.5rem'
          height='80%'
          sx={{
            '& .MuiDataGrid-root': {
              color: `${colors.grey[300]}`,
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid ${colors.grey[800]} !important`,
            },
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'hidden',
            },
          }}>
          {timecardData.length > 0 ? (
            <DataGrid
              rowHeight={25}
              columnHeaderHeight={25}
              hideFooter={true}
              rows={tcDataRendered}
              columns={transactionColumns}
            />
          ) : (
            <div />
          )}
        </Box>
      </DashBoardBox>
      <DashBoardBox gridArea='b' theme={theme} colors={colors}>
        <Stack direction='row' spacing={1} justifyContent='space-between'>
          <AnalyticsBoxHeader3
            title={`Total Timecards Past 6 Weeks`}
            subtitle='Bar Graph'
          />
          <Box sx={{ width: '110px', py: 1 }}>
            <CustomSelect
              value='option1'
              optionsArray={['option1', 'option2']}
            />
          </Box>
        </Stack>
        {errMsg ? <Alert severity='error'>{errMsg}</Alert> : null}

        {timecardData.length > 0 ? (
          <ResponsiveContainer width='100%' height='70%'>
            <BarChart
              series={[
                { data: [35, 44, 24, 34] },
                { data: [51, 6, 49, 30] },
                { data: [15, 25, 30, 50] },
                { data: [60, 50, 15, 25] },
              ]}
              height={290}
              xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            />
          </ResponsiveContainer>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}></Box>
        )}
      </DashBoardBox>
      <DashBoardBox gridArea='c' theme={theme} colors={colors}>
        <Stack direction='row' maxWidth='300px' spacing={1}>
          <Box>
            <AnalyticsBoxHeader3
              title='Breakdown'
              subtitle='Project Analysis'
            />
          </Box>
          <Box sx={{ width: '120px', p: 1 }}>
            <CustomSelect value={sixWkBkDesc[4]} optionsArray={sixWkBkDesc} />
          </Box>
          {/* <Box sx={{ width: '120px', p: 1 }}>
            <CustomSelect value={sixWkBkDesc[5]} optionsArray={sixWkBkDesc} />
          </Box> */}
        </Stack>
        {errMsg ? <Alert severity='error'>{errMsg}</Alert> : null}
        <Box
          mt='0.5rem'
          p='0 0.5rem'
          height='75%'
          sx={{
            '& .MuiDataGrid-root': {
              color: `${colors.grey[300]}`,
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid ${colors.grey[800]} !important`,
            },
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'hidden',
            },
          }}>
          <ResponsiveContainer width='80%%' height='70%'>
            <PieChart>
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={returnArray}
                cx='50%'
                cy='50%'
                innerRadius={50}
                outerRadius={70}
                label
                stroke='none'
                sx={{ border: 'black' }}
                dataKey='totalHours'>
                {returnArray.map((entry, index) => (
                  <Cell
                    name={entry.project}
                    key={`cell-${entry} ${index}`}
                    fill={COLORS[index % COLORS.length]}
                    sx={{ border: 'none' }}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </DashBoardBox>
    </>
  );
};

export default Row1;
