import { Box, Typography } from "@mui/material";

const TimeClockLabels = () => {
	return (
		<Box align="right" sx={{ color: "grey", width: "30%", minWidth: "100px" }}>
			<Typography> Device Name </Typography>
			<Typography> Project </Typography>
			<Typography> Date </Typography>
			<Typography> Project Start </Typography>
			<Typography> Project End </Typography>
			<Typography> Week Ending </Typography>
			<Typography> Max Time </Typography>
			<Typography> Current Time </Typography>
			<Typography> Status </Typography>
			<Typography> Display </Typography>
		</Box>
	);
};

export default TimeClockLabels;
