import * as React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { red, green, blue } from "@mui/material/colors";

const Root = styled("div")(({ theme }) => ({
	padding: theme.spacing(1),
	[theme.breakpoints.down("md")]: {
		color: red[500],
	},
	[theme.breakpoints.up("md")]: {
		color: blue[500],
	},
	[theme.breakpoints.up("lg")]: {
		color: green[500],
	},
}));

export default Root;
