import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, Stack } from '@mui/material';
import { tokens } from '../theme';

const AnalyticsBoxHeader = ({ title, subtitle, sidetext, color }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const defaultGrey = colors.grey[400];
  const [subtitleColor, setSubtitleColor] = useState(defaultGrey);

  useEffect(() => {
    if (color) {
      setSubtitleColor(color);
    }
  }, [color]);

  return (
    <Box sx={{ p: 1 }}>
      <Stack direction='row'>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography color={colors.grey[100]}> {title} </Typography>
          <Typography color={subtitleColor} variant={'joysmall'}>
            {' '}
            {subtitle}{' '}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default AnalyticsBoxHeader;
