import { useState, useEffect } from "react";

function TimeClockElaspedDisplay2({ start }) {
	const [elapsedTime2, setElapsedTime2] = useState({
		hours: 0,
		minutes: 0,
		seconds: 0,
	});

	useEffect(() => {
		const intervalId = setInterval(() => {
			const startTime = start;
			const timeDelta = Date.now() - Date.parse(startTime);

			setElapsedTime2({
				hours: Math.floor((timeDelta / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((timeDelta / 1000 / 60) % 60),
				seconds: Math.floor((timeDelta / 1000) % 60),
			});
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	const convertToTwoDigit = (number) => {
		return number.toLocaleString("en-US", {
			minimumIntegerDigits: 2,
		});
	};

	return (
		<div>
			<div>
				<span>
					{elapsedTime2 ? convertToTwoDigit(elapsedTime2.hours) : null}:
				</span>
				<span>{convertToTwoDigit(elapsedTime2.minutes)}:</span>
				<span>{convertToTwoDigit(elapsedTime2.seconds)}</span>
			</div>
		</div>
	);
}

export default TimeClockElaspedDisplay2;
