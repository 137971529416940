import LandingLayout from '../layouts/LandingLayout';
import LandingSection from '../modules/landing/LandingSection';
import Section1 from '../landingpage/Section1';
import Section2 from '../landingpage/Section2';
import { Box } from '@mui/material';

const LandingPage = () => {
  return (
    <LandingLayout>
      <LandingSection />
      <Box
        sx={{
          bgcolor: 'transparent',
          position: 'relative',
          margin: 'auto',
        }}>
        {/*Count Down*/}
        <Box sx={{ height: '100vh' }}>
          <Section1 />
        </Box>
        {/*Learn More*/}
        <Section2 />
      </Box>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '400px',
          padding: '100px 20vw',
          bgcolor: 'primary.main',
        }}></Box>
    </LandingLayout>
  );
};

export default LandingPage;
