import Popover from "@mui/material/Popover";
import { Box, Typography } from "@mui/material";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import StopIcon from "@mui/icons-material/Stop";
import React, { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { handleClickEdit } from "../utils/timeclock/timeclockpopover";
import EditPayrollPopOver from "./EditPayrollPopOver";
import user from "../context/AuthProvider";

const TimeClockOptionsPopover = ({
	idPopOut,
	openPopOut,
	anchorElPopOut,
	setAnchorElPopOut,
	details,
	handleOpen,
	settimeclockPopoverDetails,
}) => {
	const [showActiveButtons, setShowActiveButtons] = useState(true);
	const [showInActiveButtons, setShowInctiveButtons] = useState(true);
	const handlePopOutClose = () => {
		setAnchorElPopOut(null);
	};
	//Payroll Edit PopOver
	const [anchorElEditPayrollPopOut, setanchorElEditPayrollPopOut] =
		useState(null);
	const openEditPayrollPopOut = Boolean(anchorElEditPayrollPopOut);
	const idEditPayrollPopOut = openEditPayrollPopOut ? "Pop Out" : undefined;
	const [showPayrollEdit, setShowPayrollEdit] = useState(false);

	useEffect(() => {
		if (details?.startTime) {
			const start = dayjs(details.startTime).unix();
			const now = dayjs().unix();
			const elapsed = now - start;
			//86400 is 24 hours in unix time
			if (elapsed > 86400) {
				setShowActiveButtons(false);
				setShowInctiveButtons(true);
			} else {
				setShowActiveButtons(true);
				setShowInctiveButtons(false);
			}
		}
	}, [details]);
	//   const id = open ? 'simple-popover' : undefined;

	// const handlePopOutClickEditPayroll = (event, details) => {
	// 	setanchorElEditPayrollPopOut(event.currentTarget);
	// 	setShowPayrollEdit(true);
	// 	//setpopOverEditDetails(details.row.row);
	// };

	return (
		<Box sx={{ ml: "-350px" }}>
			{/* <Button aria-describedby={id} onClick={handleClick}>
        Query
      </Button> */}
			<Popover
				id={idPopOut}
				open={openPopOut}
				anchorEl={anchorElPopOut}
				onClose={handlePopOutClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Paper sx={{ width: 100 }}>
					<MenuList dense>
						{/* {showActiveButtons ? (
							<MenuItem>
								<ListItemIcon>
									<PlayArrowIcon />
								</ListItemIcon>
								<ListItemText>Resume</ListItemText>
							</MenuItem>
						) : null} */}
						{showInActiveButtons ? (
							<MenuItem
								onClick={() => {
									handleOpen();
									settimeclockPopoverDetails(details);
								}}
							>
								<ListItemIcon>
									<EditIcon />
								</ListItemIcon>
								<ListItemText>Edit</ListItemText>
							</MenuItem>
						) : null}
						{showActiveButtons ? (
							<MenuItem
								onClick={() => {
									handleOpen();
									settimeclockPopoverDetails(details);
								}}
							>
								<ListItemIcon>
									<StopIcon />
								</ListItemIcon>
								<ListItemText>End</ListItemText>
							</MenuItem>
						) : null}
						<MenuItem>
							<ListItemIcon>
								<ClearIcon />
							</ListItemIcon>
							<ListItemText>Delete</ListItemText>
						</MenuItem>

						{showActiveButtons ? (
							<MenuItem>
								<ListItemIcon>
									<PendingActionsIcon />
								</ListItemIcon>
								<ListItemText>Approve</ListItemText>
							</MenuItem>
						) : null}
					</MenuList>
				</Paper>
				{/* {showPayrollEdit ? (
					<div>
						<EditPayrollPopOver
							details={details}
							anchorElPopOut={anchorElEditPayrollPopOut}
							openPopOut={openEditPayrollPopOut}
							idPopOut={idEditPayrollPopOut}
							setAnchorElPopOut={setanchorElEditPayrollPopOut}
							user={user}
						/>
					</div>
				) : null} */}
			</Popover>
		</Box>
	);
};

export default TimeClockOptionsPopover;
