import StickyHeader from '../components/StickyHeader';
import { Box, Grid } from '@mui/material';
import Dashboard3 from './Dashboard3';

function MobileAdminDashboard() {
  return (
    <Box sx={{ width: '100%', minWidth: '390px' }}>
      <StickyHeader />
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        sx={{ minHeight: '100vh' }}>
        <Grid item xs={3}>
          <Box sx={{ width: '390px', height: '50dvh', p: 0, m: 0 }}>
            <Dashboard3 />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MobileAdminDashboard;
