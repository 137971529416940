import * as React from "react";
import Sheet from "@mui/joy/Sheet";
import { Typography } from "@mui/joy";
import { useTheme } from "@mui/material";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { personnel } from "../../fakedata/mockData";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";

const DesktopPayrollCreate = () => {
	const theme = useTheme();
	const renderFilters = () => (
		<React.Fragment>
			<FormControl size="sm">
				<FormLabel>Project 1</FormLabel>
				<Select
					size="sm"
					placeholder="Project 1"
					slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
				>
					<Option value="Rio Vista">Rio Vista</Option>
					<Option value="pending">Pending</Option>
					<Option value="refunded">Refunded</Option>
					<Option value="cancelled">Cancelled</Option>
				</Select>
			</FormControl>
			<FormControl size="sm">
				<FormLabel>Project 2</FormLabel>
				<Select size="sm" placeholder="All">
					<Option value="all">All</Option>
					<Option value="refund">Refund</Option>
					<Option value="purchase">Purchase</Option>
					<Option value="debit">Debit</Option>
				</Select>
			</FormControl>
			<FormControl size="sm">
				<FormLabel>Project 3</FormLabel>
				<Select size="sm" placeholder="All">
					<Option value="all">All</Option>
					{personnel &&
						personnel.map((person) => (
							<Option key={person.id} value={person.fullName}>
								{person.fullName}
							</Option>
						))}
				</Select>
			</FormControl>
		</React.Fragment>
	);
	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />
			<Sheet
				className="OrderTableContainer"
				variant="outlined"
				sx={{
					display: { xs: "none", sm: "initial" },
					width: "100%",
					borderRadius: "sm",
					flexShrink: 1,
					overflow: "auto",
					minHeight: 0,
				}}
			>
				<Tabs defaultValue={0} sx={{ bgcolor: "transparent" }}>
					<TabList
						tabFlex={1}
						size="sm"
						sx={{
							pl: { xs: 0, md: 4 },
							justifyContent: "left",
							[`&& .${tabClasses.root}`]: {
								fontWeight: "600",
								flex: "initial",
								color: "text.tertiary",
								[`&.${tabClasses.selected}`]: {
									bgcolor: "transparent",
									color: "text.primary",
									"&::after": {
										height: "2px",
										bgcolor: "primary.500",
									},
								},
							},
						}}
					>
						<Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={0}>
							1 project
						</Tab>
						<Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={1}>
							2 projects
						</Tab>
						<Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={2}>
							3 projects
						</Tab>
					</TabList>
				</Tabs>
				<Box
					className="SearchAndFilters-tabletUp"
					sx={{
						borderRadius: "sm",
						justifyContent: "center",
						py: 2,
						display: { xs: "none", sm: "flex" },
						flexWrap: "wrap",
						gap: 1.5,
						"& > *": {
							minWidth: { xs: "120px", md: "160px" },
						},
					}}
				>
					{renderFilters()}
				</Box>
			</Sheet>
		</CssVarsProvider>
	);
};

export default DesktopPayrollCreate;
