/** @jsxImportSource @emotion/react */
import { styled } from "@mui/material/styles";

const PivotBox = styled("div")(({ theme }) => ({
	display: "flex",
	marginBottom: theme.spacing(1),
	gap: theme.spacing(1),
	flexDirection: "column", // Default for xs breakpoint
	alignItems: "start",
	flexWrap: "wrap",
	justifyContent: "space-between",
	[theme.breakpoints.up("md")]: {
		flexDirection: "row", // Apply row for sm and larger
		alignItems: "center",
	},
}));

export default PivotBox;
