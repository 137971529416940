import React from 'react';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { AiOutlineUser } from 'react-icons/ai';
import { Box, Typography, useTheme, Stack, MenuItem } from '@mui/material';
import { tokens } from '../theme';
import { HiOutlineBriefcase, HiOutlineClipboardList } from 'react-icons/hi';
import Select from '@mui/material/Select';
import { initialRole } from '../hooks/initial-class-role';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import usePath from '../hooks/fetchPath';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

const EditPersonnelModal = ({ open, handleClose, person }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const { api } = useAuth();
  const defaultStartTime = dayjs().hour(7).minute(0);
  const defaultEndTime = dayjs().hour(15).minute(30);
  const [role, setRole] = useState(person?.role);
  const [classification, setClassification] = useState(person?.classification);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [sundaystart, setsundaystart] = useState(null);
  const [sundayend, setsundayend] = useState(null);
  const [mondaystart, setmondaystart] = useState(defaultStartTime);
  const [mondayend, setmondayend] = useState(defaultEndTime);
  const [tuesdaystart, settuesdaystart] = useState(defaultStartTime);
  const [tuesdayend, settuesdayend] = useState(defaultEndTime);
  const [wednesdaystart, setwednesdaystart] = useState(defaultStartTime);
  const [wednesdayend, setwednesdayend] = useState(defaultEndTime);
  const [thursdaystart, setthursdaystart] = useState(defaultStartTime);
  const [thursdayend, setthursdayend] = useState(defaultEndTime);
  const [fridaystart, setfridaystart] = useState(defaultStartTime);
  const [fridayend, setfridayend] = useState(defaultEndTime);
  const [saturdaystart, setsaturdaystart] = useState(null);
  const [saturdayend, setsaturdayend] = useState(null);
  const [statusMsg, setstatusMsg] = useState(null);
  const [isErr, setIsErr] = useState(false);
  const [sent, setsent] = useState(false);
  const { fullPathProjects, baseURL } = usePath('/api/v1/projects/');

  useEffect(() => {
    if (person) {
      const names = person?.fullName?.split(',');
      setFirstName(names?.[1].trim());
      setLastName(names?.[0].trim());
      setRole(person.role);
      setClassification(person.classification);
      setsundaystart(person.sunStart !== null ? dayjs(person.sunStart) : null);
      setsundayend(person.sunEnd !== null ? dayjs(person.sunEnd) : null);
      setmondaystart(person.monStart !== null ? dayjs(person.monStart) : null);
      setmondayend(person.monEnd !== null ? dayjs(person.monEnd) : null);
      settuesdaystart(person.tueStart !== null ? dayjs(person.tueStart) : null);
      settuesdayend(person.tueEnd !== null ? dayjs(person.tueEnd) : null);
      setwednesdaystart(
        person.wedStart !== null ? dayjs(person.wedStart) : null
      );
      setwednesdayend(person.wedEnd !== null ? dayjs(person.wedEnd) : null);
      setthursdaystart(
        person.thurStart !== null ? dayjs(person.thurStart) : null
      );
      setthursdayend(person.thurEnd !== null ? dayjs(person.thurEnd) : null);
      setfridaystart(person.friStart !== null ? dayjs(person.friStart) : null);
      setfridayend(person.friEnd !== null ? dayjs(person.friEnd) : null);
      setsaturdaystart(
        person.satStart !== null ? dayjs(person.satStart) : null
      );
      setsaturdayend(person.satEnd !== null ? dayjs(person.satEnd) : null);
    }
  }, [person]);

  //Form Styles
  const formStyles = {
    backgroundColor: colors.primary[400],
    '&:hover': {
      border: '.1px solid',
      borderRadius: 1,
      borderColor: colors.greenAccent[200],
    },
    input: { color: colors.greenAccent[300] },
    label: { color: colors.greenAccent[300] },
    width: '185px',
  };

  let typeClassification = null;
  let optionsClassification = null;

  const carpenter = [
    'Carpenters: Journeyman',
    'Carpenters: Foreman',
    'Carpenters: Apprentice 1',
    'Carpenters: Apprentice 2',
    'Carpenters: Apprentice 3',
    'Carpenters: Apprentice 4',
    'Carpenters: Apprentice 5',
    'Carpenters: Apprentice 6',
    'Carpenters: Apprentice 7',
    'Carpenters: Apprentice 8',
  ];

  const electrician = [
    'Inside Wireman',
    'Cable Splicer Welder',
    'Comm Installer',
  ];

  const laborer = [
    'Group 1 & 1B',
    'Group 1(A)',
    'Group 1(C)',
    'Group 1(E)',
    'Group 1(G)',
    'Group 2',
    'Group 3 & 3(A)',
    'Group 4 & 6(B)',
    'Group 6',
    'Group 6(A)',
    'Group 6(C)',
    'Group 6(D)',
    'Group 6(E)',
    'Group 7 - Stage 1 (1st 6 Months)',
    'Group 7 - Stage 2 (2nd 6 Months)',
    'Group 7 - Stage 3 (3rd 6 Months)',
  ];

  const electricianApprentice = [
    'Electrician Apprentice 1',
    'Electrician Apprentice 2',
    'Electrician Apprentice 3',
    'Electrician Apprentice 4',
    'Electrician Apprentice 5',
    'Electrician Apprentice 6',
    'Electrician Apprentice 7',
    'Electrician Apprentice 8',
    'Electrician Apprentice 9',
    'Electrician Apprentice 10',
  ];

  const electricalComm = ['Comm & System Installer', 'Comm & System Tech'];

  const taper = ['Taper'];

  const plasterer = ['Plasterer'];
  const drywall = ['Drywall'];

  const cementMason = ['Cement Mason'];
  const projectManager = ['Project Manager'];

  const officeAdmin = ['Office Admin', 'Biweekly'];

  if (role === 'Carpenter') {
    typeClassification = carpenter;
  } else if (role === 'Electrician') {
    typeClassification = electrician;
  } else if (role === 'Laborer') {
    typeClassification = laborer;
  } else if (role === 'Electrician Apprentice') {
    typeClassification = electricianApprentice;
  } else if (role === 'ElectricalComm') {
    typeClassification = electricalComm;
  } else if (role === 'Taper') {
    typeClassification = taper;
  } else if (role === 'Plasterer') {
    typeClassification = plasterer;
  } else if (role === 'Drywall') {
    typeClassification = drywall;
  } else if (role === 'Project Manager') {
    typeClassification = projectManager;
  } else if (role === 'Cement Mason') {
    typeClassification = cementMason;
  } else if (role === 'Office Admin') {
    typeClassification = officeAdmin;
  } else {
    typeClassification = [];
  }

  if (typeClassification) {
    optionsClassification = typeClassification;
  }

  const CreateTimePicker = ({
    startlabel,
    endlabel,
    start,
    setstart,
    end,
    setend,
  }) => {
    return (
      <Box
        gridColumn='span 6'
        backgroundColor={colors.primary[400]}
        display='flex'
        alignItems='center'
        justifyContent='center'
        sx={{ p: 1 }}>
        <Stack>
          <Typography> {startlabel} </Typography>
          <Box sx={{ mb: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                {...start}
                value={start}
                renderInput={(params) => {
                  return <TextField {...params} />;
                }}
                components={{
                  OpenPickerIcon: (props) => <AccessTimeIcon {...props} />,
                }}
                onChange={(e) => {
                  setstart(e);
                }}
                referenceDate={start}
                componentsProps={{
                  actionBar: {
                    actions: ['clear', 'accept'],
                  },
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    m: 0,
                    p: 0,
                    color: 'black',
                  },
                  '& .MuiOutlinedInput-input': {
                    color: colors.greenAccent[300],
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Stack>
        <Stack>
          <Typography> {endlabel} </Typography>
          <Box sx={{ mb: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                {...end}
                value={end}
                renderInput={(params) => {
                  return <TextField {...params} />;
                }}
                onChange={(e) => {
                  setend(e);
                }}
                components={{
                  OpenPickerIcon: (props) => <AccessTimeIcon {...props} />,
                }}
                referenceDate={end}
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    m: 0,
                    p: 0,
                    color: 'black',
                  },
                  '& .MuiOutlinedInput-input': {
                    color: colors.greenAccent[300],
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Stack>
      </Box>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitPersonnel();
  };

  const submitPersonnel = async () => {
    setsent(true);
    try {
      await api.put(
        baseURL + `/api/v1/personnel/${person.id}`,
        JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          role: role,
          classification: classification,
          sunStart: sundaystart,
          sunEnd: sundayend,
          monStart: mondaystart,
          monEnd: mondayend,
          tueStart: tuesdaystart,
          tueEnd: tuesdayend,
          wedStart: wednesdaystart,
          wedEnd: wednesdayend,
          thurStart: thursdaystart,
          thurEnd: thursdayend,
          friStart: fridaystart,
          friEnd: fridayend,
          satStart: saturdaystart,
          satEnd: saturdayend,
        })
      );
      setIsErr(false);
      setstatusMsg('Success!');
      handleClose();
    } catch (error) {
      setIsErr(true);
      setstatusMsg(error.message);
    }
  };

  function SimpleAlert() {
    return (
      <Alert icon={<CheckIcon fontSize='inherit' />} severity='success'>
        Success! Setttings will be updated on next page load.
      </Alert>
    );
  }

  function ErrorAlert({ message }) {
    return (
      <Alert icon={<CheckIcon fontSize='inherit' />} severity='error'>
        {message}
      </Alert>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={style}>
        <Typography variant='h6' sx={{ my: '15px' }}>
          {'Edit Personnel Settings For: '}
          {person?.fullName}
        </Typography>
        <Box
          component='div'
          sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <label htmlFor='firstName'>
            {' '}
            <AiOutlineUser size={25} />{' '}
          </label>
          <TextField
            id='firstname'
            label='First Name'
            variant='outlined'
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            required
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            style={{ minWidth: '185px' }}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            sx={formStyles}></TextField>
        </Box>
        <Box
          component='div'
          sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <label htmlFor='lastName'>
            {' '}
            <AiOutlineUser size={25} />{' '}
          </label>
          <TextField
            id='lastname'
            label='Last Name'
            variant='outlined'
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            required
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            style={{ minWidth: '185px' }}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            sx={formStyles}></TextField>
        </Box>

        <Box
          component='div'
          sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <label htmlFor='role'>
            {' '}
            <HiOutlineBriefcase size={25} />{' '}
          </label>
          <Select
            labelId='Role-select-label'
            id='role select'
            value={role}
            onChange={(e) => {
              setRole(e.target.value);
            }}
            label='Start'
            size='small'
            defaultValue={role}
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            style={{ minWidth: '185px', textalign: 'left' }}
            sx={formStyles}>
            {initialRole.map((role, index) => {
              return (
                <MenuItem key={index} value={role}>
                  {role}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        <Box
          component='div'
          sx={{
            display: 'flex',
            align: 'left',
            width: '100%',
            alignItems: 'center',
          }}>
          <label htmlFor='role'>
            {' '}
            <HiOutlineClipboardList size={25} />{' '}
          </label>
          <Select
            labelId='Classification-select-label'
            id='classification select'
            placeholder='Select a classification'
            value={classification}
            onChange={(e) => {
              setClassification(e.target.value);
            }}
            label='Start'
            size='small'
            style={{ minWidth: '185px' }}
            sx={formStyles}>
            {optionsClassification.map((classificationitem, index) => {
              return (
                <MenuItem key={index} value={classificationitem}>
                  {classificationitem}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        {/* Sunday */}
        <CreateTimePicker
          startlabel='Sun Start'
          endlabel='Sun End'
          start={sundaystart}
          setstart={setsundaystart}
          end={sundayend}
          setend={setsundayend}
        />

        {/* Monday */}
        <CreateTimePicker
          startlabel='Mon Start'
          endlabel='Mon End'
          start={mondaystart}
          setstart={setmondaystart}
          end={mondayend}
          setend={setmondayend}
        />

        {/* Tuesday */}
        <CreateTimePicker
          startlabel='Tue Start'
          endlabel='Tue End'
          start={tuesdaystart}
          setstart={settuesdaystart}
          end={tuesdayend}
          setend={settuesdayend}
        />

        {/* Wednesday */}
        <CreateTimePicker
          startlabel='Wed Start'
          endlabel='Wed End'
          start={wednesdaystart}
          setstart={setwednesdaystart}
          end={wednesdayend}
          setend={setwednesdayend}
        />

        {/* Thursday */}
        <CreateTimePicker
          startlabel='Thur Start'
          endlabel='Thur End'
          start={thursdaystart}
          setstart={setthursdaystart}
          end={thursdayend}
          setend={setthursdayend}
        />

        {/* Friday */}
        <CreateTimePicker
          startlabel='Fri Start'
          endlabel='Fri End'
          start={fridaystart}
          setstart={setfridaystart}
          end={fridayend}
          setend={setfridayend}
        />

        {/* Saturday */}
        <CreateTimePicker
          startlabel='Sat Start'
          endlabel='Sat End'
          start={saturdaystart}
          setstart={setsaturdaystart}
          end={saturdayend}
          setend={setsaturdayend}
        />
        {sent && !isErr ? <SimpleAlert /> : null}
        {sent && isErr ? <ErrorAlert message='Error!' /> : null}

        {person ? (
          <Button
            variant='contained'
            sx={{ mt: '10px' }}
            onClick={handleSubmit}>
            Save
          </Button>
        ) : (
          <Button variant='contained' disabled sx={{ mt: '10px' }}>
            Save
          </Button>
        )}

        {/* End of Container Box*/}
      </Box>
    </Modal>
  );
};

export default EditPersonnelModal;
