//input array
//output array
//constraints
//edge cases
//strategy

export const searchKeyValues = (searchThisArray, key) => {
  return searchThisArray
    .map((obj) => obj[key])
    .filter((value) => value !== undefined);
};

export const updateObjectInArray = (id, updatedProperties, setPageState) => {
  console.log('id', id);
  console.log('updatedProperties', updatedProperties);
  console.log('setPageState', setPageState);
  setPageState((prevState) => ({
    ...prevState,
    data: prevState.data.map(
      (item) =>
        item.id === id
          ? { ...item, ...updatedProperties } // Merge the existing object with updated properties
          : item // Return the same item if it's not the one being updated
    ),
  }));
};
