import { useMemo, useState, useEffect } from 'react';
import DashBoardBox from '../components/DashBoardBox';
import AnalyticsBoxHeader from '../components/AnalyticsBoxHeader';
import { useTheme, Box, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../theme';
import Alert from '@mui/material/Alert';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  LineChart,
  Legend,
  Bar,
  BarChart,
} from 'recharts';
import useFetch from '../hooks/fetchAPI';
import { fakePayrollCosts } from '../fakedata/mockData';
import { useAuth } from '../context/AuthProvider';
import AnalyticsBoxHeader3 from '../components/AnalyticsBoxHeader3';
import CustomSelect from '../styledcomponents/CustomSelect';

const Row2 = ({ listData, swData, error, errMsg }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [options, setOptions] = useState([]);
  const { api } = useAuth();
  const [swDataInverted, setSwDataInverted] = useState([]);
  console.log(swData);
  const renderedColumns = useMemo(() => {
    const headers = [
      'key',
      'month',
      'year',
      'reg',
      'ot',
      'sat',
      'sun',
      'totalHours',
    ];
    //create columns for each trade
    const columns = headers.map((elem) => {
      return {
        field: elem,
        headerName: elem,
        flex: 0.4,
      };
    });
    return columns;
  }, []);

  const COLORS = [
    colors.greenAccent[500],
    colors.redAccent[500],
    colors.blueAccent[500],
    colors.grey[500],
  ];

  useEffect(() => {
    if (swData) {
      setSwDataInverted(swData.slice().reverse());
    }
  }, [swData]);
  return (
    <>
      <DashBoardBox gridArea='d' theme={theme} colors={colors}>
        <Stack direction='row' spacing={2} justifyContent='space-between'>
          <AnalyticsBoxHeader3 title='Total Hours' subtitle='By Month' />
          <Box sx={{ p: 1 }}>
            <CustomSelect
              value={listData?.projectList?.data.data[0]}
              optionsArray={listData?.projectList?.data.data}
            />
          </Box>
        </Stack>
        {error?.sw ? <Alert severity='error'>{errMsg.sw}</Alert> : null}

        {
          //if there is no data, show nothing, else show the
          //DataGrid with the data
          swData && swData.length === 0 ? (
            <Box height='0%' />
          ) : (
            <ResponsiveContainer width='95%' height='80%'>
              <Box
                p='0 0.5rem'
                height='85%'
                sx={{
                  '& .MuiDataGrid-root': {
                    color: `${colors.grey[300]}`,
                    border: 'none',
                  },
                  '& .MuiDataGrid-cell': {
                    borderBottom: `1px solid ${colors.grey[800]} !important`,
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    visibility: 'hidden',
                  },
                }}>
                <DataGrid
                  rowHeight={20}
                  columnHeaderHeight={25}
                  hideFooter={true}
                  rows={swData || []}
                  columns={renderedColumns || []}
                  pageSizeOptions={[-1]}
                />
              </Box>
            </ResponsiveContainer>
          )
        }
      </DashBoardBox>
      <DashBoardBox gridArea='e' theme={theme} colors={colors}>
        <AnalyticsBoxHeader3 title='Total Hours' subtitle='Line Graph' />
        {error?.sw ? <Alert severity='error'>{errMsg.sw}</Alert> : null}
        {swData && swData.length === 0 ? (
          <Box height='0%' />
        ) : (
          <ResponsiveContainer width='100%' height='80%'>
            <LineChart
              width={300}
              height={200}
              data={swDataInverted}
              margin={{
                top: 15,
                right: 0,
                left: 0,
                bottom: 20,
              }}>
              <CartesianGrid vertical={false} stroke={colors.grey[700]} />
              <XAxis dataKey='key' stroke={colors.grey[100]} />
              <YAxis
                yAxisId='left'
                tickLine={false}
                axisLine={false}
                stroke={colors.grey[100]}
              />
              <YAxis
                yAxisId='right'
                orientation='right'
                tickLine={false}
                axisLine={false}
                stroke={colors.grey[100]}
              />
              <Tooltip />
              <Legend
                height={20}
                wrapperStyle={{
                  margin: '0 0 10px 0',
                }}
              />
              <Line
                yAxisId='left'
                type='monotone'
                dataKey='totalHours'
                stroke={colors.greenAccent[500]}
              />
              <Line
                yAxisId='right'
                type='monotone'
                dataKey='ot'
                stroke={colors.redAccent[500]}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </DashBoardBox>
      <DashBoardBox gridArea='f' theme={theme} colors={colors}>
        <Stack direction='row' spacing={2} justifyContent='space-between'>
          <AnalyticsBoxHeader3 title='S and W' subtitle='(Summary)' />
          <Box sx={{ p: 1 }}>
            <CustomSelect
              value={listData?.projectList?.data.data[0]}
              optionsArray={listData?.projectList?.data.data}
            />
          </Box>
        </Stack>
        {error?.sw ? <Alert severity='error'>{errMsg.sw}</Alert> : null}

        {swData && swData.length === 0 ? (
          <Box height='0%' />
        ) : (
          <ResponsiveContainer width='100%' height='80%'>
            <BarChart
              width={600}
              height={300}
              data={swDataInverted}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={20}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey={'key'} />
              <YAxis />
              <Tooltip />
              <Legend />
              {options.map((entry, index) => {
                return (
                  <Bar
                    key={`bar-${index}`}
                    dataKey={entry}
                    stackId='a'
                    fill={COLORS[index % COLORS.length]}
                  />
                );
              })}
              <Bar dataKey='reg' stackId='a' fill='#8884d8' />
              <Bar dataKey='ot' stackId='a' fill='#82ca9d' />
              <Bar dataKey='sat' stackId='a' fill='#32a852' />
              <Bar dataKey='sun' stackId='a' fill='#3a32a8' />
            </BarChart>
          </ResponsiveContainer>
        )}
      </DashBoardBox>
    </>
  );
};

export default Row2;
