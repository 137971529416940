import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Box, Typography, Stack } from '@mui/material';
import dayjs from 'dayjs';
import TwoToneInlineText from './TwoToneInlineText';
import { useState } from 'react';
import { useAuth } from '../context/AuthProvider';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '350px',
  width: '100%',
  height: '250px',
  bgcolor: 'common.black',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  zIndex: 1000000,
  textAlign: 'center',
};

const ApproveTimeCardModal = ({
  open,
  handleClose,
  details,
  handleSnackbarClick,
  timecards,
  setTimecards,
}) => {
  const [sentState, setSentState] = useState(false);
  const [errState, setErrState] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const { api } = useAuth();

  // TimeClock Post
  const approveTimeCard = async (details) => {
    console.log('submitting edit timecards:', details);
    const { _id } = details;
    const body = [_id];
    console.log('body:', body);
    try {
      const timecard = await api.put(`/api/v1/timecardsv3/approvemany`, body);
      console.log('Timecard Edited:', timecard);
      setSentState(true);
      setErrState(false);
      handleSnackbarClick();
      handleClose();
      //update timecard in state
      setTimecards((prevItems) =>
        prevItems.map((item) =>
          item._id === _id
            ? { ...item, approved: true, status: 'Complete', approvedBy: _id }
            : item
        )
      );
    } catch (error) {
      console.log('Error creating timecard:', error);
      setErrState(true);
      if (error.response?.data) {
        setErrMsg(error.response.data.error);
      } else {
        setErrMsg('Error creating time card');
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'>
      <Box sx={style}>
        <Typography variant='h6' id='modal-modal-title' sx={{ mt: 2 }}>
          Approve Time Card
        </Typography>
        <TwoToneInlineText text1={'Full Name'} text2={details.fullName} />

        <TwoToneInlineText
          text1={'Week Ending'}
          text2={dayjs(details.weekEnding).format('MM/DD/YYYY')}
        />

        <TwoToneInlineText
          text1={'Total Hours'}
          text2={details.calculations.totalHours}
        />

        <Typography
          variant='body1'
          id='simple-modal-description'
          sx={{ mt: 2 }}>
          Are you sure you want to approve?
        </Typography>

        <Stack
          direction='row'
          spacing={2}
          justifyContent={'center'}
          sx={{ mt: 2 }}>
          <Button
            variant='contained'
            color='success'
            sx={{ color: 'white' }}
            onClick={() => {
              approveTimeCard(details);
            }}>
            Approve
          </Button>
          <Button variant='contained' color='error' onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ApproveTimeCardModal;
