//functional card component
import React, { useState, useEffect } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import TimerIcon from '@mui/icons-material/Timer';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { CssVarsProvider } from '@mui/joy/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Root from '../../styled/Test';
import Button from '@mui/joy/Button';
import Avatar from '@mui/joy/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GroupIcon from '@mui/icons-material/Group';
import { useAuth } from '../../context/AuthProvider';
import CustomSelect from './../../styledcomponents/CustomSelect';
import EditTimeCardModal2 from '../../components/EditTimeCardModal2';
import DeleteTimeCardModal from '../../components/DeleteTimeCardModal';
import ViewTimeCardModal from '../../components/ViewTimeCardModal';
import { ColorModeContext, useMode } from '../../theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import ApproveTimeCardModal from '../ApproveTimeCardModal';
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const SetRatesTimeCard = ({
  category,
  title,
  rareFind = false,
  liked = false,
  image,
  regHours,
  overtimeHours,
  satHours,
  sunHours,
  weekEnd,
  approved,
  status,
  fullName,
  firstName,
  lastName,
  paid,
  timecard,
  listData,
  handleSnackbar,
  isLoading,
  setIsLoading,
}) => {
  const { user } = useAuth();
  const [isLiked, setIsLiked] = React.useState(liked);
  const [firstInitial, setFirstInitial] = React.useState();
  const [lastInitial, setLastInitial] = React.useState();

  useEffect(() => {
    if (firstName && lastName) {
      setFirstInitial(firstName.charAt(0));
      setLastInitial(lastName.charAt(0));
    }
  }, [firstName, lastName]);
  return (
    <CssVarsProvider disableTransitionOnChange>
      <Box>
        <Card
          variant='outlined'
          orientation='horizontal'
          sx={{
            bgcolor: 'neutral.softBg',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            '&:hover': {
              boxShadow: 'lg',
              borderColor: 'var(--joy-palette-neutral-outlinedDisabledBorder)',
            },
          }}>
          <CardOverflow
            sx={{
              mr: { xs: 'var(--CardOverflow-offset)', sm: 0 },
              mb: { xs: 0, sm: 'var(--CardOverflow-offset)' },
              '--AspectRatio-radius': {
                xs: 'calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) 0 0',
                sm: 'calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) 0 0 calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px))',
              },
            }}>
            <AspectRatio
              ratio='1'
              flex
              sx={{
                minWidth: { sm: 80, md: 100 },
                '--AspectRatio-maxHeight': { xs: '160px', sm: '99px' },
              }}>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: 'grey', height: '60px', width: '60px' }}>
                  {firstInitial}
                  {lastInitial}
                </Avatar>
              </Box>
              {/* <img alt="" src={image} /> */}
              <Stack
                direction='row'
                sx={{
                  alignItems: 'top',
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  p: 1,
                }}>
                {/* <IconButton
								variant="plain"
								size="sm"
								color={isLiked ? "danger" : "neutral"}
								onClick={() => setIsLiked((prev) => !prev)}
								sx={{
									display: { xs: "flex", sm: "none" },
									ml: "auto",
									borderRadius: "50%",
									zIndex: "20",
								}}
							>
								<MoreVertIcon />
							</IconButton> */}
              </Stack>
            </AspectRatio>
          </CardOverflow>
          <CardContent>
            <Stack
              spacing={1}
              direction='row'
              sx={{
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}>
              <div>
                <Typography
                  level='body-sm'
                  sx={{
                    color:
                      category === 'Incomplete' ? 'warning.400' : 'success.400',
                  }}>
                  {category === 'Incomplete'
                    ? 'Pending Timecard'
                    : 'Approved Timecard'}
                </Typography>
                <Typography level='title-md'>
                  <Link
                    overlay
                    underline='none'
                    href='#interactive-card'
                    sx={{ color: 'text.primary' }}>
                    {title}
                  </Link>
                </Typography>
              </div>

              <IconButton
                variant='plain'
                size='sm'
                color={isLiked ? 'danger' : 'neutral'}
                onClick={() => setIsLiked((prev) => !prev)}
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  borderRadius: '50%',
                }}>
                <Root>
                  <MoreVertIcon />
                </Root>
              </IconButton>
            </Stack>
            <Stack
              spacing='0.25rem 1rem'
              direction='row'
              useFlexGap
              sx={{ flexWrap: 'wrap', my: 0.25 }}>
              <Typography level='body-xs' startDecorator={<TimerIcon />}>
                Reg: {regHours ? regHours : 0}
              </Typography>
              <Typography level='body-xs'>
                OT: {overtimeHours ? overtimeHours : 0}
              </Typography>
              <Typography level='body-xs'>
                Sat: {satHours ? satHours : 0}
              </Typography>
              <Typography level='body-xs'>
                Sun: {sunHours ? sunHours : 0}
              </Typography>

              <Typography
                level='body-xs'
                startDecorator={<CalendarMonthIcon />}>
                Week Ending: {weekEnd ? weekEnd : 'None'}
              </Typography>
              <Typography
                level='body-xs'
                startDecorator={<PendingActionsIcon />}>
                Approved:{' '}
                {approved ? (
                  <CheckIcon sx={{ color: 'green' }} />
                ) : (
                  <CloseIcon sx={{ color: 'red' }} />
                )}
              </Typography>
              <Typography
                level='body-xs'
                startDecorator={<PendingActionsIcon />}>
                <Box>
                  Status:{' '}
                  {status === 'Complete' ? (
                    <Chip
                      variant='soft'
                      color='success'
                      startDecorator={<WorkspacePremiumRoundedIcon />}
                      size='sm'>
                      Complete
                    </Chip>
                  ) : (
                    // <Chip label="Active" color="primary" size="small" />
                    // <Chip label="Incomplete" color="warning" size="small" />
                    <Chip color='danger' size='sm'>
                      Incomplete
                    </Chip>
                  )}
                </Box>
              </Typography>
              <Typography
                level='body-xs'
                startDecorator={<PendingActionsIcon />}>
                <Box>
                  Paid:{' '}
                  {paid === true ? (
                    <Chip
                      variant='soft'
                      color='success'
                      startDecorator={<WorkspacePremiumRoundedIcon />}
                      size='sm'>
                      True
                    </Chip>
                  ) : (
                    // <Chip label="Active" color="primary" size="small" />
                    // <Chip label="Incomplete" color="warning" size="small" />
                    <Chip color='danger' size='sm'>
                      False
                    </Chip>
                  )}
                </Box>
              </Typography>
              <Typography level='body-xs' startDecorator={<GroupIcon />}>
                <Box>
                  Project:{' '}
                  {paid === true ? (
                    <Chip
                      variant='soft'
                      color='success'
                      startDecorator={<WorkspacePremiumRoundedIcon />}
                      size='sm'>
                      True
                    </Chip>
                  ) : (
                    // <Chip label="Active" color="primary" size="small" />
                    // <Chip label="Incomplete" color="warning" size="small" />
                    <Chip color='danger' size='sm'>
                      False
                    </Chip>
                  )}
                </Box>
              </Typography>
            </Stack>
            <CustomSelect />

            <IconButton onClick={() => console.log('loading')}>
              <AddCircleIcon />
            </IconButton>

            <Typography
              level='title-lg'
              sx={{ flexGrow: 1, textAlign: 'right' }}>
              <strong>{regHours + overtimeHours + satHours + sunHours}</strong>{' '}
              <Typography level='body-md'>total</Typography>
            </Typography>
            <Stack direction='row' spacing={1} sx={{ mt: 'auto' }}>
              {user?.role === 'Company Admin' ? (
                <Button
                  size='sm'
                  onClick={() => {
                    console.log('approve timecard');
                  }}>
                  Approve
                </Button>
              ) : null}
              <Button
                size='sm'
                onClick={() => {
                  console.log('view timecard');
                }}>
                View
              </Button>
              <Button
                onClick={() => {
                  console.log('edit timecard');
                }}
                disabled={timecard.approved}>
                Edit
              </Button>
              <Button
                onClick={() => {
                  console.log('delete timecard');
                }}
                size='sm'>
                Delete
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </CssVarsProvider>
  );
};

export default SetRatesTimeCard;
