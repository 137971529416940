import {
  Box,
  Typography,
  Stack,
  useTheme,
  InputBase,
  InputAdornment,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { useState, useEffect, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import TwoToneInlineText from './TwoToneInlineText';
import EditTimeCardWeekLabels from './EditTimeCardWeekLabels';
// import { tokens } from "../theme";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { useAuth } from '../context/AuthProvider';
import PivotBox from '../styled/PivotBox';
import useMediaQuery from '@mui/material/useMediaQuery';
import useMeasure from 'react-use-measure';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SettingsIcon from '@mui/icons-material/Settings';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import threeWeekLookBack from '../hooks/three-week-lookback';
import {
  updateDetailTimes,
  seedPayrollCreateV3Details,
} from './../utils/PayrollCreateV3';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import PayrollCreateSettingsModal from './PayrollCreateSettingsModal';
import { personnel } from './../fakedata/mockData';

const PayrollCreateV3Body = ({
  listData,
  initialDetails,
  weekEnding,
  setWeekEnding,
  prevTimecards,
  user,
}) => {
  const { api } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [ref, { height }] = useMeasure();
  const [project1, setProject1] = useState(null);
  const [project1hour, setproject1hour] = useState(0);
  const [project2, setProject2] = useState(null);
  const [project2hour, setproject2hour] = useState(0);
  const [project3, setProject3] = useState(null);
  const [project3hour, setproject3hour] = useState(0);
  const [p1disabled, setP1disabled] = useState(true);
  const [p2disabled, setP2disabled] = useState(true);
  const [p3disabled, setP3disabled] = useState(true);
  const [errState, setErrState] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [sentState, setSentState] = useState(false);
  const [touched, setTouched] = useState(false);
  const [personnel, setPersonnel] = useState([]);
  const [project, setProject] = useState([]);
  const [seededHours, setSeededHours] = useState('');
  const [detailsRendered, setDetailsRendered] = useState(initialDetails);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const handleOpenSettingsModal = () => setOpenSettingsModal(true);
  const handleCloseSettingsModal = () => setOpenSettingsModal(false);
  const { sixWkBkDesc } = threeWeekLookBack();
  console.log('initialDetails:', initialDetails);
  console.log('detailsRendered:', detailsRendered);
  const handlePersonnelChange = (event) => {
    setDetailsRendered((prevState) => ({
      ...prevState,
      fullName: event.target.value,
      firstName: event.target.value.split(' ')[1],
      lastName: event.target.value
        .split(' ')[0]
        .substring(0, event.target.value.length - 1),
    }));
  };

  const handleWeekChange = (event) => {
    setWeekEnding(event.target.value);
    setDetailsRendered(updateDetailTimes(detailsRendered, event.target.value));
  };
  const handleChange1 = (event) => {
    setTouched(true);
    setProject1(event.target.value);

    setDetailsRendered((prevState) => ({
      ...prevState,
      project1: event.target.value,
    }));
    console.log('project1:', event.target.value);
    if (event.target.value === '' || event.target.value === null) {
      setP1disabled(true);
      setErrState(true);
    } else {
      setP1disabled(false);
      project1hour > 0 &&
        ((project2 && project2hour > 0) || project2hour === 0) &&
        ((project3 && project3hour > 0) || project3hour === 0) &&
        setErrState(false);
    }
  };
  const handleChange2 = (event) => {
    setProject2(event.target.value);
    setDetailsRendered((prevState) => ({
      ...prevState,
      project2: event.target.value,
    }));
    if (event.target.value === '' || event.target.value === null) {
      setP2disabled(true);
      setErrState(true);
    } else {
      setP2disabled(false);
    }
  };
  const handleChange3 = (event) => {
    setProject3(event.target.value);
    setDetailsRendered((prevState) => ({
      ...prevState,
      project3: event.target.value,
    }));
    if (event.target.value === '' || event.target.value === null) {
      setP3disabled(true);
      setErrState(true);
    } else {
      setP3disabled(false);
    }
  };
  const handleChangeBreak = (event) => {
    setDetailsRendered((prevState) => ({
      ...prevState, // Spread the existing properties
      [event.target.name]: event.target.value, // Update only the targeted property
    }));
  };

  useEffect(() => {
    console.log(weekEnding);
    const output = seedPayrollCreateV3Details(user, weekEnding, initialDetails);
    setDetailsRendered(output);
  }, [initialDetails, user, weekEnding]);

  const handleTimeCardChange = (event) => {
    console.log('event:', event.target.value);
    setSeededHours(event.target.value);
    const searchName = event.target.value;
    //we have the name of the timecard
    const oldTimecard = prevTimecards.find((item) => item.name === searchName);
    //we need to seed with the week ending date but keep the hours the same
    let personnel;
    const personnelFound = listData.personnel.data.data.find(
      (person) => person.fullName === oldTimecard.fullName
    );
    personnel = personnelFound ? personnelFound : user;
    //set the detailsRendered to the timecard
    // setDetailsRendered((prevState) => ({
    //   ...prevState,
    //   [event.target.name]: event.target.value,
    // }));

    //weekEndingString should never be null, oldTimecard should never be null
    const output = seedPayrollCreateV3Details(
      personnel,
      weekEnding,
      oldTimecard
    );
    setDetailsRendered(output);
    console.log('output:', output);
  };

  const style = {
    top: 'calc(0% + 500px)',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? 390 : '80%',
    height: isMobile ? '85%' : 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '15px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
    p: 2,
    overflow: 'auto',
  };

  useEffect(() => {
    if (listData?.personnel?.data?.data?.length > 0) {
      setPersonnel(listData.personnel.data.data);
    }
    if (listData?.projects?.data?.data?.length > 0) {
      setProject(listData.projects.data.data);
    }
  }, [listData]);

  useEffect(() => {
    console.log('user:', user);
    const findFunction = async (array) => {
      if (array.length > 0) {
        const found = array.find((elem) => elem.fullName === user.fullName);
        console.log(found);
        const updated = {
          ...detailsRendered,
          fullName: found.fullName,
          employeeID: found.employeeID,
          firstName: found.firstName,

          lastName: found.lastName,
        };
        setDetailsRendered((prev) => ({
          ...prev,
          ...updated,
        }));
        return found; // Return the found item
      }
      return null; // Return null if no match
    };

    // Call function
    const executeFind = async () => {
      if (listData?.personnel && user) {
        const found = await findFunction(listData.personnel.data.data); // Await the async function
        console.log(found);
      }
    };

    executeFind(); // Execute the async wrapper function
  }, []);

  useEffect(() => {
    const calculateHours = (start, end) => {
      let answer = 0;
      if (start && end) {
        answer = end.diff(start, 'hour', true);
      }
      return answer;
    };
    if (detailsRendered) {
      setproject1hour(
        calculateHours(detailsRendered?.sunp1start, detailsRendered?.sunp1end) +
          calculateHours(
            detailsRendered?.monp1start,
            detailsRendered?.monp1end
          ) +
          calculateHours(
            detailsRendered?.tuep1start,
            detailsRendered?.tuep1end
          ) +
          calculateHours(
            detailsRendered?.wedp1start,
            detailsRendered?.wedp1end
          ) +
          calculateHours(
            detailsRendered?.thurp1start,
            detailsRendered?.thurp1end
          ) +
          calculateHours(
            detailsRendered?.frip1start,
            detailsRendered?.frip1end
          ) +
          calculateHours(detailsRendered?.satp1start, detailsRendered?.satp1end)
      );
      setproject2hour(
        calculateHours(detailsRendered?.sunp2start, detailsRendered?.sunp2end) +
          calculateHours(
            detailsRendered?.monp2start,
            detailsRendered?.monp2end
          ) +
          calculateHours(
            detailsRendered?.tuep2start,
            detailsRendered?.tuep2end
          ) +
          calculateHours(
            detailsRendered?.wedp2start,
            detailsRendered?.wedp2end
          ) +
          calculateHours(
            detailsRendered?.thurp2start,
            detailsRendered?.thurp2end
          ) +
          calculateHours(
            detailsRendered?.frip2start,
            detailsRendered?.frip2end
          ) +
          calculateHours(detailsRendered?.satp2start, detailsRendered?.satp2end)
      );
      setproject3hour(
        calculateHours(detailsRendered?.sunp3start, detailsRendered?.sunp3end) +
          calculateHours(
            detailsRendered?.monp3start,
            detailsRendered?.monp3end
          ) +
          calculateHours(
            detailsRendered?.tuep3start,
            detailsRendered?.tuep3end
          ) +
          calculateHours(
            detailsRendered?.wedp3start,
            detailsRendered?.wedp3end
          ) +
          calculateHours(
            detailsRendered?.thurp3start,
            detailsRendered?.thurp3end
          ) +
          calculateHours(
            detailsRendered?.thurp3start,
            detailsRendered?.thurp3end
          ) +
          calculateHours(
            detailsRendered?.frip3start,
            detailsRendered?.frip3end
          ) +
          calculateHours(detailsRendered?.satp3start, detailsRendered?.satp3end)
      );
    }
  }, [detailsRendered]);

  const shouldDiableTimeStarts = useCallback(
    (value, view, p1end, p2start, p2end, p3start, p3end) => {
      if (!p1end && !p2start && !p2end && !p3start && !p3end) {
        // If any input is undefined, do not disable any times
        return false;
      }

      if (view === 'hours') {
        const startAdjustmentp2 = p2start?.minute() > 0 ? 1 : 0;
        const startAdjustmentp3 = p3start?.minute() > 0 ? 1 : 0;
        return (
          //true when p2 is earlier than p1 start
          // Only apply if start and end hours are different
          (value.hour() >= p3start?.hour() + startAdjustmentp3 &&
            value.hour() <= p3end?.hour() - 1) ||
          //true when p2 is earlier than p1 start
          (value.hour() >= p2start?.hour() + startAdjustmentp2 &&
            value.hour() <= p2end?.hour() - 1) ||
          value.hour() > p1end?.hour()
        );
      }

      if (view === 'minutes') {
        return (
          (value.hour() === p3start?.hour() &&
            value.minute() > p3start?.minute()) ||
          (value.hour() === p3end?.hour() &&
            value.minute() < p3end?.minute()) ||
          (value.hour() === p2start?.hour() &&
            value.minute() > p2start?.minute()) ||
          (value.hour() === p2end?.hour() && value.minute() < p2end?.minute())
        );
      }

      return false;
    },
    []
  );

  const shouldDisableTimeEnd = useCallback(
    (value, view, p1start, p2start, p2end, p3start, p3end) => {
      if (!p1start && !p2start && !p2end && !p3start && !p3end) {
        // If any input is undefined, do not disable any times
        return false;
      }

      if (view === 'hours') {
        const endAdjustmentp2 = p2end?.minute() > 0 ? 1 : 0;
        const endAdjustmentp3 = p3end?.minute() > 0 ? 1 : 0;
        return (
          (value.hour() >= p3start?.hour() + 1 &&
            value.hour() <= p3end?.hour() - endAdjustmentp3) ||
          (value.hour() >= p2start?.hour() + 1 &&
            value.hour() <= p2end?.hour() - endAdjustmentp2) ||
          value.hour() < p1start?.hour()
        );
      }

      if (view === 'minutes') {
        return (
          (value.hour() === p3start?.hour() &&
            value.minute() > p3start?.minute()) ||
          (value.hour() === p3end?.hour() &&
            value.minute() < p3end?.minute()) ||
          (value.hour() === p2start?.hour() &&
            value.minute() > p2start?.minute()) ||
          (value.hour() === p2end?.hour() && value.minute() < p2end?.minute())
        );
      }

      return false;
    },
    []
  );

  const breakSelect = (name, prop) => {
    return (
      <FormControl
        sx={{
          width: '5.5rem',
          height: '50%',
          mt: '5%',
        }}
        size='small'>
        <InputLabel id='demo-select-small-label'>Break</InputLabel>
        <Select
          labelId='demo-select-small-label'
          id='demo-select-small'
          name={name}
          value={detailsRendered?.[prop]}
          onChange={handleChangeBreak}
          sx={{
            backgroundColor:
              p1disabled && p2disabled && p3disabled ? '#00000000' : '#3d3d3d',
          }}>
          <MenuItem value={0}>
            <em>None</em>
          </MenuItem>
          <MenuItem value={0.5}>30 Min</MenuItem>
          <MenuItem value={1}>1 Hour</MenuItem>
        </Select>
      </FormControl>
    );
  };

  // TimeClock Post
  const submitTimeCard = async (details) => {
    console.log('submitting timecards:', details);
    try {
      const timecard = await api.post('/api/v1/timecardsv3', details);
      console.log('Timecard created:', timecard);
      setSentState(true);
    } catch (error) {
      console.log('Error creating timecard:', error);
      setErrState(true);
      if (error.response?.data) {
        setErrMsg(error.response.data.error);
      } else {
        setErrMsg('Error creating time card');
      }
    }
  };

  return (
    <Box sx={style}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography
          id='modal-modal-title'
          variant={isMobile ? 'body2' : 'h6'}
          component='h2'>
          Create Timecard View
        </Typography>
        <Box>
          <FormControl size='small' sx={{ pb: 1, width: isMobile ? 130 : 300 }}>
            <InputLabel id='demo-select-small-label'>Personnel</InputLabel>
            <Select
              labelId='demo-select-small-label'
              id='demo-select-small'
              value={detailsRendered?.fullName}
              label='Week Ending'
              onChange={handlePersonnelChange}>
              {personnel.length > 0 &&
                personnel.map((person) => (
                  <MenuItem key={person._id} value={person.fullName}>
                    {person.fullName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Box
            sx={{
              display: isMobile ? 'none' : 'inline-block',
              overflow: 'hidden',
            }}>
            <IconButton aria-label='delete' onClick={handleOpenSettingsModal}>
              <SettingsIcon />
            </IconButton>
          </Box>
        </Box>
        {/* <Box
        sx={{
          display: isMobile ? 'none' : 'grid',
          gridAutoColumns: '1fr',
          gap: 1,
        }}> */}
        <Box
          sx={{
            display: isMobile ? 'block' : 'none',
            marginLeft: 'auto',
          }}>
          <Stack direction='row'>
            <FormControl size='small'>
              <InputLabel id='demo-select-small-label'>Week Ending</InputLabel>
              <Select
                labelId='demo-select-small-label'
                id='demo-select-small'
                value={weekEnding}
                label='Week Ending'
                onChange={handleWeekChange}>
                <MenuItem value={null}>
                  <em>Please Select A Week</em>
                </MenuItem>
                {sixWkBkDesc.map((week, index) => {
                  return (
                    <MenuItem key={'endDate' + index} value={week}>
                      {week}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box sx={{ display: isMobile ? 'block' : 'none' }}>
              <IconButton aria-label='delete' onClick={handleOpenSettingsModal}>
                <SettingsIcon />
              </IconButton>
            </Box>
          </Stack>
        </Box>
      </Stack>

      <Box
        sx={{
          display: isMobile ? 'none' : 'grid',
          gridAutoColumns: '1fr',
          gap: 1,
        }}>
        <FormControl
          sx={{ gridRow: '1', gridColumn: '1/2', backgroundColor: '#3d3d3d' }}
          size='small'>
          <InputLabel id='demo-select-small-label'>Week Ending</InputLabel>
          <Select
            labelId='demo-select-small-label'
            id='demo-select-small'
            value={weekEnding}
            label='Week Ending'
            onChange={handleWeekChange}>
            <MenuItem value={null}>
              <em>Please Select A Week</em>
            </MenuItem>
            {sixWkBkDesc.map((week, index) => {
              return (
                <MenuItem key={'endDate' + index} value={week}>
                  {week}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ gridRow: '1', gridColumn: '2/4' }} size='small'>
          {project1 ? null : (
            <InputLabel id='demo-select-small-label'>
              Select A Project
            </InputLabel>
          )}
          <Select
            labelId='demo-select-small-label'
            id='demo-select-small'
            value={project1}
            label='Project 1'
            error={!project1 && !touched}
            onChange={handleChange1}
            sx={{ backgroundColor: p1disabled ? '#00000000' : '#3d3d3d' }}>
            {project.length > 0 &&
              project.map((proj, index) => (
                <MenuItem key={index} value={proj}>
                  {proj}
                </MenuItem>
              ))}
            <MenuItem value={null}>
              <em>Please Select A Project</em>
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ gridRow: '1', gridColumn: '4/6' }} size='small'>
          {project2 ? null : (
            <InputLabel id='demo-select-small-label'>
              Select A Project
            </InputLabel>
          )}
          <Select
            labelId='demo-select-small-label'
            id='demo-select-small'
            value={project2}
            label='Project 2'
            onChange={handleChange2}
            sx={{ backgroundColor: p2disabled ? '#00000000' : '#3d3d3d' }}>
            {project.length > 0 &&
              project.map((proj, index) => (
                <MenuItem key={index} value={proj}>
                  {proj}
                </MenuItem>
              ))}
            <MenuItem value={null}>
              <em>Please Select A Project</em>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ gridRow: '1', gridColumn: '6/8' }} size='small'>
          {project3 ? null : (
            <InputLabel id='demo-select-small-label'>
              Select A Project
            </InputLabel>
          )}
          <Select
            labelId='demo-select-small-label'
            id='demo-select-small'
            value={project3}
            label='Project 3'
            onChange={handleChange3}
            sx={{ backgroundColor: p3disabled ? '#00000000' : '#3d3d3d' }}>
            {project.length > 0 &&
              project.map((proj, index) => (
                <MenuItem key={index} value={proj}>
                  {proj}
                </MenuItem>
              ))}
            <MenuItem value={null}>
              <em>Please Select A Project</em>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['TimePicker', 'TimePicker', 'TimePicker']}>
          <DemoItem>
            {/* Start of unit box 1 */}

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: isMobile
                  ? 'repeat(3, 1fr)'
                  : 'repeat(7, 1fr)',
                overflow: 'hidden',
              }}>
              <Stack
                spacing={1}
                sx={{
                  pr: 1,
                  maxWidth: '130px',
                  color: 'white',
                }}>
                {isMobile ? (
                  <FormControl size='small'>
                    <InputLabel id='demo-select-small-label'>
                      {isMobile ? 'Project' : 'Select A Project'}
                    </InputLabel>

                    <Select
                      labelId='demo-select-small-label'
                      id='demo-select-small'
                      value={project1}
                      label='Project 1'
                      error={!project1 && !touched}
                      onChange={handleChange1}>
                      {project.length > 0 &&
                        project.map((proj, index) => (
                          <MenuItem key={index} value={proj}>
                            {proj}
                          </MenuItem>
                        ))}
                      <MenuItem value={null}>
                        <em>Please Select A Project</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : null}
                {isMobile ? null : <Typography>Week</Typography>}
                <Box
                  sx={{
                    mt: isMobile ? 0 : '1.5rem',
                  }}>
                  <Stack direction='row'>
                    <Box>
                      <Typography>Sun</Typography>
                      <Typography>
                        {dayjs(weekEnding).day(0).format('MM/DD')}
                      </Typography>
                    </Box>
                    {breakSelect('breaksun', 'breaksun')}
                  </Stack>
                </Box>
                <Box>
                  <Stack direction='row'>
                    <Box>
                      <Typography>Mon</Typography>
                      <Typography>
                        {' '}
                        {dayjs(weekEnding).day(1).format('MM/DD')}
                      </Typography>
                    </Box>
                    {breakSelect('breakmon', 'breakmon')}
                  </Stack>
                </Box>

                <Box>
                  <Stack direction='row'>
                    <Box>
                      <Typography>Tue</Typography>
                      <Typography>
                        {' '}
                        {dayjs(weekEnding).day(2).format('MM/DD')}
                      </Typography>
                    </Box>
                    {breakSelect('breaktue', 'breaktue')}
                  </Stack>
                </Box>
                <Box>
                  <Stack direction='row'>
                    <Box>
                      <Typography>Wed</Typography>
                      <Typography>
                        {' '}
                        {dayjs(weekEnding).day(3).format('MM/DD')}
                      </Typography>
                    </Box>
                    {breakSelect('breakwed', 'breakwed')}
                  </Stack>
                </Box>
                <Box>
                  <Stack direction='row'>
                    <Box>
                      <Typography>Thur</Typography>
                      <Typography>
                        {' '}
                        {dayjs(weekEnding).day(4).format('MM/DD')}
                      </Typography>
                    </Box>
                    {breakSelect('breakthur', 'breakthur')}
                  </Stack>
                </Box>
                <Box>
                  <Stack direction='row'>
                    <Box>
                      <Typography>Fri</Typography>
                      <Typography>
                        {' '}
                        {dayjs(weekEnding).day(5).format('MM/DD')}
                      </Typography>
                    </Box>
                    {breakSelect('breakfri', 'breakfri')}
                  </Stack>
                </Box>
                <Box>
                  <Stack direction='row'>
                    <Box>
                      <Typography>Sat</Typography>
                      <Typography>
                        {' '}
                        {dayjs(weekEnding).day(6).format('MM/DD')}
                      </Typography>
                    </Box>
                    {breakSelect('breaksat', 'breaksat')}
                  </Stack>
                </Box>
              </Stack>

              <div ref={ref}>
                <Stack spacing={1.75} sx={{ mt: isMobile ? '1rem' : 0 }}>
                  <Typography>Hours Start</Typography>
                  <DesktopTimePicker
                    id='unique'
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      if (e === null) {
                      }
                      setDetailsRendered((prev) => ({
                        ...prev,
                        sunp1start: e,
                      }));
                    }}
                    value={detailsRendered?.sunp1start}
                    referenceDate={dayjs(weekEnding).day(0)}
                    defaultValue={detailsRendered?.sunp1start}
                    disabled={p1disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.sunp1end,
                        detailsRendered?.sunp2start,
                        detailsRendered?.sunp2end,
                        detailsRendered?.sunp3start,
                        detailsRendered?.sunp3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered?.sunp1start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                    onClear={() => {
                      console.log('clearing');
                      setDetailsRendered((prev) => ({
                        ...prev,
                        sunp1start: null,
                      }));
                    }}
                  />
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        monp1start: e,
                      }));
                    }}
                    value={detailsRendered?.monp1start}
                    referenceDate={dayjs(weekEnding).day(1)}
                    defaultValue={detailsRendered?.monp1start}
                    disabled={p1disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered.monp1end,
                        detailsRendered.monp2start,
                        detailsRendered.monp2end,
                        detailsRendered.monp3start,
                        detailsRendered.monp3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered?.monp1start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        tuep1start: e,
                      }));
                    }}
                    value={detailsRendered?.tuep1start}
                    referenceDate={dayjs(weekEnding).day(2)}
                    defaultValue={detailsRendered?.tuep1start}
                    disabled={p1disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.tuep1end,
                        detailsRendered?.tuep2start,
                        detailsRendered?.tuep2end,
                        detailsRendered?.tuep3start,
                        detailsRendered?.tuep3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered?.tuep1start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        wedp1start: e,
                      }));
                    }}
                    value={detailsRendered?.wedp1start}
                    referenceDate={dayjs(weekEnding).day(3)}
                    defaultValue={detailsRendered?.wedp1start}
                    disabled={p1disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.wedp1end,
                        detailsRendered?.wedp2start,
                        detailsRendered?.wedp2end,
                        detailsRendered?.wedp3start,
                        detailsRendered?.wedp3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered?.wedp1start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        thurp1start: e,
                      }));
                    }}
                    value={detailsRendered?.thurp1start}
                    referenceDate={dayjs(weekEnding).day(4)}
                    defaultValue={detailsRendered?.thurp1start}
                    disabled={p1disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.thurp1end,
                        detailsRendered?.thurp2start,
                        detailsRendered?.thurp2end,
                        detailsRendered?.thurp3start,
                        detailsRendered?.thurp3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered?.thurp1start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        frip1start: e,
                      }));
                    }}
                    value={detailsRendered?.frip1start}
                    referenceDate={dayjs(weekEnding).day(5)}
                    defaultValue={detailsRendered?.frip1start}
                    disabled={p1disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.frip1end,
                        detailsRendered?.frip2start,
                        detailsRendered?.frip2end,
                        detailsRendered?.frip3start,
                        detailsRendered?.frip3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered?.frip1start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        satp1start: e,
                      }));
                    }}
                    value={detailsRendered.satp1start}
                    referenceDate={dayjs(weekEnding).day(6)}
                    defaultValue={detailsRendered?.satp1start}
                    disabled={p1disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.satp1end,
                        detailsRendered?.satp2start,
                        detailsRendered?.satp2end,
                        detailsRendered?.satp3start,
                        detailsRendered?.satp3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },

                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered.satp1start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                </Stack>
              </div>
              <Stack spacing={1.75} sx={{ mt: isMobile ? '1rem' : 0 }}>
                <Typography>Hours End</Typography>
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      sunp1end: e,
                    }));
                  }}
                  value={detailsRendered?.sunp1end}
                  referenceDate={dayjs(weekEnding).day(0)}
                  defaultValue={detailsRendered?.sunp1end}
                  disabled={p1disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.sunp1start,
                      detailsRendered?.sunp2start,
                      detailsRendered?.sunp2end,
                      detailsRendered?.sunp3start,
                      detailsRendered?.sunp3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.sunp1end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      monp1end: e,
                    }));
                  }}
                  value={detailsRendered?.monp1end}
                  referenceDate={dayjs(weekEnding).day(1)}
                  defaultValue={detailsRendered.monp1end}
                  disabled={p1disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.monp1start,
                      detailsRendered?.monp2start,
                      detailsRendered?.monp2end,
                      detailsRendered?.monp3start,
                      detailsRendered?.monp3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.monp1end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      tuep1end: e,
                    }));
                  }}
                  value={detailsRendered?.tuep1end}
                  referenceDate={dayjs(weekEnding).day(2)}
                  defaultValue={detailsRendered.tuep1end}
                  disabled={p1disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.tuep1start,
                      detailsRendered?.tuep2start,
                      detailsRendered?.tuep2end,
                      detailsRendered?.tuep3start,
                      detailsRendered?.tuep3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.tuep1end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      wedp1end: e,
                    }));
                  }}
                  value={detailsRendered?.wedp1end}
                  referenceDate={dayjs(weekEnding).day(3)}
                  defaultValue={detailsRendered.wedp1end}
                  disabled={p1disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.wedp1start,
                      detailsRendered?.wedp2start,
                      detailsRendered?.wedp2end,
                      detailsRendered?.wedp3start,
                      detailsRendered?.wedp3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.wedp1end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      thurp1end: e,
                    }));
                  }}
                  value={detailsRendered?.thurp1end}
                  referenceDate={dayjs(weekEnding).day(4)}
                  defaultValue={detailsRendered.thurp1end}
                  disabled={p1disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.thurp1start,
                      detailsRendered?.thurp2start,
                      detailsRendered?.thurp2end,
                      detailsRendered?.thurp3start,
                      detailsRendered?.thurp3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.thurp1end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      frip1end: e,
                    }));
                  }}
                  value={detailsRendered?.frip1end}
                  referenceDate={dayjs(weekEnding).day(5)}
                  defaultValue={detailsRendered.frip1end}
                  disabled={p1disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.frip1start,
                      detailsRendered?.frip2start,
                      detailsRendered?.frip2end,
                      detailsRendered?.frip3start,
                      detailsRendered?.frip3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.frip1end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      satp1end: e,
                    }));
                  }}
                  value={detailsRendered?.satp1end}
                  referenceDate={dayjs(weekEnding).day(6)}
                  defaultValue={detailsRendered.satp1end}
                  disabled={p1disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.satp1start,
                      detailsRendered?.satp2start,
                      detailsRendered?.satp2end,
                      detailsRendered?.satp3start,
                      detailsRendered?.satp3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.satp1end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
              </Stack>
              <EditTimeCardWeekLabels
                show={isMobile ? true : false}
                height={height}
                weekEnding={weekEnding}
                project={project2}
                handleChange={handleChange2}
                projectList={project}
                isMobile={isMobile}
              />
              <div>
                <Stack spacing={1.75} sx={{ mt: isMobile ? '2rem' : 0 }}>
                  <Typography>Hours Start</Typography>
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        sunp2start: e,
                      }));
                    }}
                    value={detailsRendered.sunp2start}
                    referenceDate={dayjs(weekEnding).day(0)}
                    defaultValue={detailsRendered.sunp2start}
                    disabled={p2disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.sunp2end,
                        detailsRendered?.sunp1start,
                        detailsRendered?.sunp1end,
                        detailsRendered?.sunp3start,
                        detailsRendered?.sunp3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered.sunp2start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        monp2start: e,
                      }));
                    }}
                    value={detailsRendered?.monp2start}
                    referenceDate={dayjs(weekEnding).day(1)}
                    defaultValue={detailsRendered.monp2start}
                    disabled={p2disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.monp2end,
                        detailsRendered?.monp1start,
                        detailsRendered?.monp1end,
                        detailsRendered?.monp3start,
                        detailsRendered?.monp3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered?.monp2start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        tuep2start: e,
                      }));
                    }}
                    value={detailsRendered?.tuep2start}
                    referenceDate={dayjs(weekEnding).day(2)}
                    defaultValue={detailsRendered.tuep2start}
                    disabled={p2disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.tuep2end,
                        detailsRendered?.tuep1start,
                        detailsRendered?.tuep1end,
                        detailsRendered?.tuep3start,
                        detailsRendered?.tuep3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered?.tuep2start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        wedp2start: e,
                      }));
                    }}
                    value={detailsRendered?.wedp2start}
                    referenceDate={dayjs(weekEnding).day(3)}
                    defaultValue={detailsRendered.wedp2start}
                    disabled={p2disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.wedp2end,
                        detailsRendered?.wedp1start,
                        detailsRendered?.wedp1end,
                        detailsRendered?.wedp3start,
                        detailsRendered?.wedp3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered?.wedp2start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        thurp2start: e,
                      }));
                    }}
                    value={detailsRendered?.thurp2start}
                    referenceDate={dayjs(weekEnding).day(4)}
                    defaultValue={detailsRendered.thurp2start}
                    disabled={p2disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.thurp2end,
                        detailsRendered?.thurp1start,
                        detailsRendered?.thurp1end,
                        detailsRendered?.thurp3start,
                        detailsRendered?.thurp3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered?.thurp2start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        frip2start: e,
                      }));
                    }}
                    value={detailsRendered?.frip2start}
                    referenceDate={dayjs(weekEnding).day(5)}
                    defaultValue={detailsRendered.frip2start}
                    disabled={p2disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.frip2end,
                        detailsRendered?.frip1start,
                        detailsRendered?.frip1end,
                        detailsRendered?.frip3start,
                        detailsRendered?.frip3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered?.frip2start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                  <DesktopTimePicker
                    views={['hours', 'minutes']}
                    onChange={(e) => {
                      setDetailsRendered((prev) => ({
                        ...prev,
                        satp2start: e,
                      }));
                    }}
                    value={detailsRendered?.satp2start}
                    referenceDate={dayjs(weekEnding).day(6)}
                    defaultValue={detailsRendered.satp2start}
                    disabled={p2disabled}
                    closeOnSelect={false}
                    timeSteps={{ minutes: 15 }}
                    shouldDisableTime={(value, view) =>
                      shouldDiableTimeStarts(
                        value,
                        view,
                        detailsRendered?.satp2end,
                        detailsRendered?.satp1start,
                        detailsRendered?.satp1end,
                        detailsRendered?.satp3start,
                        detailsRendered?.satp3end
                      )
                    }
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                      textField: {
                        size: 'small',
                        style: {
                          backgroundColor: !detailsRendered?.satp2start
                            ? '#00000000'
                            : '#3d3d3d',
                        },
                      },
                    }}
                  />
                </Stack>
              </div>
              <Stack spacing={1.75} sx={{ mt: isMobile ? '2rem' : 0 }}>
                <Typography>Hours End</Typography>
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      sunp2end: e,
                    }));
                  }}
                  value={detailsRendered.sunp2end}
                  referenceDate={dayjs(weekEnding).day(0)}
                  defaultValue={detailsRendered.sunp2end}
                  disabled={p2disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.sunp2start,
                      detailsRendered?.sunp1start,
                      detailsRendered?.sunp1end,
                      detailsRendered?.sunp3start,
                      detailsRendered?.sunp3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.sunp2end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      monp2end: e,
                    }));
                  }}
                  value={detailsRendered?.monp2end}
                  referenceDate={dayjs(weekEnding).day(1)}
                  defaultValue={detailsRendered.monp2end}
                  disabled={p2disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.monp2start,
                      detailsRendered?.monp1start,
                      detailsRendered?.monp1end,
                      detailsRendered?.monp3start,
                      detailsRendered?.monp3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.monp2end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      tuep2end: e,
                    }));
                  }}
                  value={detailsRendered?.tuep2end}
                  referenceDate={dayjs(weekEnding).day(2)}
                  defaultValue={detailsRendered.tuep2end}
                  disabled={p2disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.tuep2start,
                      detailsRendered?.tuep1start,
                      detailsRendered?.tuep1end,
                      detailsRendered?.tuep3start,
                      detailsRendered?.tuep3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.tuep2end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      wedp2end: e,
                    }));
                  }}
                  value={detailsRendered?.wedp2end}
                  referenceDate={dayjs(weekEnding).day(3)}
                  defaultValue={detailsRendered.wedp2end}
                  disabled={p2disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.wedp2start,
                      detailsRendered?.wedp1start,
                      detailsRendered?.wedp1end,
                      detailsRendered?.wedp3start,
                      detailsRendered?.wedp3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.wedp2end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      thurp2end: e,
                    }));
                  }}
                  value={detailsRendered?.thurp2end}
                  referenceDate={dayjs(weekEnding).day(4)}
                  defaultValue={detailsRendered.thurp2end}
                  disabled={p2disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.thurp2start,
                      detailsRendered?.thurp1start,
                      detailsRendered?.thurp1end,
                      detailsRendered?.thurp3start,
                      detailsRendered?.thurp3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.thurp2end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      frip2end: e,
                    }));
                  }}
                  value={detailsRendered?.frip2end}
                  referenceDate={dayjs(weekEnding).day(5)}
                  defaultValue={detailsRendered.frip2end}
                  disabled={p2disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.frip2start,
                      detailsRendered?.frip1start,
                      detailsRendered?.frip1end,
                      detailsRendered?.frip3start,
                      detailsRendered?.frip3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.frip2end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      satp2end: e,
                    }));
                  }}
                  value={detailsRendered?.satp2end}
                  referenceDate={dayjs(weekEnding).day(6)}
                  defaultValue={detailsRendered.satp2end}
                  disabled={p2disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.satp2start,
                      detailsRendered?.satp1start,
                      detailsRendered?.satp1end,
                      detailsRendered?.satp3start,
                      detailsRendered?.satp3end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.satp2end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
              </Stack>
              <EditTimeCardWeekLabels
                show={isMobile ? true : false}
                height={height}
                weekEnding={weekEnding}
                project={project3}
                handleChange={handleChange3}
                projectList={project}
                isMobile={isMobile}
              />
              <Stack spacing={1.75} sx={{ mt: isMobile ? '2rem' : 0 }}>
                <Typography>Hours Start</Typography>
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      sunp3start: e,
                    }));
                  }}
                  value={detailsRendered.sunp3start}
                  referenceDate={dayjs(weekEnding).day(0)}
                  defaultValue={detailsRendered.sunp3start}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDiableTimeStarts(
                      value,
                      view,
                      detailsRendered?.satp3end,
                      detailsRendered?.satp1start,
                      detailsRendered?.satp1end,
                      detailsRendered?.satp2start,
                      detailsRendered?.satp2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered.sunp3start
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      monp3start: e,
                    }));
                  }}
                  value={detailsRendered?.monp3start}
                  referenceDate={dayjs(weekEnding).day(1)}
                  defaultValue={detailsRendered.monp3start}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDiableTimeStarts(
                      value,
                      view,
                      detailsRendered?.monp3end,
                      detailsRendered?.monp1start,
                      detailsRendered?.monp1end,
                      detailsRendered?.monp2start,
                      detailsRendered?.monp2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered.monp3start
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      tuep3start: e,
                    }));
                  }}
                  value={detailsRendered?.tuep3start}
                  referenceDate={dayjs(weekEnding).day(2)}
                  defaultValue={detailsRendered.tuep3start}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDiableTimeStarts(
                      value,
                      view,
                      detailsRendered?.tuep3end,
                      detailsRendered?.tuep1start,
                      detailsRendered?.tuep1end,
                      detailsRendered?.tuep2start,
                      detailsRendered?.tuep2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.tuep3start
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      wedp3start: e,
                    }));
                  }}
                  value={detailsRendered?.wedp3start}
                  referenceDate={dayjs(weekEnding).day(3)}
                  defaultValue={detailsRendered.wedp3start}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDiableTimeStarts(
                      value,
                      view,
                      detailsRendered?.wedp3end,
                      detailsRendered?.wedp1start,
                      detailsRendered?.wedp1end,
                      detailsRendered?.wedp2start,
                      detailsRendered?.wedp2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.wedp3start
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      thurp3start: e,
                    }));
                  }}
                  value={detailsRendered?.thurp3start}
                  referenceDate={dayjs(weekEnding).day(4)}
                  defaultValue={detailsRendered.thurp3start}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDiableTimeStarts(
                      value,
                      view,
                      detailsRendered?.thurp3end,
                      detailsRendered?.thurp1start,
                      detailsRendered?.thurp1end,
                      detailsRendered?.thurp2start,
                      detailsRendered?.thurp2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.thurp3start
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      frip3start: e,
                    }));
                  }}
                  value={detailsRendered?.frip3start}
                  referenceDate={dayjs(weekEnding).day(5)}
                  defaultValue={detailsRendered.frip3start}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDiableTimeStarts(
                      value,
                      view,
                      detailsRendered?.frip3end,
                      detailsRendered?.frip1start,
                      detailsRendered?.frip1end,
                      detailsRendered?.frip2start,
                      detailsRendered?.frip2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.frip3start
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      satp3start: e,
                    }));
                  }}
                  value={detailsRendered?.satp3start}
                  referenceDate={dayjs(weekEnding).day(6)}
                  defaultValue={detailsRendered.satp3start}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDiableTimeStarts(
                      value,
                      view,
                      detailsRendered?.satp3end,
                      detailsRendered?.satp1start,
                      detailsRendered?.satp1end,
                      detailsRendered?.satp2start,
                      detailsRendered?.satp2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.satp3start
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={1.75} sx={{ mt: isMobile ? '2rem' : 0 }}>
                <Typography>Hours End</Typography>
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      sunp3end: e,
                    }));
                  }}
                  value={detailsRendered.sunp3end}
                  referenceDate={dayjs(weekEnding).day(0)}
                  defaultValue={detailsRendered.sunp3end}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.sunp3start,
                      detailsRendered?.sunp1start,
                      detailsRendered?.sunp1end,
                      detailsRendered?.sunp2start,
                      detailsRendered?.sunp2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered.sunp3end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      monp3end: e,
                    }));
                  }}
                  value={detailsRendered?.monp3end}
                  referenceDate={dayjs(weekEnding).day(1)}
                  defaultValue={detailsRendered.monp3end}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.monp3start,
                      detailsRendered?.monp1start,
                      detailsRendered?.monp1end,
                      detailsRendered?.monp2start,
                      detailsRendered?.monp2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.monp3end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      tuep3end: e,
                    }));
                  }}
                  value={detailsRendered?.tuep3end}
                  referenceDate={dayjs(weekEnding).day(2)}
                  defaultValue={detailsRendered.tuep3end}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.tuep3start,
                      detailsRendered?.tuep1start,
                      detailsRendered?.tuep1end,
                      detailsRendered?.tuep2start,
                      detailsRendered?.tuep2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.tuep3end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      wedp3end: e,
                    }));
                  }}
                  value={detailsRendered?.wedp3end}
                  referenceDate={dayjs(weekEnding).day(3)}
                  defaultValue={detailsRendered.wedp3end}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.wedp3start,
                      detailsRendered?.wedp1start,
                      detailsRendered?.wedp1end,
                      detailsRendered?.wedp2start,
                      detailsRendered?.wedp2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.wedp3end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      thurp3end: e,
                    }));
                  }}
                  value={detailsRendered?.thurp3end}
                  referenceDate={dayjs(weekEnding).day(4)}
                  defaultValue={detailsRendered.thurp3end}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.thurp3start,
                      detailsRendered?.thurp1start,
                      detailsRendered?.thurp1end,
                      detailsRendered?.thurp2start,
                      detailsRendered?.thurp2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.thurp3end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      frip3end: e,
                    }));
                  }}
                  value={detailsRendered?.frip3end}
                  referenceDate={dayjs(weekEnding).day(5)}
                  defaultValue={detailsRendered.frip3end}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.frip3start,
                      detailsRendered?.frip1start,
                      detailsRendered?.frip1end,
                      detailsRendered?.frip2start,
                      detailsRendered?.frip2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.frip3end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
                <DesktopTimePicker
                  views={['hours', 'minutes']}
                  onChange={(e) => {
                    setDetailsRendered((prev) => ({
                      ...prev,
                      satp3end: e,
                    }));
                  }}
                  value={detailsRendered?.satp3end}
                  referenceDate={dayjs(weekEnding).day(6)}
                  defaultValue={detailsRendered.satp3end}
                  disabled={p3disabled}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  shouldDisableTime={(value, view) =>
                    shouldDisableTimeEnd(
                      value,
                      view,
                      detailsRendered?.satp3start,
                      detailsRendered?.satp1start,
                      detailsRendered?.satp1end,
                      detailsRendered?.satp2start,
                      detailsRendered?.satp2end
                    )
                  }
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                    textField: {
                      size: 'small',
                      style: {
                        backgroundColor: !detailsRendered?.satp3end
                          ? '#00000000'
                          : '#3d3d3d',
                      },
                    },
                  }}
                />
              </Stack>
              <PayrollCreateSettingsModal
                open={openSettingsModal}
                handleClose={handleCloseSettingsModal}
                details={detailsRendered}
                setDetails={setDetailsRendered}
              />
            </Box>
            {/* End of unit box 1 */}
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
      <Box
        sx={{
          display: isMobile ? 'none' : 'grid',
          gridAutoColumns: '1fr',
          gap: 1,
        }}>
        <Box sx={{ gridRow: '1', gridColumn: '2/4' }} size='small'>
          {!touched ? null : (
            <TwoToneInlineText text1={`Project 1 Hours`} text2={project1hour} />
          )}
        </Box>

        <Box sx={{ gridRow: '1', gridColumn: '4/6' }} size='small'>
          {!touched ? null : (
            <TwoToneInlineText text1={`Project 2 Hours`} text2={project2hour} />
          )}
        </Box>
        <Box sx={{ gridRow: '1', gridColumn: '6/8' }} size='small'>
          {!touched ? null : (
            <TwoToneInlineText text1={`Project 3 Hours`} text2={project3hour} />
          )}
        </Box>
      </Box>
      {sentState ? (
        <Alert severity='success'>
          Payroll submitted successfully for Week Ending {weekEnding}
        </Alert>
      ) : null}
      {errState ? <Alert severity='error'>{errMsg}</Alert> : null}
      <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
        <Box>
          <TwoToneInlineText text1='Name' text2={detailsRendered?.fullName} />
          <TwoToneInlineText
            text1='Week Ending'
            text2={dayjs(weekEnding).format('LLL')}
          />
          <TwoToneInlineText
            text1='Gross Time'
            text2={project1hour + project2hour + project3hour}
          />
          <TwoToneInlineText
            text1='Break Time'
            text2={
              detailsRendered?.breaksun +
              detailsRendered?.breakmon +
              detailsRendered?.breaktue +
              detailsRendered?.breakwed +
              detailsRendered?.breakthur +
              detailsRendered?.breakfri +
              detailsRendered?.breaksat
            }
          />
        </Box>
        <Box sx={{ width: '330px' }}>
          <TwoToneInlineText
            text1='Load Start Times from Prev'
            text2={project1}
          />
          <FormControl size='small' fullWidth>
            <InputLabel id='demo-select-small-label'>Timecards</InputLabel>

            <Select
              labelId='load prev hours'
              id='load hours'
              value={seededHours}
              label='load hours'
              onChange={handleTimeCardChange}>
              <MenuItem value={''}>Load Hours From Previous</MenuItem>
              {prevTimecards.length > 0 &&
                prevTimecards.map((timecard, index) => (
                  <MenuItem key={index} value={timecard.name}>
                    {timecard.name}
                  </MenuItem>
                ))}
              <MenuItem value={null}>
                <em>Default Settings</em>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Stack>

      <Button
        variant='contained'
        // sx={{ backgroundColor: colors.greenAccent[500], m: 1 }}
        disabled={errState || p1disabled}
        onClick={() => submitTimeCard(detailsRendered)}
        endIcon={<SendIcon />}>
        Submit
      </Button>
    </Box>
  );
};

export default PayrollCreateV3Body;
