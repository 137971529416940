import { Box, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Row1 from '../dashboard3/Row1';
import Row2 from '../dashboard3/Row2';
import Row3 from '../dashboard3/Row3';
import dashboardConfig from '../fakedata/dashboardConfig';
import useFetch from '../hooks/fetchAPI';
import threeWeekLookBack from '../hooks/three-week-lookback';
import filteredBarGraphData from '../hooks/fx-create-bar-graph-array';
import { useEffect, useState } from 'react';
import LoadingCircle from '../components/LoadingCircle';
import dayjs from 'dayjs';
import { useAuth } from '../context/AuthProvider';
import TopBarDashboardSettings from '../components/TopBarDashboardSettings';

var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);

const Dashboard3 = () => {
  const {
    gridTemplateLargeScreens,
    gridTemplateMediumScreens,
    gridTemplateSmallScreens,
  } = dashboardConfig();
  const { sixWkBkDesc } = threeWeekLookBack();
  const isLargeScreen = useMediaQuery('(min-width: 1200px)');
  const isMediumScreen = useMediaQuery(
    '(min-width:768px) and (max-width:1199px)'
  );

  const gridStyles = isLargeScreen
    ? {
        gridTemplateColumns: 'repeat(3, minmax(330px, 1fr))',
        gridTemplateRows: 'repeat(10, minmax(30px, 1fr))',
        gridTemplateAreas: gridTemplateLargeScreens,
        padding: '1rem',
      }
    : isMediumScreen
    ? {
        gridTemplateColumns: 'repeat(2, minmax(330px, 1fr))',
        gridTemplateRows: 'repeat(15, minmax(30px, 1fr))',
        gridTemplateAreas: gridTemplateMediumScreens,
        padding: '2rem',
      }
    : {
        gridAutoColumns: '1fr',
        gridAutoRows: '55px',
        gridTemplateAreas: gridTemplateSmallScreens,
        padding: '4rem',
      };

  const PrevWeekNumber = dayjs().day(-1).week();
  const { api } = useAuth();
  const [progress, setProgress] = useState(10);
  const [isLoaded, setisLoaded] = useState(false);
  const [listData, setListData] = useState([]);
  const [changeOrderData, setChangeOrderData] = useState([]);
  const [COProject, setCOProject] = useState('All Projects');
  const [rfiData, setRfiData] = useState([]);
  const [RFIProject, setRFIProject] = useState('All Projects');
  const [swData, setSwData] = useState([]);
  const [TimecardRange, setTimecardRange] = useState([]);
  const [searchFilters, setSearchFilters] = useState({
    approved: false,
    status: 'Incomplete',
    project: 'All Projects',
  });
  const [monthlySearchFilters, setMonthlySearchFilters] = useState({
    startYear: dayjs().subtract(1, 'year').year(),
    startMonth: dayjs().subtract(12, 'month').month() + 1,
    endYear: dayjs().year(),
    endMonth: dayjs().month() + 1,
    project: 'All Projects',
    approved: false,
  });
  console.log('monthlySearchFilters', monthlySearchFilters);
  const [timecards, setTimecards] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    totaldocuments: 0,
  });
  const [error, setError] = useState({
    timecards: false,
    rfi: false,
    co: false,
    sw: false,
  });
  const [errMsg, seterrMsg] = useState({
    timecards: '',
    rfi: '',
    co: '',
    sw: '',
  });

  const weeksObject = threeWeekLookBack();
  const [startDate, setStartDate] = useState(
    new Date(weeksObject.sixWkBkDesc[0])
  );
  const [endDate, setEndDate] = useState(new Date(weeksObject.sixWkBkDesc[6]));

  useEffect(() => {
    let timer;
    if (progress < 100) {
      timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 10
        );
      }, 100);
    }

    if (progress === 100) {
      setisLoaded(true);
    }

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  function checkFailed(then) {
    return function (responses) {
      const someFailed = responses.some((response) => response.error);

      if (someFailed) {
        throw responses;
      }

      return then(responses);
    };
  }

  useEffect(() => {
    async function fetchMultipleDataStreams(...prevPayroll) {
      await Promise.all([
        api.get(`/api/v1/rfi/?page=1&limit=20&project=${RFIProject}`),
        api.get(`/api/v1/changeorder/?page=1&limit=20&project=${COProject}`),
        api.get('/api/v1/projects/list'),
        api.get('/api/v1/payroll/weekly'),
        api.get(
          `/api/v1/timecardsv2/range?page=1&limit=100&mode=range&start=${startDate}&end=${endDate}`
        ),
      ])
        .then(
          checkFailed(([rfi, co, projectList, payroll, timecardsv2]) => {
            setListData((prev) => ({
              ...prev,
              rfi,
              co,
              projectList,
              payroll,
              timecardsv2,
            }));
          })
        )
        .catch((err) => {
          console.log('FAIL', err);
        });
    }

    if (api.authenticated) {
      fetchMultipleDataStreams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api?.authenticated, COProject, RFIProject]);

  useEffect(() => {
    async function fetchMultipleDataStreams() {
      await Promise.all([
        api.get(
          `/api/v1/timecardsv2/range?page=1&limit=100&mode=range&start=${startDate}&end=${endDate}`
        ),
      ])
        .then(
          checkFailed(([timecards]) => {
            console.log('SUCCESS', timecards);

            const array = timecards?.data?.data[0].results;
            if (array) {
              setTimecardRange(array);
            } else {
              setTimecardRange([]);
            }
          })
        )
        .catch((err) => {
          console.log('FAIL', err);
          setTimecardRange([]);
        });
    }

    if (api.authenticated) {
      fetchMultipleDataStreams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  //const result = filteredBarGraphData(sixWkBkDesc, data);

  //useEffect for change in search filters
  useEffect(() => {
    setisLoaded(false);
    let renderedData = [];

    if (api.authenticated) {
      api

        .get(
          `/api/v1/timecardsv3/mytimecards/?page=1&limit=20&approved=${searchFilters.approved}&status=${searchFilters.status}&project=${searchFilters.project}`
        )
        .then((response) => {
          console.log(response);
          setTimecards(response.data.data);
        })
        .catch((err) => {
          console.log(err);
          setTimecards([]);
          setPagination((prev) => ({
            ...prev,
            page: 1,
            totaldocuments: 0,
          }));
        })
        .finally(() => {
          setisLoaded(true);
          setTimecards(renderedData);
          if (renderedData.length === 0) {
            setError((prev) => ({
              ...prev,
              timecards: true,
            }));
            seterrMsg((prev) => ({
              ...prev,
              timecards: 'No timecards found',
            }));
            setPagination((prev) => ({
              ...prev,
              page: 1,
              totaldocuments: 0,
            }));
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, searchFilters]);

  //useEffect for change in search filters
  useEffect(() => {
    setisLoaded(false);
    let renderedCOData = [];

    if (api.authenticated) {
      api

        .get(`/api/v1/changeorder/?page=1&limit=20&project=${COProject}`)
        .then((response) => {
          console.log(response);
          setChangeOrderData(response.data.data[0].results);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setisLoaded(true);

          if (renderedCOData.length === 0) {
            setError((prev) => ({
              ...prev,
              co: true,
            }));
            seterrMsg((prev) => ({
              ...prev,
              co: 'No change orders found',
            }));
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  //useEffect for change in search filters
  useEffect(() => {
    setisLoaded(false);
    let renderedRFIData = [];
    setError((prev) => ({
      ...prev,
      rfi: false,
    }));
    seterrMsg((prev) => ({
      ...prev,
      rfi: '',
    }));
    if (api.authenticated) {
      api

        .get(`/api/v1/rfi/?page=1&limit=20&project=${RFIProject}`)
        .then((response) => {
          console.log(response);
          setRfiData(response.data.data[0].results);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setisLoaded(true);

          if (renderedRFIData.length === 0) {
            setError((prev) => ({
              ...prev,
              rfi: true,
            }));
            seterrMsg((prev) => ({
              ...prev,
              rfi: 'No rfis found',
            }));
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  //useEffect for change in search filters
  useEffect(() => {
    setisLoaded(false);
    let renderedSWData = [];
    setError((prev) => ({
      ...prev,
      sw: false,
    }));
    seterrMsg((prev) => ({
      ...prev,
      sw: '',
    }));
    if (api.authenticated) {
      api

        .get(
          `/api/v1/timecardsv3/sandw/?page=1&limit=20&startYear=${monthlySearchFilters.startYear}&startMonth=${monthlySearchFilters.startMonth}&endYear=${monthlySearchFilters.endYear}&endMonth=${monthlySearchFilters.endMonth}&project=All Projects&approved=false`
        )
        .then((response) => {
          console.log(response);
          renderedSWData = response.data.data.map((sw, i) => ({
            id: sw._id.month + '/' + sw._id.year,
            month: sw._id.month,
            year: sw._id.year,
            key: sw._id.month + '/' + sw._id.year,
            trade: sw._id.trade,
            reg: sw.regHours,
            ot: sw.overtimeHours,
            sat: sw.satHours,
            sun: sw.sunHours,
            totalHours: sw.totalHours,
          }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setisLoaded(true);
          setSwData(renderedSWData);
          if (renderedSWData.length === 0) {
            setError((prev) => ({
              ...prev,
              sw: true,
            }));
            seterrMsg((prev) => ({
              ...prev,
              sw: 'No S&W Data found',
            }));
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, monthlySearchFilters]);

  return (
    <Box width='100%'>
      {isLoaded ? (
        <Box>
          <TopBarDashboardSettings
            monthlySearchFilters={monthlySearchFilters}
            setMonthlySearchFilters={setMonthlySearchFilters}
          />
          <Box width='100%' display='grid' gap='1.5rem' sx={gridStyles}>
            <Row1
              timecardData={timecards}
              error={error.timecards}
              errMsg={errMsg.timecards}
              unfilteredData={timecards}
              label={PrevWeekNumber}
            />
            <Row2
              listData={listData}
              swData={swData}
              error={error}
              errMsg={errMsg}
            />
            <Row3
              changeOrderData={changeOrderData}
              rfiData={rfiData}
              COProject={COProject}
              setCOProject={setCOProject}
              RFIProject={RFIProject}
              setRFIProject={setRFIProject}
              listData={listData}
              error={error}
              errMsg={errMsg}
            />
          </Box>
        </Box>
      ) : (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'>
          <Grid item xs={3} elevation={3}>
            <LoadingCircle
              progress={progress}
              setProgress={setProgress}
              isLoaded={isLoaded}
              setisLoaded={setisLoaded}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Dashboard3;
