import Popover from "@mui/material/Popover";
import { Box } from "@mui/material";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import StopIcon from "@mui/icons-material/Stop";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

const TimeCardOptionsPopover = ({
	idPopOut,
	openPopOut,
	anchorElPopOut,
	setAnchorElPopOut,
	details,
	handlePopOutClickEditPayroll,
}) => {
	const [showActiveButtons, setShowActiveButtons] = useState(true);
	const [showInActiveButtons, setShowInctiveButtons] = useState(true);
	const handlePopOutClose = () => {
		setAnchorElPopOut(null);
	};

	useEffect(() => {
		if (details?.startTime) {
			const start = dayjs(details.startTime).unix();
			const now = dayjs().unix();
			const elapsed = now - start;
			//86400 is 24 hours in unix time
			if (elapsed > 86400) {
				setShowActiveButtons(false);
				setShowInctiveButtons(true);
			} else {
				setShowActiveButtons(true);
				setShowInctiveButtons(false);
			}
		}
	}, [details]);
	//   const id = open ? 'simple-popover' : undefined;

	useEffect(() => {
		let renderedData;
		if (Object.keys(details).length > 0) {
			// renderedData = details.map((timeclock, i) => ({
			// 	id: timeclock.employeeID,
			// 	analytics: timeclock.analytics,
			// 	monday: timeclock.monday,
			// 	monOTHours: timeclock.monOTHours,
			// 	mondayHours: timeclock.mondayHours,
			// 	tuesday: timeclock.tuesday,
			// 	tueOTHours: timeclock.tueOTHours,
			// 	tuesdayHours: timeclock.tuesdayHours,
			// 	wednesday: timeclock.wednesday,
			// 	wedOTHours: timeclock.wedOTHours,
			// 	wednesdayHours: timeclock.wednesdayHours,
			// 	thursday: timeclock.thursday,
			// 	thurOTHours: timeclock.thurOTHours,
			// 	thursdayHours: timeclock.thursdayHours,
			// 	friday: timeclock.friday,
			// 	friOTHours: timeclock.friOTHours,
			// 	fridayHours: timeclock.fridayHours,
			// 	saturday: timeclock.saturday,
			// 	satHours: timeclock.satHours,
			// 	sunday: timeclock.sunday,
			// 	sunHours: timeclock.sunHours,
			// 	status: timeclock.status,
			// 	employeeID: timeclock.employeeID,
			// 	fullName: timeclock.fullName,
			// 	weekEnd: dayjs(timeclock.weekEnd),
			// 	status: timeclock.status,
			// 	createdAt: dayjs(timeclock.createdAt),
			// 	updatedAt: dayjs(timeclock.updatedAt),
			// }));
			details.weekEnd = dayjs(details.weekEnd);
			details.createdAt = dayjs(details.createdAt);
			details.updatedAt = dayjs(details.updatedAt);
			// details.sunday = details.sunday[0].log.map((elem) => {
			// 	return {
			// 		...elem,
			// 		start: elem.start ? dayjs(elem.start) : null,
			// 		end: elem.end ? dayjs(elem.end) : null,
			// 	};
			// });
		}
	}, [details]);

	return (
		<Box>
			{/* <Button aria-describedby={id} onClick={handleClick}>
        Query
      </Button> */}
			<Popover
				id={idPopOut}
				open={openPopOut}
				anchorEl={anchorElPopOut}
				onClose={handlePopOutClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Paper sx={{ width: 100 }}>
					<MenuList dense>
						{showActiveButtons ? (
							<MenuItem>
								<ListItemIcon>
									<PlayArrowIcon />
								</ListItemIcon>
								<ListItemText>Resume</ListItemText>
							</MenuItem>
						) : null}
						{showInActiveButtons ? (
							<MenuItem
								onClick={(e) => handlePopOutClickEditPayroll(e, details)}
							>
								<ListItemIcon>
									<EditIcon />
								</ListItemIcon>
								<ListItemText>Edit</ListItemText>
							</MenuItem>
						) : null}
						{showActiveButtons ? (
							<MenuItem>
								<ListItemIcon>
									<StopIcon />
								</ListItemIcon>
								<ListItemText>End</ListItemText>
							</MenuItem>
						) : null}
						<MenuItem>
							<ListItemIcon>
								<ClearIcon />
							</ListItemIcon>
							<ListItemText>Del</ListItemText>
						</MenuItem>
						{showActiveButtons ? (
							<MenuItem>
								<ListItemIcon>
									<PendingActionsIcon />
								</ListItemIcon>
								<ListItemText>Approve</ListItemText>
							</MenuItem>
						) : null}
					</MenuList>
				</Paper>
			</Popover>
		</Box>
	);
};

export default TimeCardOptionsPopover;
