import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, Stack } from '@mui/material';
import { tokens } from '../theme';

const AnalyticsBoxHeader = ({ title, subtitle, sidetext, color }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const defaultGrey = colors.grey[700];
  const [subtitleColor, setSubtitleColor] = useState(defaultGrey);

  useEffect(() => {
    if (color) {
      setSubtitleColor(color);
    }
  }, [color]);

  return (
    <Box ml='10px'>
      <Stack direction='row'>
        <Box>
          <Typography color={colors.greenAccent[700]}> {title} </Typography>
          <Typography color={subtitleColor}> {subtitle} </Typography>
        </Box>
        <Typography color={colors.greenAccent[500]} align='right'>
          {' '}
          {sidetext}{' '}
        </Typography>
      </Stack>
    </Box>
  );
};

export default AnalyticsBoxHeader;
