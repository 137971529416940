import Popover from "@mui/material/Popover";
import { Box } from "@mui/material";
import PayrollEdit4 from "./PayrollEdit4";

const EditPayrollPopOver = ({
	idPopOut,
	openPopOut,
	anchorElPopOut,
	setAnchorElPopOut,
	details,
	user,
}) => {
	const handlePopOutClose = () => {
		setAnchorElPopOut(null);
	};

	return (
		<Box>
			<Popover
				id={idPopOut}
				open={openPopOut}
				anchorEl={anchorElPopOut}
				onClose={handlePopOutClose}
				anchorOrigin={{
					vertical: "center",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "center",
					horizontal: "center",
				}}
			>
				<PayrollEdit4 user={user} initialDetails={details} />
			</Popover>
		</Box>
	);
};

export default EditPayrollPopOver;
