import { useState, useEffect, useMemo } from 'react';
import { Box, Grid, Typography, useTheme, Button, Stack } from '@mui/material';
import TopBarAdmin from '../components/TopBarAdmin';
import PayrollCreateV3 from '../components/PayrollCreateV3';
import useFetch from '../hooks/fetchAPI';
import usePath from '../hooks/fetchPath';
import { tokens } from '../theme';
import dayjs from 'dayjs';
//utils
import { useAuth } from '../context/AuthProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import Skeleton from '@mui/material/Skeleton';
import { personnel } from './../fakedata/mockData';

function PayrollCreatePageV3({ user, auth }) {
  const { api } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [listData, setListData] = useState(null);
  const [errMsg, seterrMsg] = useState('');
  const [prevTimecards, setPrevTimecards] = useState([]);
  const isoWeek = require('dayjs/plugin/isoWeek');
  dayjs.extend(isoWeek);
  const lastWeekend = dayjs().day() === 6 ? dayjs() : dayjs().day(6 - 7);
  const [searchFilters, setSearchFilters] = useState({
    approved: false,
    status: 'Incomplete',
    project: 'All Projects',
    weekNumberStart: dayjs().subtract(8, 'week').isoWeek(),
    weekStartString: dayjs().subtract(8, 'week').day(6).format('MM/DD/YYYY'),
    yearStart: dayjs().subtract(8, 'week').year(),
    weekNumberEnd: lastWeekend.isoWeek(),
    weekEndString: lastWeekend.day(6).format('MM/DD/YYYY'),
    yearEnd: lastWeekend.year(),
  });
  //themes
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [errState, seterrState] = useState(false);
  const [notificationsNumber, setnotificationsNumber] = useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  function checkFailed(then) {
    return function (responses) {
      const someFailed = responses.some((response) => response.error);

      if (someFailed) {
        throw responses;
      }

      return then(responses);
    };
  }

  useEffect(() => {
    setIsLoading(true);
    if (api.authenticated) {
      Promise.all([
        api.get('/api/v1/personnel?sort=fullName&limit=1000'),
        api.get('/api/v1/projects/list'),
      ])
        .then(
          checkFailed(([personnel, projects]) => {
            setListData((prev) => ({
              ...prev,
              personnel,
              projects,
            }));
          })
        )
        .catch((err) => {
          console.log('FAIL', err);
          setError(true);
          seterrMsg('Error Loading Data');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useEffect(() => {
    setIsLoading(true);
    let renderedData = [];

    if (api.authenticated) {
      api

        .get(
          `/api/v1/timecardsv3/mytimecards/?page=1&limit=20&approved=${searchFilters.approved}&status=${searchFilters.status}&project=${searchFilters.project}&weekNumberStart=${searchFilters.weekNumberStart}&yearStart=${searchFilters.yearStart}&weekNumberEnd=${searchFilters.weekNumberEnd}&yearEnd=${searchFilters.yearEnd}`
        )
        .then((response) => {
          if (response?.data?.data[0]?.results) {
            const formatDate = (date) =>
              date ? dayjs(date).format('MM/DD/YYYY') : null;
            const formatDay = (date) => (date ? dayjs(date) : null);

            renderedData = response?.data?.data[0].results.map((timecard) => {
              const formattedTimecard = {
                ...timecard,
                weekEndingString: formatDate(timecard.weekEnding),
                createdAt: formatDate(timecard.createdAt),
                updatedAt: formatDate(timecard.updatedAt),
              };

              const periods = ['p1', 'p2', 'p3'];
              const days = ['sun', 'mon', 'tue', 'wed', 'thur', 'fri', 'sat'];

              periods.forEach((period) => {
                days.forEach((day) => {
                  formattedTimecard[`${day}${period}start`] = formatDay(
                    timecard[`${day}${period}start`]
                  );
                  formattedTimecard[`${day}${period}end`] = formatDay(
                    timecard[`${day}${period}end`]
                  );
                });
              });

              return formattedTimecard;
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setPrevTimecards([]);
        })
        .finally(() => {
          setIsLoading(false);
          setPrevTimecards(renderedData);
          if (renderedData.length === 0) {
            setError(true);
            seterrMsg('No Timecards Found');
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, searchFilters]);

  useEffect(() => {
    async function GetResults(data) {
      try {
        const response = await api.get(
          `/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
        );
        setnotificationsNumber(response?.data.data[0].results.length);
        seterrState(false);
      } catch (err) {
        console.log('Failed', { err });
        seterrState(true);
      }
    }
    GetResults();
  }, [api]);

  return (
    <Box>
      {isLoading ? (
        <Grid
          container
          spacing={0}
          justifyContent='center'
          direction='column'
          alignItems='center'
          sx={{ minHeight: '100vh' }}>
          <Grid item xs={3}>
            <Stack spacing={1.75}>
              {/* For other variants, adjust the size with `width` and `height` */}
              <Stack spacing={1} direction='row'>
                <Skeleton variant='circular' width={50} height={50} />
                <Box>
                  <Skeleton variant='rounded' width={100} height={50} />
                </Box>
                <Box>
                  <Skeleton variant='rounded' width={150} height={50} />
                </Box>
                <Box>
                  <Skeleton variant='rounded' width={175} height={50} />
                </Box>
              </Stack>
              <Skeleton variant='rounded' width={500} height={50} />
              <Skeleton variant='rounded' width={500} height={50} />
              <Skeleton variant='rounded' width={500} height={50} />
              <Skeleton variant='rounded' width={500} height={50} />
              <Skeleton variant='rounded' width={500} height={50} />
              <Skeleton variant='rounded' width={500} height={50} />
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Box width='95%' height='calc(100vh + 100px)' margin='auto'>
          <TopBarAdmin notificationsNumber={notificationsNumber} />

          <PayrollCreateV3 listData={listData} prevTimecards={prevTimecards} />
        </Box>
      )}

      {error && <Typography>{errMsg}</Typography>}
    </Box>
  );
}

export default PayrollCreatePageV3;
// details.project1[0].hours[0].grossHoursTotal
