//mui typography two tone inline text
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const TwoToneInlineGrey = ({ text1, text2 }) => {
  return (
    <Box>
      <Box display='inline'>
        <Typography variant='body1' component='span' color='grey'>
          {text1}
        </Typography>
        <Typography variant='body1' component='span' color='grey'>
          {': '}
        </Typography>
        <Typography variant='body1' component='span' color='white'>
          {text2}
        </Typography>
      </Box>
    </Box>
  );
};

export default TwoToneInlineGrey;
