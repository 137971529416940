import { useRef, useState, useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Navigate, useNavigate } from 'react-router-dom';
import { tokens } from '../theme';
import {
  Box,
  Button,
  useTheme,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SignUpModal from '../components/SignUpModal';

const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { login, api, success, setSuccess } = useAuth();
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    navigate('/Login');
  };
  const handleTrialClick = () => {
    setShowSignUpModal(true);
  };
  const handleCancelSignUp = () => {
    setShowSignUpModal(false);
  };

  const handleSuccessSignUp = () => {
    console.log('Handle Success Ran');
    setShowSignUpModal(false);
    setSuccess(true);
    handleClick();
  };

  useEffect(() => {
    setErrMsg('');
  }, [email, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    api
      .post('/api/v1/auth/login', { email: email, password: pwd })
      .then((response) => {
        login(response.data.accessToken);
      })
      .catch((err) => {
        console.log(err);
        if (!err?.response) {
          setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
          setErrMsg('Error : ' + err.response?.data.error);
          console.log(err.response?.data.error);
        } else if (err.response?.status === 401) {
          setErrMsg('UnAuthorized');
        } else {
          setErrMsg('Login Failed');
        }
        errRef.current.focus();
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.primary[400],
        width: '100%',
        p: '30px',
        textAlign: 'center',
      }}>
      {success ? (
        <Navigate replace to='/Dashboard' />
      ) : (
        <section>
          <form onSubmit={handleSubmit}>
            <Box sx={{ backgroundColor: colors.primary[400] }}>
              <Stack spacing={2}>
                <TextField
                  id='email'
                  data-testid='email'
                  label='Email'
                  ref={userRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  variant='standard'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />

                <FormControl sx={{ m: 1, width: '100%' }} variant='standard'>
                  <InputLabel htmlFor='standard-adornment-password'>
                    Password
                  </InputLabel>
                  <Input
                    id='standard-adornment-password'
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl>
                  <Stack sx={{ pt: '10px', width: '100%' }}>
                    <Button
                      type='submit'
                      variant='contained'
                      onClick={handleSubmit}
                      sx={{
                        color: colors.greenAccent[500],
                        backgroundColor: colors.primary[500],
                      }}>
                      Sign In
                    </Button>
                  </Stack>
                  <Typography
                    ref={errRef}
                    className={errMsg ? 'errmsg' : 'offscreen'}
                    aria-live='assertive'
                    sx={{ pt: '10px', color: colors.redAccent[500] }}>
                    {errMsg}
                  </Typography>
                </FormControl>
              </Stack>
              <Box
                sx={{
                  pt: '10px',
                }}>
                <Stack
                  direction='row'
                  spacing={0.5}
                  justifyContent='center'
                  alignItems='center'>
                  <Button onClick={handleTrialClick}>Create An Account</Button>
                  <SignUpModal
                    open={showSignUpModal}
                    onCancel={handleCancelSignUp}
                    onSuccess={handleSuccessSignUp}
                  />
                </Stack>
                <a href='/ForgotPassword'>
                  <Typography
                    sx={{
                      pt: '10px',
                      color: colors.blueAccent[500],
                      textDecoration: 'underline',
                    }}>
                    Forgot Password
                  </Typography>
                </a>
              </Box>
            </Box>
          </form>
        </section>
      )}
    </Box>
  );
};

export default Login;
