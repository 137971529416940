import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';

const TimecardFilters = ({
  searchFilters,
  setSearchFilters,
  listData,
  weekEndingSaturdays,
}) => {
  console.log('searchFilters', searchFilters);
  const handleChange = (event) => {
    setSearchFilters((prev) => ({
      ...prev,
      approved: event.target.value,
    }));
  };

  const handleStatusChange = (event) => {
    setSearchFilters((prev) => ({
      ...prev,
      status: event.target.value,
    }));
  };

  const handleProjectChange = (event) => {
    setSearchFilters((prev) => ({
      ...prev,
      project: event.target.value,
    }));
  };

  const handleWeekStartChange = (event) => {
    setSearchFilters((prev) => ({
      ...prev,
      weekNumberStart: weekEndingSaturdays.find(
        (week) => week.weekEnd === event.target.value
      ).weekNumber,
      weekStartString: event.target.value,
      yearStart: weekEndingSaturdays.find(
        (week) => week.weekEnd === event.target.value
      ).year,
    }));
  };

  const handleWeekEndChange = (event) => {
    setSearchFilters((prev) => ({
      ...prev,
      weekNumberEnd: weekEndingSaturdays.find(
        (week) => week.weekEnd === event.target.value
      ).weekNumber,
      weekEndString: event.target.value,
      yearEnd: weekEndingSaturdays.find(
        (week) => week.weekEnd === event.target.value
      ).year,
    }));
  };

  return (
    <Stack direction='row' spacing={1} sx={{ p: 1, width: '100%' }}>
      <FormControl variant='outlined' fullWidth>
        <InputLabel id='timecard-filter-label'>Approved</InputLabel>
        <Select
          labelId='timecard-filter-label'
          value={searchFilters?.approved}
          onChange={handleChange}
          label='Approved'>
          <MenuItem value={false}>False</MenuItem>
          <MenuItem value={true}>True</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant='outlined' fullWidth>
        <InputLabel id='timecard-filter-label'>Status</InputLabel>
        <Select
          labelId='timecard-filter-label'
          value={searchFilters?.status}
          onChange={handleStatusChange}
          label='Status'>
          <MenuItem value='Complete'>Complete</MenuItem>
          <MenuItem value='Incomplete'>Incomplete</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant='outlined' fullWidth>
        <InputLabel id='timecard-filter-label'>Select A Project</InputLabel>
        <Select
          labelId='timecard-filter-label'
          value={searchFilters?.project}
          onChange={handleProjectChange}
          label='Project'>
          <MenuItem value='All Projects'>All Projects</MenuItem>
          {listData?.projects?.data?.data.map((project, index) => (
            <MenuItem key={index} value={project}>
              {project}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant='outlined' fullWidth>
        <InputLabel id='timecard-filter-label'>Week Start</InputLabel>
        <Select
          labelId='timecard-filter-label'
          value={searchFilters?.weekStartString}
          onChange={handleWeekStartChange}
          label='Project'>
          <MenuItem value='All Projects'>All Projects</MenuItem>
          {weekEndingSaturdays.map((project, index) => (
            <MenuItem key={index} value={project.weekEnd}>
              {project.weekEnd}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant='outlined' fullWidth>
        <InputLabel id='timecard-filter-label'>Week End</InputLabel>
        <Select
          labelId='timecard-filter-label'
          value={searchFilters?.weekEndString}
          onChange={handleWeekEndChange}
          label='Project'>
          <MenuItem value='All Projects'>All Projects</MenuItem>
          {weekEndingSaturdays.map((project, index) => (
            <MenuItem key={index} value={project.weekEnd}>
              {project.weekEnd}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default TimecardFilters;
