import { useState, useEffect } from "react";

function TimeClockUnixElapsed({ start }) {
	const unixTime = start;
	const [elapsedTime, setElapsedTime] = useState("");

	// Helper function to calculate the elapsed time
	const getElapsedTime = (timestamp) => {
		const seconds = Math.floor((Date.now() - timestamp * 1000) / 1000);
		let interval = Math.floor(seconds / 31536000);

		if (interval > 1) return `${interval} years ago`;
		interval = Math.floor(seconds / 2592000);
		if (interval > 1) return `${interval} months ago`;
		interval = Math.floor(seconds / 86400);
		if (interval > 1) return `${interval} days ago`;
		interval = Math.floor(seconds / 3600);
		if (interval > 1) return `${interval} hours ago`;
		interval = Math.floor(seconds / 60);
		if (interval > 1) return `${interval} minutes ago`;

		return `${Math.floor(seconds)} seconds ago`;
	};

	useEffect(() => {
		const updateElapsedTime = () => {
			setElapsedTime(getElapsedTime(unixTime));
		};

		// Update the elapsed time every second
		const intervalId = setInterval(updateElapsedTime, 1000);

		// Initial call to display the time immediately
		updateElapsedTime();

		// Clean up the interval on component unmount
		return () => clearInterval(intervalId);
	}, [unixTime]);

	return <span>{elapsedTime}</span>;
}

export default TimeClockUnixElapsed;
