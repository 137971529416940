import {
  AppBar,
  Container,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  Box,
  Menu,
  MenuItem,
  useTheme,
} from '@mui/material';
import { useState, useEffect } from 'react';
import useScrollPosition from '../hooks/useScrollPosition';
import DailyLogo1 from '../assets/Daily.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Title from './title';

const options = [
  { name: 'Home', path: '/' },
  { name: 'About', path: '/AboutPage' },
  { name: 'Features', path: '/features' },
  { name: 'Terms And Agreements', path: '/TermsAndAgreementPage' },
  { name: 'Privacy Policy', path: '/PrivacyPolicyPage' },
  { name: 'Contact', path: '/ContactPage' },
  { name: 'FAQ', path: '/FAQPage' },
  { name: 'Login', path: '/Login' },
];

const LinkButton = ({ children, ...props }) => (
  <Stack
    direction='row'
    alignItems='center'
    spacing={0.2}
    sx={{
      cursor: 'pointer',
      color: 'text.secondary',
      '&:hover': { color: 'text.primary' },
    }}
    {...props}>
    {children}
  </Stack>
);

const LandingPageNavBar = () => {
  const scrollPosition = useScrollPosition();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  //Menu Options
  const handleClick = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElMenu(null);
  };
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const open = Boolean(anchorElMenu);
  const handleClick2 = (event) => {
    setAnchorElMenu2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorElMenu2(null);
  };
  const [anchorElMenu2, setAnchorElMenu2] = useState(null);
  const open2 = Boolean(anchorElMenu2);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleMenuItemClick = (event, index) => {
    console.log(event.target.value);
    setSelectedIndex(index);
    setAnchorElMenu(null);
  };

  useEffect(() => {
    if (window.location.pathname === '/') {
      setSelectedIndex(0);
    } else if (window.location.pathname === '/AboutPage') {
      setSelectedIndex(1);
    } else if (window.location.pathname === '/features') {
      setSelectedIndex(2);
    } else if (window.location.pathname === '/TermsAndAgreementPage') {
      setSelectedIndex(3);
    } else if (window.location.pathname === '/PrivacyPolicyPage') {
      setSelectedIndex(4);
    } else if (window.location.pathname === '/ContactPage') {
      setSelectedIndex(5);
    } else if (window.location.pathname === '/FAQPage') {
      setSelectedIndex(6);
    }
  }, []);

  return (
    <AppBar
      elevation={0}
      sx={{
        py: 1,
        height: 95,
        bgcolor: scrollPosition > 10 ? 'rgba(7, 7, 16, .7)' : 'transparent',
        backdropFilter: scrollPosition > 10 && 'blur(60px)',
      }}>
      <Container
        sx={{
          [theme.breakpoints.up('lg')]: {
            maxWidth: '1380px!important',
          },
        }}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          flexWrap='wrap'>
          <Link to={'/'}>
            <img
              src={DailyLogo1}
              style={{ height: '75px', objectFit: 'contain' }}
              alt='buildlogo'
            />
          </Link>
          {!isMobile ? (
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='center'
              spacing={6}
              sx={{ flex: 1 }}
              flexWrap='wrap'>
              <Link to={'/'}>
                {' '}
                <Title variant={{ xs: 'joysmall', md: 'joymed' }}>
                  {' '}
                  Home{' '}
                </Title>{' '}
              </Link>
              <Link to={'/features'}>
                {' '}
                <Title variant={{ xs: 'joysmall', md: 'joymed' }}>
                  {' '}
                  Features{' '}
                </Title>{' '}
              </Link>
              <LinkButton onClick={handleClick}>
                <Stack direction='row'>
                  <Title variant={{ xs: 'joysmall', md: 'joymed' }}>
                    {' '}
                    Links{' '}
                  </Title>
                  <KeyboardArrowDownIcon fontSize='small' />
                </Stack>
              </LinkButton>
              <Box sx={{ position: 'fixed' }}>
                <Menu
                  id='lock-menu'
                  anchorEl={anchorElMenu}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                  }}>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.name}
                      value={option.name}
                      selected={index === selectedIndex}
                      // onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <Link
                        to={option.path}
                        sx={
                          index === selectedIndex ? { color: 'success' } : null
                        }>
                        {' '}
                        {option.name}{' '}
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Stack>
          ) : null}
          {isMobile ? (
            <Box>
              <IconButton onClick={handleClick2}>
                <MenuIcon sx={{ color: 'text.secondary' }} />
              </IconButton>
              <Box sx={{ position: 'fixed' }}>
                <Menu
                  id='lock-menu2'
                  anchorEl={anchorElMenu2}
                  open={open2}
                  onClose={handleClose2}
                  MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                  }}>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.name}
                      selected={index === selectedIndex}
                      //onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <Link variant='body2' to={option.path}>
                        {' '}
                        <li>{option.name} </li>
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          ) : (
            <Stack direction='row' spacing={5} alignItems='center'>
              <LinkButton spacing={1}>
                <LanguageIcon fontSize='small' />
                <Typography variant='body2'>EN</Typography>
              </LinkButton>
            </Stack>
          )}
        </Stack>
      </Container>
    </AppBar>
  );
};

export default LandingPageNavBar;
