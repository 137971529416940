import {
  Box,
  colors,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import Title from '../components/title';
import { tokens } from '../theme';

const CustomCounter = ({
  before = '',
  after = '',
  duration,
  counter,
  subtitle,
  colors,
  decimals = false,
}) => (
  <Stack spacing={{ xs: 0, md: 1 }} alignItems='center'>
    <CountUp
      delay={0}
      prefix={before}
      suffix={after}
      start={0}
      end={counter}
      duration={duration}
      decimals={decimals}>
      {({ countUpRef }) => (
        <Title variant={{ xs: 'h9', md: 'h8' }} sx={{ fontWeight: 400 }}>
          <span ref={countUpRef} />
        </Title>
      )}
    </CountUp>
    {/* <CountUp start={0} end={counter} duration={4} prefix={before} suffix={after} decimals={decimals}>
      </CountUp > */}

    <Typography variant='joymed' sx={{ color: colors.greenAccent[300] }}>
      {subtitle}
    </Typography>
  </Stack>
);

const Section2 = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const items = [
    { counter: 5000, duration: 2.75, subtitle: 'Prevailing Wage Rates' },
    {
      counter: 50000,
      duration: 2.75,
      before: '$',
      after: '+',
      subtitle: 'Payroll Processed',
    },
    {
      counter: 200,
      duration: 2.75,
      after: '+',
      subtitle: 'Total Payrolls',
      decimals: true,
    },
    {
      counter: 30,
      duration: 2.75,
      after: '+',
      subtitle: 'Total Users',
      decimals: true,
    },
  ];

  return (
    <Container sx={{ mt: { xs: -5, md: -10 } }}>
      <Box
        sx={(theme) => ({
          position: 'relative',
          py: 5,
          bgcolor: colors.primary[500],
          borderRadius: '50px',
          [theme.breakpoints.up('sm')]: {
            '&::before': {
              content: '""',
              position: 'absolute',
              inset: 0,
              border: '2px solid transparent',
              borderRadius: '50px',
              background: 'linear-gradient(180deg,grey,transparent) border-box',
              WebkitMask:
                'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
              WebkitMaskComposite: 'xor',
              maskComposite: 'exlude',
            },
          },
        })}>
        <Grid container spacing={3} justifyContent='space-between'>
          {items.map((item) => (
            <Grid item xs={6} md={3} key={item.subtitle}>
              <CustomCounter {...item} colors={colors} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Section2;
