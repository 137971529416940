import { Box, Typography, useTheme, TextField } from '@mui/material';
import Header from '../components/Header';
import { tokens } from '../theme';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useAuth } from '../context/AuthProvider';

const AddUserSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
  role: Yup.string().required('Required'),
  classification: Yup.string().required('Required'),
  payType: Yup.string().required('Required'),
  employeeID: Yup.number(),
  management: Yup.array().of(
    Yup.object().shape({
      project: Yup.string(),
    })
  ),
});

const details = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  role: '',
  classification: '',
  payType: '',
  employeeID: '',
  management: [],
};

const AddUserForm = ({ open, handleClose, listData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [statusMsg, setStatusMessage] = useState('');
  const formik = useFormik({
    initialValues: details,
    validationSchema: AddUserSchema,
    onSubmit: (values) => {
      createUser(values);
    },
  });
  const { api } = useAuth();

  // Mapping Create Stuff
  const createUser = async (details) => {
    console.log('Creating User', details);
    api
      .post('/api/v1/users', JSON.stringify(details))
      .then(() => {
        // const updatedPayroll = [...allTimecards, response.data.data];
        // setBookDetails(updatedPayroll);
        setStatusMessage('User & Personnel Created!');
        //refresh page
        window.location.reload(false);
      })
      .catch((error) => {
        setStatusMessage('An Error Occurred!');
      });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Header title={'Create A New User'} subtitle={'Enter Below'} />
      <Typography variant='h6' sx={{ mt: 2, color: colors.greenAccent[400] }}>
        {' '}
        Required
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <FormControl sx={{ width: '99%' }}>
          <TextField
            id='outlined-basic'
            label='Enter First Name'
            name='firstName'
            variant='outlined'
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.firstName}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          <TextField
            id='outlined-basic'
            label='Enter Last Name'
            name='lastName'
            variant='outlined'
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          <TextField
            id='outlined-basic'
            label='Enter Email'
            name='email'
            variant='outlined'
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          <TextField
            id='outlined-basic'
            label='Enter a Password'
            name='password'
            variant='outlined'
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          <TextField
            id='outlined-basic'
            label='Enter Role'
            name='role'
            variant='outlined'
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.role}
            error={formik.touched.role && Boolean(formik.errors.role)}
            helperText={formik.touched.role && formik.errors.role}
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          <TextField
            id='outlined-basic'
            label='Enter Classification'
            name='classification'
            variant='outlined'
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.classification}
            error={
              formik.touched.classification &&
              Boolean(formik.errors.classification)
            }
            helperText={
              formik.touched.classification && formik.errors.classification
            }
          />
        </FormControl>

        <FormControl sx={{ width: '99%' }}>
          <TextField
            id='outlined-basic'
            label='Enter Pay Type'
            name='payType'
            variant='outlined'
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.payType}
            error={formik.touched.payType && Boolean(formik.errors.payType)}
            helperText={formik.touched.payType && formik.errors.payType}
          />
        </FormControl>

        <Box textAlign='center'>
          <Typography
            variant='h6'
            sx={{ mt: 2, color: colors.greenAccent[400] }}>
            {statusMsg}
          </Typography>
          <Button
            variant='contained'
            sx={{ backgroundColor: colors.greenAccent[500], m: 1 }}
            onClick={formik.handleSubmit}
            endIcon={<SendIcon />}>
            Send
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddUserForm;
