import React from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import TimeClockButton from "./TimeClockButton";
import { tokens } from "../theme";

const TimeClockNumberPad = ({ onSubmit }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const buttonStyle = {
		backgroundColor: colors.grey[700],
		width: isMobile ? "32%" : null,
		height: isMobile ? "40px" : null,
	};
	return (
		<Box sx={{ border: "solid", p: 1, width: isMobile ? "100%" : "240px" }}>
			<Stack spacing={1}>
				<Stack direction="row" spacing={1}>
					<TimeClockButton number="7" onSubmit={onSubmit} sx={buttonStyle}>
						{" "}
					</TimeClockButton>
					<TimeClockButton number="8" onSubmit={onSubmit} sx={buttonStyle}>
						{" "}
					</TimeClockButton>
					<TimeClockButton number="9" onSubmit={onSubmit} sx={buttonStyle}>
						{" "}
					</TimeClockButton>
				</Stack>
				<Stack direction="row" spacing={1}>
					<TimeClockButton number="4" onSubmit={onSubmit} sx={buttonStyle}>
						{" "}
					</TimeClockButton>
					<TimeClockButton number="5" onSubmit={onSubmit} sx={buttonStyle}>
						{" "}
					</TimeClockButton>
					<TimeClockButton number="6" onSubmit={onSubmit} sx={buttonStyle}>
						{" "}
					</TimeClockButton>
				</Stack>
				<Stack direction="row" spacing={1}>
					<TimeClockButton number="1" onSubmit={onSubmit} sx={buttonStyle}>
						{" "}
					</TimeClockButton>
					<TimeClockButton number="2" onSubmit={onSubmit} sx={buttonStyle}>
						{" "}
					</TimeClockButton>
					<TimeClockButton number="3" onSubmit={onSubmit} sx={buttonStyle}>
						{" "}
					</TimeClockButton>
				</Stack>
				<Stack direction="row" spacing={1}>
					<TimeClockButton
						number="Del"
						onSubmit={onSubmit}
						sx={{
							backgroundColor: colors.grey[600],
							width: isMobile ? "32%" : null,
							height: isMobile ? "40px" : null,
						}}
					>
						{" "}
					</TimeClockButton>
					<TimeClockButton number="0" onSubmit={onSubmit} sx={buttonStyle}>
						{" "}
					</TimeClockButton>
					<TimeClockButton
						number="Clear"
						onSubmit={onSubmit}
						sx={{
							backgroundColor: colors.grey[600],
							width: isMobile ? "32%" : null,
							height: isMobile ? "40px" : null,
						}}
					>
						{" "}
					</TimeClockButton>
				</Stack>
				<Stack direction="row" spacing={1}>
					<TimeClockButton
						number="Start"
						sx={{ backgroundColor: colors.greenAccent[600], width: "50%" }}
						onSubmit={onSubmit}
					>
						{" "}
					</TimeClockButton>
					<TimeClockButton
						number="Stop"
						sx={{ backgroundColor: colors.redAccent[500], width: "50%" }}
						onSubmit={onSubmit}
					>
						{" "}
					</TimeClockButton>
				</Stack>
			</Stack>
		</Box>
	);
};

export default TimeClockNumberPad;
