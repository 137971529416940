import React, { useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/joy";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Sidebar from "../joy/components/Sidebar";
import Header from "../joy/components/Header";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import dayjs from "dayjs";
//local imports
import TimecardTable from "../joy/components/TimecardTable";
import TimecardList from "../joy/components/TimecardList";
//data
import { useAuth } from "../context/AuthProvider";

function TestDataGridPage({ location }) {
	const [timecards, setTimecards] = useState([]);
	const { api, user } = useAuth();
	useMemo(() => {
		if (api.authenticated) {
			api
				.get("/api/v1/timecardsv2/incomplete/?page=1&limit=1000")
				.then((response) => {
					let renderedData;
					renderedData = response.data.data[0].results.map((timecard, i) => ({
						id: timecard.employeeID + i,
						employeeID: timecard.employeeID,
						firstName: timecard.firstName,
						lastName: timecard.lastName,
						fullName: timecard.fullName,
						weekEnd: dayjs(timecard.weekEnd),
						regHours: timecard.regHours,
						overtimeHours: timecard.overtimeHours,
						satHours: timecard.satHours,
						sunHours: timecard.sunHours,
						status: timecard.status,
						createdAt: timecard.createdAt,
						sunday: timecard.sunday,
						monday: timecard.monday,
						tuesday: timecard.tuesday,
						wednesday: timecard.wednesday,
						thursday: timecard.thursday,
						friday: timecard.friday,
						saturday: timecard.saturday,
						mondayHours: timecard.monday[0].hours[0].regDayTotal,
						monOTHours: timecard.monday[0].hours[0].OTDayTotal,
						tuesdayHours: timecard.tuesday[0].hours[0].regDayTotal,
						tueOTHours: timecard.tuesday[0].hours[0].OTDayTotal,
						wednesdayHours: timecard.wednesday[0].hours[0].regDayTotal,
						wedOTHours: timecard.wednesday[0].hours[0].OTDayTotal,
						thursdayHours: timecard.thursday[0].hours[0].regDayTotal,
						thurOTHours: timecard.thursday[0].hours[0].OTDayTotal,
						fridayHours: timecard.friday[0].hours[0].regDayTotal,
						friOTHours: timecard.friday[0].hours[0].OTDayTotal,
						analytics: timecard.analytics,
						status: timecard.status,
						approved: timecard.approved,
						paid: timecard.paid,
						updatedAt: timecard.updatedAt,
					}));
					setTimecards(renderedData);
				})
				.catch((err) => {
					console.log(err);
					setTimecards([]);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api]);

	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />

			<Box sx={{ display: "flex", minHeight: "100dvh" }}>
				<Header />
				<Sidebar location={location} />

				<Box
					component="main"
					className="MainContent"
					sx={{
						px: { xs: 2, md: 6 },
						pt: {
							xs: "calc(12px + var(--Header-height))",
							sm: "calc(12px + var(--Header-height))",
							md: 3,
						},
						pb: { xs: 2, sm: 2, md: 3 },
						flex: 1,
						display: "flex",
						flexDirection: "column",
						minWidth: 0,
						height: "70dvh",
						gap: 1,
					}}
				>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Breadcrumbs
							size="sm"
							aria-label="breadcrumbs"
							separator={<ChevronRightRoundedIcon fontSize="sm" />}
							sx={{ pl: 0 }}
						>
							<Link
								underline="none"
								color="neutral"
								href="#some-link"
								aria-label="Home"
							>
								<HomeRoundedIcon />
							</Link>
							<Link
								underline="hover"
								color="neutral"
								href="#some-link"
								sx={{ fontSize: 12, fontWeight: 500 }}
							>
								Timecards
							</Link>
							<Typography
								color="primary"
								sx={{ fontWeight: 500, fontSize: 12 }}
							>
								Manage
							</Typography>
						</Breadcrumbs>
					</Box>
					<Box
						sx={{
							display: "flex",
							mb: 1,
							gap: 1,
							flexDirection: { xs: "column", sm: "row" },
							alignItems: { xs: "start", sm: "center" },
							flexWrap: "wrap",
							justifyContent: "space-between",
							height: "100dvh",
						}}
					>
						<Typography level="h2" component="h1">
							Manage
						</Typography>
						<Button
							color="primary"
							startDecorator={<DownloadRoundedIcon />}
							size="sm"
							disabled
						>
							Download PDF
						</Button>
						<TimecardTable timecards={timecards} />
						<TimecardList timecards={timecards} />
					</Box>
				</Box>
			</Box>
		</CssVarsProvider>
	);
}

export default TestDataGridPage;
