import { useState, useEffect } from 'react';
import TopBarAdmin from '../components/TopBarAdmin';
import { Box, Grid } from '@mui/material';
import Dashboard3 from './Dashboard3';
import useMediaQuery from '@mui/material/useMediaQuery';
import useQryFetch from '../hooks/fetchQryAPI';

function AdminDashboard() {
  const isAboveMediumScreens = useMediaQuery('(min-width: 1200px)');
  const isAboveiPhone = useMediaQuery('(min-width: 390px)');
  const isAboveiPhonePlus = useMediaQuery('(min-width: 415px)');
  const isAboveiPad = useMediaQuery('(min-width: 768px)');
  const { data, loading, error } = useQryFetch(
    '/api/v1/timecardsv2/?sort=lastName&approved=false'
  );
  const [notificationsNumber, setNotificationsNumber] = useState();

  useEffect(() => {
    if (data) {
      setNotificationsNumber(data.length);
    } else {
      setNotificationsNumber(0);
    }
  }, [data]);

  useEffect(() => {
    if (isAboveMediumScreens) {
      console.error('This is above 1200px');
    } else if (isAboveiPad) {
      console.error('This is above 768px');
    } else if (isAboveiPhonePlus) {
      console.error('This is above 415px');
    } else if (isAboveiPhone) {
      console.error('This is above 390px');
    }
  }, [isAboveMediumScreens, isAboveiPad, isAboveiPhone, isAboveiPhonePlus]);

  return (
    <Box width='95%'>
      <TopBarAdmin notificationsNumber={notificationsNumber} />
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        sx={{ minHeight: '100vh' }}>
        <Grid item xs={3}>
          <Dashboard3 />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminDashboard;
