import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';
import RFI from '../rfi';
import { Box } from '@mui/material';
import TopBarAdmin from '../components/TopBarAdmin';
import Alert from '@mui/material/Alert';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';

function AdminPageRFI() {
  const { api } = useAuth();
  const [project, setProject] = useState('All Projects');
  const [projectList, setProjectList] = useState([]);
  //load all user data
  //const { data, loading, error } = useFetch('/api/v1/projects');

  const [rfi, setrfi] = useState([]);
  const [selectedDataID, setselectedDataID] = useState([]);
  const [notificationsNumber, setnotificationsNumber] = useState(0);
  const [listData, setListData] = useState([]);
  const [errState, setErrState] = useState(false);
  const [errMsg, seterrMsg] = useState('');

  function checkFailed(then) {
    return function (responses) {
      const someFailed = responses.some((response) => response.error);

      if (someFailed) {
        throw responses;
      }

      return then(responses);
    };
  }

  useEffect(() => {
    if (api.authenticated) {
      api
        .get(`/api/v1/rfi/?page=1&limit=20&project=${project}`)
        .then(({ data }) => {
          setErrState(false);
          seterrMsg('');
          console.log(data);
          setrfi(data.data[0].results);
        })
        .catch((err) => {
          console.log('FAIL', err);
          setErrState(true);
          seterrMsg('Error Loading Data');
        });
    }
  }, [api, project]);

  useEffect(() => {
    if (api.authenticated) {
      api
        .get(`/api/v1/projects/list`)
        .then(({ data }) => {
          setProjectList(data.data);
          //setProject(data.data[0]);
        })
        .catch((err) => {
          console.log('FAIL', err);
          setErrState(true);
          seterrMsg('Error Loading Data');
        });
    }
  }, [api]);

  useEffect(() => {
    if (api.authenticated) {
      Promise.all([
        api.get('/api/v1/projects/list'),
        api.get('/api/v1/personnel/?sort=fullName'),
      ])
        .then(
          checkFailed(([projects, personnel]) => {
            setListData((prev) => ({
              ...prev,
              projects,
              personnel,
            }));
          })
        )
        .catch((err) => {
          console.log('FAIL', err);
          setErrState(true);
          seterrMsg('Error Loading Data');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api?.authenticated]);

  useEffect(() => {
    if (api.authenticated) {
      async function GetResults(data) {
        try {
          const response = await api.get(
            `/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
          );
          console.log(response);
          setnotificationsNumber(response.data.data[0].results.length);
        } catch (err) {
          console.log('Failed', { err });
          setErrState(true);
          seterrMsg('Error Loading Data');
        }
      }
      GetResults();
    }
  }, [api]);

  // //Delete Many Projects By Id
  // async function deleteManyProjects(...prevProjects) {
  // 	await Promise.all(
  // 		selectedDataID.map((id) =>
  // 			api.delete(`/api/v1/changeorder/${id}`, {
  // 				headers: {
  // 					"Content-Type": "application/json",
  // 					Authorization: `Bearer ${token}`,
  // 				},
  // 				baseURL: baseURL,
  // 			})
  // 		)
  // 	)
  // 		.then(handleRemoveMultipleItems())
  // 		.catch(function (err) {});
  // }

  // const deleteById = (id) => {
  // 	//filter keeps all id thats not selected
  // 	const filteredData = rfi.filter((item) => item._id !== id);
  // 	// Do something with the filtered data
  // 	setrfi(filteredData);
  // };

  // const handleRemoveMultipleItems = () => {
  // 	selectedDataID.forEach((id) => deleteById(id));
  // };

  return (
    <Box sx={{ height: '100dvh' }}>
      {errState ? (
        <Alert
          icon={<RunningWithErrorsIcon fontSize='inherit' />}
          severity='error'>
          {errMsg}
        </Alert>
      ) : null}
      <TopBarAdmin notificationsNumber={notificationsNumber} />
      <RFI
        selectedDataID={selectedDataID}
        setselectedDataID={setselectedDataID}
        data={rfi}
        setrfi={setrfi}
        projectList={projectList}
        project={project}
        setProject={setProject}
        listData={listData}
        //deleteManyProjects={deleteManyProjects}
      />
    </Box>
  );
}

export default AdminPageRFI;
