import { Box, Stack, Tab, Tabs } from '@mui/material';
import TopBarAdmin from '../components/TopBarAdmin';
import Header from '../components/Header';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';
import CompanySubscription from '../components/Settings/CompanySubscription';
import CompanySettings from '../components/Settings/CompanySettings';
import UserProfile from '../components/Settings/UserProfile';
import SettingsInfo from '../components/Settings/SettingsInfo';
import UserChangePassword from '../components/Settings/UserChangePassword';
import Alert from '@mui/material/Alert';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import { Grid, Card, CardContent, Typography, Button } from '@mui/material';

const SettingsTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ maxWidth: 'sm', marginX: 'auto' }}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
};

const SettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [notificationsNumber, setnotificationsNumber] = useState(0);
  const handleChange = (event, newValue) => setSelectedTab(newValue);
  const { api } = useAuth();
  const [errState, seterrState] = useState(false);

  useEffect(() => {
    async function GetResults(data) {
      try {
        const response = await api.get(
          `/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
        );
        setnotificationsNumber(response?.data.data[0].results.length);
        seterrState(false);
      } catch (err) {
        console.log('Failed', { err });
        seterrState(true);
      }
    }
    GetResults();
  }, [api]);

  const plans = [
    {
      title: 'Basic Plan',
      price: '$10/month',
      features: ['Feature 1', 'Feature 2', 'Feature 3'],
      active: true,
    },
    {
      title: 'Pro Plan',
      price: '$20/month',
      features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
      active: false,
    },
    {
      title: 'Premium Plan',
      price: '$30/month',
      features: [
        'Feature 1',
        'Feature 2',
        'Feature 3',
        'Feature 4',
        'Feature 5',
      ],
      active: false,
    },
  ];

  const Plans = () => {
    return (
      <Box sx={{ p: 4 }}>
        <Grid container spacing={4} justifyContent='center'>
          {plans.map((plan, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  borderRadius: '12px',
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                }}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography
                    variant='h5'
                    component='div'
                    sx={{ fontWeight: 'bold', mb: 2 }}>
                    {plan.title}
                  </Typography>
                  <Typography
                    variant='h6'
                    component='div'
                    sx={{ color: 'success.main', mb: 3 }}>
                    {plan.price}
                  </Typography>
                  <Box component='ul' sx={{ listStyle: 'none', p: 0, mb: 3 }}>
                    {plan.features.map((feature, i) => (
                      <Typography
                        component='li'
                        key={i}
                        sx={{ mb: 1, fontSize: '1rem' }}>
                        {feature}
                      </Typography>
                    ))}
                  </Box>
                  <Button
                    variant='contained'
                    color='success'
                    disabled={plan.active}>
                    {plan.active ? 'Selected' : 'Choose Plan'}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <Box sx={{ height: '140dvh' }} m='10px 20px 20px 20px'>
      {errState ? (
        <Alert
          icon={<RunningWithErrorsIcon fontSize='inherit' />}
          severity='error'>
          {'Data Not Loaded!  Please refresh'}
        </Alert>
      ) : null}
      <TopBarAdmin notificationsNumber={notificationsNumber} />
      <Header title='Settings' subtitle='Update Your Settings' />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab label='Company Settings' />
          <Tab label='User Profile' />
          <Tab label='Info' />
        </Tabs>
      </Box>
      <SettingsTabPanel value={selectedTab} index={0}>
        <Stack spacing={4}>
          <CompanySettings />
          <CompanySubscription />
        </Stack>
      </SettingsTabPanel>
      <SettingsTabPanel value={selectedTab} index={1}>
        <Stack spacing={4}>
          <UserProfile />
          <UserChangePassword />
        </Stack>
      </SettingsTabPanel>

      <SettingsTabPanel value={selectedTab} index={2}>
        <Stack spacing={4}>
          <SettingsInfo />
          <Plans />
        </Stack>
      </SettingsTabPanel>
    </Box>
  );
};

export default SettingsPage;
