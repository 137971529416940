import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import CustomSelect from '../styledcomponents/CustomSelect';
import dayjs from 'dayjs';

const TopBarDashboardSettings = ({
  monthlySearchFilters,
  setMonthlySearchFilters,
}) => {
  const monthRange = Array.from({ length: 12 }, (_, i) => i + 1);
  const yearRange = Array.from(
    {
      length: monthlySearchFilters.endYear - monthlySearchFilters.startYear + 1,
    },
    (_, i) => i + monthlySearchFilters.startYear
  );
  const handleChange = (field) => (value) => {
    setMonthlySearchFilters((prevState) => ({
      ...prevState,
      [field]: value, // Update the specific field dynamically
    }));
  };
  console.log('monthRange', monthRange);
  console.log('yearRange', yearRange);
  return (
    <Box display='flex' justifyContent='center' spacing={2}>
      {/* SEARCH BAR */}
      <CustomSelect
        value={monthlySearchFilters.startMonth}
        optionsArray={monthRange}
        handleChange={handleChange('startMonth')}
      />
      <CustomSelect
        value={monthlySearchFilters.startYear}
        optionsArray={yearRange}
        handleChange={handleChange('startYear')}
      />
      <Box sx={{ p: 1 }}>
        <Typography variant={'joysmall'}> to </Typography>
      </Box>
      <CustomSelect
        value={monthlySearchFilters.endMonth}
        optionsArray={monthRange}
        handleChange={handleChange('endMonth')}
      />
      <CustomSelect
        value={monthlySearchFilters.endYear}
        optionsArray={yearRange}
        handleChange={handleChange('endYear')}
      />
    </Box>
  );
};

export default TopBarDashboardSettings;
