import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useAuth } from '../context/AuthProvider';
import { useState, useEffect } from 'react';
import { Box, Stack, Datagrid } from '@mui/material';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import TwoToneInlineText from './TwoToneInlineText';
import TwoToneInlineGrey from './TwoToneInlineGrey';
import { Typography } from '@mui/material/';
import Divider from '@mui/material/Divider';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '500px',
  width: '100%',
  height: '500px',
  bgcolor: 'common.black',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  zIndex: 1000000,
  textAlign: 'center',
};

function transformData(input) {
  // Initialize three objects for the three projects
  const project1 = { id: 1, project: input.project1 };
  const project2 = { id: 2, project: input.project2 };
  const project3 = { id: 3, project: input.project3 };

  // Helper function to calculate project hours
  const calculateProjectHours = (projectCalcKeys, input) => {
    const answer = projectCalcKeys.reduce(
      (sum, key) => sum + (input[key] || 0),
      0
    );
    return answer;
  };

  // Calculate projectHours for each project
  const project1Keys = [
    'sunp1calc',
    'monp1calc',
    'tuep1calc',
    'wedp1calc',
    'thurp1calc',
    'frip1calc',
    'satp1calc',
  ];
  const project2Keys = [
    'sunp2calc',
    'monp2calc',
    'tuep2calc',
    'wedp2calc',
    'thurp2calc',
    'frip2calc',
    'satp2calc',
  ];
  const project3Keys = [
    'sunp3calc',
    'monp3calc',
    'tuep3calc',
    'wedp3calc',
    'thurp3calc',
    'frip3calc',
    'satp3calc',
  ];

  project1.projectHours = calculateProjectHours(
    project1Keys,
    input.calculations
  );
  project2.projectHours = calculateProjectHours(
    project2Keys,
    input.calculations
  );
  project3.projectHours = calculateProjectHours(
    project3Keys,
    input.calculations
  );

  // Loop through each key in the input object
  for (const key in input.calculations) {
    if (
      key === 'overtimeHours' ||
      key === 'satHours' ||
      key === 'sunHours' ||
      key === 'regHours' ||
      key === 'totalHours'
    ) {
      // Skip these keys
      continue;
    } else if (key.endsWith('p1calc') || key.endsWith('Hours')) {
      project1[key.replace('p1calc', '')] = input.calculations[key];
    } else if (key.endsWith('p2calc')) {
      project2[key.replace('p2calc', '')] = input.calculations[key];
    } else if (key.endsWith('p3calc')) {
      project3[key.replace('p3calc', '')] = input.calculations[key];
    } else {
      // Add non-project-specific keys to all projects
      project1[key] = input[key];
      project2[key] = input[key];
      project3[key] = input[key];
    }
  }

  // Return the array of project objects
  return [project1, project2, project3];
}

const ViewTimeCardModal = ({ open, handleClose, details }) => {
  const { api } = useAuth();
  const [errState, setErrState] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [sentState, setSentState] = useState(false);
  const [days, setDays] = useState({});

  function renderDynamicHeader(dayKey, days) {
    return function (params) {
      return (
        <Box sx={{ p: 0, m: 0, display: 'block' }}>
          <Typography variant='body2'>
            {dayKey.charAt(0).toUpperCase() + dayKey.slice(1)}
          </Typography>
          <Typography variant='body2' color='grey'>
            {days[dayKey]}
          </Typography>
        </Box>
      );
    };
  }

  useEffect(() => {
    if (details) {
      setDays({
        sun: dayjs(details.weekEnding).day(0).format('MM/DD'),
        mon: dayjs(details.weekEnding).day(1).format('MM/DD'),
        tue: dayjs(details.weekEnding).day(2).format('MM/DD'),
        wed: dayjs(details.weekEnding).day(3).format('MM/DD'),
        thur: dayjs(details.weekEnding).day(4).format('MM/DD'),
        fri: dayjs(details.weekEnding).day(5).format('MM/DD'),
        sat: dayjs(details.weekEnding).day(6).format('MM/DD'),
      });
    }
  }, [details]);

  const [timecard, setTimecard] = useState({});
  useEffect(() => {
    setTimecard(transformData(details));
  }, [details]);

  function isOdd(number) {
    return number % 2 !== 0;
  }

  function renderCell(dayKey) {
    return function ({ row }) {
      const value = row[dayKey];
      const isPositive = value > 0;

      return (
        <Box sx={{ justifyContent: 'center' }}>
          <Typography
            variant='body2'
            color={isPositive ? 'primary' : 'grey'}
            sx={{
              fontWeight: isPositive ? 'bold' : 'normal',
              textAlign: 'center',
            }}>
            {value}
          </Typography>
        </Box>
      );
    };
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'>
      <Box sx={style}>
        <Typography variant={'h5'} color='white'>
          {' '}
          View Time Card{' '}
        </Typography>
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Box sx={{ display: 'flex' }}>
            <Typography variant={'body2'} sx={{ color: 'grey', px: 0.5 }}>
              Name:{' '}
            </Typography>
            <Typography variant={'body2'}> {details.fullName}</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography variant={'body2'} sx={{ color: 'grey', px: 0.5 }}>
              Week Ending:
            </Typography>
            <Typography variant={'body2'}>
              {dayjs(details.weekEnding).format('MM/DD/YYYY')}
            </Typography>
          </Box>
          <TwoToneInlineGrey text1={'Week Number'} text2={details.weekNumber} />
        </Box>
        <Box
          sx={{
            height: 250,
            width: '100%',
            backgroundColor: '#1a1b1c',
            borderRadius: '5px',
            justifyContent: 'center',
          }}>
          <DataGrid
            rows={timecard}
            columns={[
              { field: 'id', headerName: 'ID', renderInput: false, hide: true },
              {
                field: 'project',
                headerName: 'Project',
                width: 70,
                renderCell: ({ row }) => {
                  return (
                    <Typography
                      variant='body2'
                      color={isOdd(row.id) === true ? 'primary' : 'secondary'}>
                      {row.project}
                    </Typography>
                  );
                },
              },
              {
                field: 'sun',
                headerName: `Sun${days.sun}`,
                renderHeader: renderDynamicHeader('sun', days),
                width: 50,
                renderCell: renderCell('sun'),
              },
              {
                field: 'mon',
                headerName: 'M',
                renderHeader: renderDynamicHeader('mon', days),
                width: 50,
                renderCell: renderCell('mon'),
              },
              {
                field: 'tue',
                headerName: 'T',
                renderHeader: renderDynamicHeader('tue', days),
                width: 30,
                renderCell: renderCell('tue'),
              },
              {
                field: 'wed',
                headerName: 'W',
                renderHeader: renderDynamicHeader('wed', days),
                width: 30,
                renderCell: renderCell('wed'),
              },
              {
                field: 'thur',
                headerName: 'R',
                renderHeader: renderDynamicHeader('thur', days),
                width: 30,
                renderCell: renderCell('thur'),
              },
              {
                field: 'fri',
                headerName: 'F',
                renderHeader: renderDynamicHeader('fri', days),
                width: 30,
                renderCell: renderCell('fri'),
              },
              {
                field: 'sat',
                headerName: 'Sat',
                renderHeader: renderDynamicHeader('sat', days),
                width: 30,
                renderCell: renderCell('sat'),
              },
              { field: 'projectHours', headerName: 'Subtotal', width: 30 },
            ]}
            disableRowSelectionOnClick
            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter={true}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'Mui-even'
                : 'Mui-odd'
            }
          />
          <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
            <Box textAlign='left'>
              <TwoToneInlineText
                text1={'Project 1'}
                text2={details?.project1}
              />
              <TwoToneInlineText
                text1={'Project 1 Hours'}
                text2={details?.calculations?.p1Hours}
              />
            </Box>
            <Box textAlign='left'>
              <TwoToneInlineText text1={'Project 2'} text2={details.project2} />
              <TwoToneInlineText
                text1={'Project 2 Hours'}
                text2={details.calculations.p2Hours}
              />
            </Box>
            <Box textAlign='left'>
              <TwoToneInlineText text1={'Project 3'} text2={details.project3} />
              <TwoToneInlineText
                text1={'Project 3 Hours'}
                text2={details.calculations.p3Hours}
              />
            </Box>
          </Box>
          <Divider sx={{ py: 1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TwoToneInlineGrey
              text1={'Total Hours'}
              text2={details.calculations.totalHours}
            />
            <TwoToneInlineGrey
              text1={'Approved'}
              text2={details.approved ? 'True' : 'False'}
            />
            <TwoToneInlineGrey
              text1={'Created At'}
              text2={dayjs(details.createdAt).format('MM/DD/YYYY')}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default ViewTimeCardModal;
