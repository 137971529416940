import { Box, Typography, Stack, useTheme } from "@mui/material";
import Modal from "@mui/material/Modal";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useState, useEffect, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import TwoToneInlineText from "./TwoToneInlineText";
import EditTimeCardWeekLabels from "./EditTimeCardWeekLabels";
// import { tokens } from "../theme";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { useAuth } from "../context/AuthProvider";
import PivotBox from "../styled/PivotBox";
import useMediaQuery from "@mui/material/useMediaQuery";
import useMeasure from "react-use-measure";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SettingsIcon from "@mui/icons-material/Settings";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Sheet from "@mui/joy/Sheet";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import { Input } from "@mui/joy";
import {
	unstable_createCssVarsProvider as createCssVarsProvider,
	unstable_createCssVarsTheme as createCssVarsTheme,
} from "@mui/system";
import {
	CssVarsProvider,
	extendTheme as extendJoyTheme,
} from "@mui/joy/styles";
import {
	createTheme as createMuiTheme,
	ThemeProvider,
} from "@mui/material/styles";
import { adaptV4Theme } from "@mui/material/styles";
// Extend the Joy UI theme
const joyTheme = extendJoyTheme({
	colorSchemes: {
		light: {
			palette: {
				primary: {
					500: "#007FFF", // Example primary color
				},
			},
		},
	},
});

// Convert Joy theme to MUI-compatible theme
const muiTheme = createMuiTheme({
	palette: {
		primary: {
			main: joyTheme.colorSchemes.light.palette.primary[500], // From Joy UI theme
		},
		secondary: {
			main: joyTheme.colorSchemes.light.palette.neutral?.[500] || "#6c757d", // From Joy UI or fallback
		},
		error: {
			main: joyTheme.colorSchemes.light.palette.danger?.[500] || "#d32f2f", // Fallback for error
		},
		background: {
			default:
				joyTheme.colorSchemes.light.palette.background?.body || "#ffffff",
			paper:
				joyTheme.colorSchemes.light.palette.background?.surface || "#f9f9f9",
		},
		text: {
			primary: joyTheme.colorSchemes.light.palette.text?.primary || "#000000",
			secondary:
				joyTheme.colorSchemes.light.palette.text?.secondary || "#6c757d",
		},
	},
	shape: {
		borderRadius: 8, // Ensure borderRadius is available
	},
});

const calculateElapsedTime = (details) => {
	let hours;
	if (details.startTime) {
		const start = dayjs(details.startTime);
		const end = dayjs(details.endTime);
		const diff = end.diff(start); //milliseconds
		hours = diff / 3600000;
	}

	return hours;
};

const testDetails = {
	sunp1start: dayjs().day(0).hour(7),
	sunp1end: dayjs().day(0).hour(15),
	monp1start: dayjs().day(1).hour(8),
	monp1end: dayjs().day(1).hour(14),
	tuep1start: dayjs().day(2).hour(9),
	tuep1end: dayjs().day(2).hour(13),
	wedp1start: dayjs().day(3).hour(10),
	wedp1end: dayjs().day(3).hour(12),
	thurp1start: dayjs().day(4).hour(7),
	thurp1end: dayjs().day(4).hour(9),
	frip1start: dayjs().day(5).hour(8),
	frip1end: dayjs().day(5).hour(12),
	satp1start: dayjs().day(6).hour(7),
	satp1end: dayjs().day(6).hour(15),
	sunp2start: null,
	sunp2end: null,
	monp2start: null,
	monp2end: null,
	tuep2start: null,
	tuep2end: null,
	wedp2start: null,
	wedp2end: null,
	thurp2start: null,
	thurp2end: null,
	frip2start: null,
	frip2end: null,
	satp2start: null,
	satp2end: null,
	sunp3start: null,
	sunp3end: null,
	monp3start: null,
	monp3end: null,
	tuep3start: null,
	tuep3end: null,
	wedp3start: null,
	wedp3end: null,
	thurp3start: null,
	thurp3end: null,
	frip3start: null,
	frip3end: null,
	satp3start: null,
	satp3end: null,
	firstName: "Barry",
	lastName: "Bonds",
	breaksun: 0,
	breakmon: 0.5,
	breaktue: 0.5,
	breakwed: 0.5,
	breakthur: 0.5,
	breakfri: 0.5,
	breaksat: 0,
	project1: null,
	project2: null,
	project3: null,
	weekEnding: dayjs().day(6).hour(0),
	weekEndingString: dayjs().day(6).format("MM/DD/YYYY"),
};

const TestEditTimecardModal = ({ open, handleClose, details }) => {
	const { CssVarsProvider } = createCssVarsProvider({
		theme: createCssVarsTheme({
			colorSchemes: {
				light: {
					typography: {
						htmlFontSize: "16px",
						h1: {
							fontSize: "1rem",
							fontWeight: 500,
						},
					},
				},
			},
			shouldSkipGeneratingVar: (keys) =>
				keys[0] === "typography" && keys[1] === "h1",
		}),
		defaultColorScheme: "light",
	});
	const [formatted, setFormatted] = useState({});
	const { api } = useAuth();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const [ref, { height }] = useMeasure();
	const [age, setAge] = useState("");
	const [project1, setProject1] = useState(null);
	const [project1hour, setproject1hour] = useState(0);
	const [project2, setProject2] = useState(null);
	const [project2hour, setproject2hour] = useState(0);
	const [project3, setProject3] = useState(null);
	const [project3hour, setproject3hour] = useState(0);
	const [p1disabled, setP1disabled] = useState(true);
	const [p2disabled, setP2disabled] = useState(true);
	const [p3disabled, setP3disabled] = useState(true);
	const [checked, setChecked] = useState(true);
	const [weekEnding, setWeekEnding] = useState("12/14/24");
	const [detailsRendered, setDetailsRendered] = useState(testDetails);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			height={"100px"}
		>
			<Sheet
				variant="outlined"
				sx={{
					maxWidth: 900,
					borderRadius: "md",
					p: 3,
					boxShadow: "lg",
					ml: "300px",
				}}
			>
				<ModalClose variant="plain" sx={{ m: 1 }} onClick={handleClose} />

				<Typography
					component="h2"
					id="modal-title"
					level="h4"
					textColor="inherit"
					sx={{ fontWeight: "lg", mb: 1 }}
				>
					This is the modal title
				</Typography>
				<Typography id="modal-desc" textColor="text.tertiary">
					Make sure to use <code>aria-labelledby</code> on the modal dialog with
					an optional <code>aria-describedby</code> attribute.
				</Typography>
				<CssVarsProvider theme={joyTheme}>
					<ThemeProvider theme={muiTheme}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DemoContainer
								components={["TimePicker", "TimePicker", "TimePicker"]}
							>
								<DemoItem>
									{/* End of unit box 1 */}
									<TimePicker
										label="Select Time"
										//value={value}
										//onChange={(newValue) => setValue(newValue)}
										renderInput={(params) => (
											<Input
												{...params.InputProps}
												{...params}
												error={params.error} // Map error prop to Joy Input
											/>
										)}
									/>
								</DemoItem>
							</DemoContainer>
						</LocalizationProvider>
					</ThemeProvider>
				</CssVarsProvider>
			</Sheet>
		</Modal>
	);
};

export default TestEditTimecardModal;
