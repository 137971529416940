import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Stack,
  Typography,
  useTheme,
  TextField,
  Grid,
  useMediaQuery,
} from '@mui/material';
import TimeClockNumberPad from './TimeClockNumberPad';
import TimeClockDisplay from './TimeClockDisplay';
import TimeClockElaspedDisplay2 from './TimeClockElaspedDisplay2';
import TimeClockUnixElapsed from './TimeClockUnixElapsed';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../theme';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
//dropdown
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
//data
import { useAuth } from '../context/AuthProvider';
//error
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TimeClockOptionsPopover from './TimeClockOptionsPopover';
import TimeCardOptionsPopover from './TimeCardOptionsPopover';
import EditPayrollPopOver from './EditPayrollPopOver';
import EditTimeClockModal from './EditTimeClockModal';
import EditTimeCardModal from './EditTimeCardModal';
import TimecardCard from './TimecardCard';
import TimeClockLabels from './TimeClockLabels';
import PivotBox from '../styled/PivotBox';

const TimeClock = ({
  projectList,
  personnelList,
  setTimecardCreated,
  setTimecardCreatedMsg,
  errMsg,
  seterrMsg,
  errState,
  seterrState,
  dot,
}) => {
  var weekYear = require('dayjs/plugin/weekYear');
  dayjs.extend(weekYear);
  var weekOfYear = require('dayjs/plugin/weekOfYear');
  dayjs.extend(weekOfYear);
  var utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  var weekday = require('dayjs/plugin/weekday');
  dayjs.extend(weekday);
  // set Start Time
  const theme = useTheme();
  const { api, user } = useAuth();
  const colors = tokens(theme.palette.mode);
  const [today] = useState(dayjs().format('MM/DD/YYYY'));
  const [isWorkingHours, setisWorkingHours] = useState(false);
  const [display, setDisplay] = useState('');
  const [project1, setProject1] = useState('');
  const [project1Error, setProject1Error] = useState(false);
  const [status, setStatus] = useState('Active');
  const [projectStartTime, setprojectStartTime] = useState(
    dayjs().hour(7).minute(0)
  );
  const [projectEndTime, setprojectEndTime] = useState(
    dayjs().hour(15).minute(30)
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [person, setPerson] = useState('');
  const [weekEnding] = useState(dayjs().day(6).format('MM/DD/YYYY'));
  const [weekNumber] = useState(dayjs().week());
  const [timeClock, setTimeClock] = useState([]);
  const [timecards, setTimecards] = useState([]);
  const [popoverDetails, setPopoverDetails] = useState({});
  const [timecardPopoverDetails, settimecardPopoverDetails] = useState({});
  const [timeclockPopoverDetails, settimeclockPopoverDetails] = useState({});
  var LocalizedFormat = require('dayjs/plugin/localizedFormat');
  dayjs.extend(LocalizedFormat);

  //more options popout
  const [anchorElPopOut, setAnchorElPopOut] = useState(null);
  const openPopOut = Boolean(anchorElPopOut);
  const idPopOut = openPopOut ? 'Pop Out' : undefined;

  //timeclock
  const [anchorElEditTimeclockPopOut, setanchorElEditTimeclockPopOut] =
    useState(null);
  const openEditTimeclockPopOut = Boolean(anchorElEditTimeclockPopOut);
  const idEditTimeclockPopOut = openEditTimeclockPopOut ? 'Pop Out' : undefined;

  const handlePopOutClickEditTimeclock = (event) => {
    setanchorElEditTimeclockPopOut(event.currentTarget);
    //setShowTimeclockEdit(true);
    //setpopOverEditDetails(details.row.row);
  };

  //timecard popout
  const [anchorElTimecardPopOut, setAnchorElTimecardPopOut] = useState(null);
  const openTimecardPopOut = Boolean(anchorElTimecardPopOut);
  const idTimecardPopOut = openTimecardPopOut ? 'Timecard Pop Out' : undefined;

  //timecard - Payroll Edit PopOver
  const [anchorElEditPayrollPopOut, setanchorElEditPayrollPopOut] =
    useState(null);
  const openEditPayrollPopOut = Boolean(anchorElEditPayrollPopOut);
  const idEditPayrollPopOut = openEditPayrollPopOut ? 'Pop Out' : undefined;

  const handlePopOutClickEditPayroll = (event) => {
    setanchorElEditPayrollPopOut(event.currentTarget);
    //	setShowPayrollEdit(true);
    //setpopOverEditDetails(details.row.row);
  };

  //timeclock modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //timecard modal v2
  const [openTC, setOpenTC] = useState(false);
  const handleOpenTC = () => setOpenTC(true);
  const handleCloseTC = () => setOpenTC(false);

  const timeclockColumns = [
    {
      field: 'employeeID',
      headerName: 'ID',
      minWidth: 25,
      width: 25,
    },
    {
      field: 'startTime',
      headerName: 'Hours',
      width: 75,
      renderCell: ({ row: { startTime }, row: { status } }, i) => {
        const date1 = dayjs();
        const date2 = dayjs(startTime);
        const diff = date1.diff(date2, 'second'); //  set in seconds

        return (
          <Box
            key={i}
            m='5px'
            px='5px'
            display='flex'
            justifyContent='center'
            backgroundColor={
              diff < 86400 ? colors.greenAccent[600] : colors.redAccent[700]
            }
            borderRadius='4px'>
            {diff < 86400 ? (
              <TimeClockElaspedDisplay2 start={startTime} />
            ) : (
              <Typography>24 hrs+</Typography>
            )}
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 85,
      renderCell: ({ row: { status, startTime } }) => {
        const date1 = dayjs();
        const date2 = dayjs(startTime);
        const diff = date1.diff(date2, 'second'); //  set in seconds

        return (
          <Box sx={{ m: 0 }}>
            {diff < 86400 ? (
              <Chip label='Active' color='primary' size='small' />
            ) : (
              <Chip label='Incomplete' color='warning' size='small' />
            )}
          </Box>
        );
      },
    },
    {
      field: 'more',
      headerName: '',
      width: 25,
      minWidth: 25,
      renderCell: ({ row: { startTime }, row }, i) => {
        return (
          <Box
            key={i}
            m='-7.5px'
            display='flex'
            justifyContent='center'
            onClick={(event) => {
              const version = 'timeclock';
              handleMoreClick(event, row, version);
            }}>
            {<MoreVertIcon start={startTime} />}
          </Box>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 135,
      renderCell: ({ row: { createdAt } }, i) => {
        return (
          <Typography variant='body2' sx={{ color: 'grey' }}>
            {' '}
            {dayjs(createdAt).format('llll')};
          </Typography>
        );
      },
    },
    {
      field: 'elapsed',
      headerName: 'Elapsed',
      width: 135,
      renderCell: ({ row: { startTime } }, i) => {
        return (
          <Typography variant='body2' sx={{ color: 'grey' }}>
            {<TimeClockUnixElapsed start={dayjs(startTime).unix()} />}
          </Typography>
        );
      },
    },
  ];

  const timecardColumns = [
    {
      field: 'employeeID',
      headerName: 'ID',
      minWidth: 25,
      width: 25,
    },
    {
      field: 'weekEnd',
      headerName: 'Week Ending',
      minWidth: 100,
      width: 100,
    },
    {
      field: 'fullName',
      headerName: 'Name',
      minWidth: 100,
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 80,
      width: 80,
      renderCell: ({ row: { status } }) => {
        return (
          <Box sx={{ m: 0 }}>
            {status === 'Complete' ? (
              <Chip label='Complete' color='primary' size='small' />
            ) : (
              <Chip label='Incomplete' color='warning' size='small' />
            )}
          </Box>
        );
      },
    },
    {
      field: 'regHours',
      headerName: 'Reg',
      minWidth: 30,
      width: 30,
    },
    {
      field: 'overtimeHours',
      headerName: 'OT',
      minWidth: 30,
      width: 30,
    },
    {
      field: 'satHours',
      headerName: 'Sat',
      minWidth: 30,
      width: 30,
    },
    {
      field: 'sunHours',
      headerName: 'Sun',
      minWidth: 30,
      width: 30,
    },
    {
      field: 'more',
      headerName: '',
      width: 25,
      minWidth: 25,
      renderCell: ({ row: { startTime }, row }, i) => {
        return (
          <Box
            key={i}
            m='-7.5px'
            display='flex'
            justifyContent='center'
            onClick={(event) => {
              const version = 'timecard';
              handleMoreTimecardClick(event, row, version);
            }}>
            {<MoreVertIcon start={startTime} />}
          </Box>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 135,
      renderCell: ({ row: { createdAt } }, i) => {
        return (
          <Typography variant='body2' sx={{ color: 'grey' }}>
            {' '}
            {dayjs(createdAt).format('llll')};
          </Typography>
        );
      },
    },
  ];

  //more click
  const handleMoreClick = (event, row, version) => {
    console.log('Clicked more');
    console.log(version);
    setAnchorElPopOut(event.currentTarget);
    setPopoverDetails(row);
    settimecardPopoverDetails(row);
  };

  //more click
  const handleMoreTimecardClick = (event, row, version) => {
    console.log('Clicked');
    console.log(version);
    console.log(row);
    setAnchorElTimecardPopOut(event.currentTarget);
    settimecardPopoverDetails(row);
  };

  //Create dropdown function for time pickers
  const CreateDropdownComponent = ({ error, data, state, callBack }) => {
    return (
      <FormControl
        variant='standard'
        error={error}
        sx={{
          minWidth: '90%',
          width: '100px',
          '& .MuiInputLabel-formControl': {
            color: 'grey',
          },
          '& .MuiInputLabel-root': {
            backgroundColor: 'red',
          },
        }}>
        <Select
          labelId='demo-simple-select-standard-label'
          id='demo-simple-select-standard'
          size='small'
          value={state}
          onChange={(prev) => {
            const newValue = prev.target.value;
            callBack(newValue);
          }}
          label='Start'
          sx={{ m: 0, p: 0, height: '1rem' }}>
          {data &&
            data.map((value, index) => {
              return (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  };

  // //update status
  useEffect(() => {
    checkStatus(projectStartTime, projectEndTime);
  }, [projectEndTime, projectStartTime]);

  //takes in dayjs objects
  function checkStatus(start, end) {
    console.log(dayjs().unix());
    console.log(dayjs(start).unix());
    if (dayjs().unix() - dayjs(start).unix() > 86400) {
      //one day is 86400 seconds
      setStatus('Closed');
      setisWorkingHours(false);
    } else if (dayjs().isBefore(start)) {
      setisWorkingHours(false);
      setStatus('Closed');
    } else if (dayjs().isAfter(start) && dayjs().isBefore(end)) {
      setStatus('Active');
      setisWorkingHours(true);
    }
  }

  function changeStatus(obj) {
    if (obj.type === 'Start') {
      checkStatus(obj.value, projectEndTime);
    } else if (obj.type === 'End') {
      checkStatus(projectStartTime, obj.value);
    }
  }

  useMemo(() => {
    if (api.authenticated) {
      api
        .get('/api/v1/timeclock/unresolved')
        .then((response) => {
          let renderedData;
          renderedData = response.data.data[0].results.map((timeclock, i) => ({
            id: timeclock.employeeID + i,
            _id: timeclock._id,
            personnel: timeclock.personnel,
            employeeID: timeclock.employeeID,
            startTime: timeclock.startTime,
            endTime: timeclock.endTime,
            referenceDate: timeclock.referenceDate,
            creditedTime: timeclock.creditedTime,
            deviceName: timeclock.deviceName,
            fullName: timeclock.fullName,
            project: timeclock.project,
            dayOfTheWeek: timeclock.dayOfTheWeek,
            status: timeclock.status,
            createdAt: timeclock.createdAt,
          }));

          setTimeClock(renderedData);
        })
        .catch((err) => {
          setTimeClock([]);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useMemo(() => {
    if (api.authenticated) {
      api
        .get('/api/v1/timecardsv2/incomplete/?page=1&limit=1000')
        .then((response) => {
          let renderedData;
          renderedData = response.data.data[0].results.map((timecard, i) => ({
            id: timecard.employeeID + i,
            employeeID: timecard.employeeID,
            firstName: timecard.firstName,
            lastName: timecard.lastName,
            fullName: timecard.fullName,
            weekEnd: dayjs(timecard.weekEnd),
            regHours: timecard.regHours,
            overtimeHours: timecard.overtimeHours,
            satHours: timecard.satHours,
            sunHours: timecard.sunHours,
            status: timecard.status,
            createdAt: timecard.createdAt,
            sunday: timecard.sunday,
            monday: timecard.monday,
            tuesday: timecard.tuesday,
            wednesday: timecard.wednesday,
            thursday: timecard.thursday,
            friday: timecard.friday,
            saturday: timecard.saturday,
            mondayHours: timecard.monday[0].hours[0].regDayTotal,
            monOTHours: timecard.monday[0].hours[0].OTDayTotal,
            tuesdayHours: timecard.tuesday[0].hours[0].regDayTotal,
            tueOTHours: timecard.tuesday[0].hours[0].OTDayTotal,
            wednesdayHours: timecard.wednesday[0].hours[0].regDayTotal,
            wedOTHours: timecard.wednesday[0].hours[0].OTDayTotal,
            thursdayHours: timecard.thursday[0].hours[0].regDayTotal,
            thurOTHours: timecard.thursday[0].hours[0].OTDayTotal,
            fridayHours: timecard.friday[0].hours[0].regDayTotal,
            friOTHours: timecard.friday[0].hours[0].OTDayTotal,
            analytics: timecard.analytics,
            approved: timecard.approved,
            paid: timecard.paid,
            updatedAt: timecard.updatedAt,
          }));
          setTimecards(renderedData);
        })
        .catch((err) => {
          console.log(err);
          setTimecards([]);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  //compile time clock details
  useEffect(() => {
    //determine whether post request or put request
    if (timeClock.length > 0) {
      // setUpdateAPI(compileTimeClock())
    } else if (timeClock.length === 0) {
      return;
    }
  }, [timeClock.length]);

  //Number Pad Function
  const onSubmit = (value) => {
    //check if value in array
    console.log('clicked');
    let updatedDisplayString;
    if (value === 'Clear') {
      updatedDisplayString = '';
    } else if (value === 'Del') {
      updatedDisplayString = display.slice(0, -1);
    } else if (value === 'Start') {
      let found;
      if (display) {
        found = personnelList.find(
          (elem) => elem.employeeID === Number(display)
        );
        console.log(found);
        setPerson(found);
      }

      const newEntry = {
        id: `${timeClock.length}-${today}` + Number(display),
        personnel: found,
        employeeID: Number(display),
        startTime: dayjs(),
        endTime: null,
        weekNumber: weekNumber,
        weekEnding: dayjs().weekday(6).format('MM-DD-YYYY'),
        referenceDate: today,
        creditedTime: 0,
        deviceName: 'iPad#3',
        project: project1,
        dayOfTheWeek: dayjs().day(),
        status: 'Active',
      };

      const error = checkErrors(newEntry);
      console.log(error);
      console.log(status);
      console.log(newEntry);
      if (
        !error &&
        status === 'Active' &&
        typeof newEntry.personnel !== 'undefined'
      ) {
        setTimeClock((timeClock) => [...timeClock, newEntry]);
        submitTimeClock(newEntry);

        //getAllTimeClock();
      }
    } else if (value === 'Stop') {
      setDisplay('');
      const foundID = (elem) => elem.employeeID === Number(display);
      const indexToDrop = timeClock.findIndex(foundID);

      if (indexToDrop >= 0) {
        const arrayCopy = timeClock;

        //drop from active list
        const updatedArray = arrayCopy.slice(indexToDrop, -1);

        setTimeClock(updatedArray);
        //update backend
        const updateEntry = timeClock[indexToDrop];

        const start = dayjs(updateEntry.startTime);
        let personfound;
        let timeclockfound;
        console.log(personnelList);

        if (display) {
          personfound = personnelList.find(
            (elem) => elem.employeeID === Number(display)
          );
          timeclockfound = timeClock.find(
            (elem) => elem.employeeID === Number(display)
          );
          console.log(personfound, 'person found');
          setPerson(personfound);
        }

        const update = {
          employeeID: display,
          personnel: personfound,
          timeclock: timeclockfound,
          weekEnding: dayjs().weekday(6).format('MM-DD-YYYY'),
          endTime: dayjs().format('MMMM D, YYYY h:mm A'),
          creditedTime: dayjs().diff(start, 'hour', true),
          dayOfTheWeek: dayjs().day(),
          status: 'Closed',
        };
        const final = { ...updateEntry, ...update };
        updateTimeClock(final);
      } else if (indexToDrop > 0) {
        //run code
        const arrayCopy = timeClock;

        //drop from active list
        const updatedArray = arrayCopy.splice(indexToDrop, 1);
        setTimeClock(updatedArray);
        //update backend
        const updateEntry = timeClock[indexToDrop];
        const start = dayjs(updateEntry.startTime);

        const update = {
          employeeID: display,
          endTime: dayjs().format('MMMM D, YYYY h:mm A'),
          creditedTime: dayjs().diff(start, 'hour', true),
        };
        const final = { ...updateEntry, ...update };
        updateTimeClock(final);
      }
    } else {
      if (display) {
        updatedDisplayString = display + value;
      } else {
        updatedDisplayString = value;
      }
    }

    //add value to array
    setDisplay(updatedDisplayString);
  };

  // TimeClock Post
  const submitTimeClock = async (details) => {
    console.log('clicked');
    try {
      const timeclock = await api.post('/api/v1/timeclock', details);
      setTimecardCreated(true);
      setTimecardCreatedMsg(
        `Timecard Created for ${timeclock?.personnel?.fullName}`
      );
    } catch (error) {
      seterrState(true);
      seterrMsg(error.response?.data?.error);
      const foundIndex = timeClock.findIndex((elem) => elem.id === details.id);
      const updatedArray = timeClock.slice(foundIndex, -1);
      setTimeClock(updatedArray);
    }
  };

  // TimeClock Post
  const getAllTimeClock = async () => {
    try {
      await api
        .get(`/api/v1/timeclock?project=${project1}&status=Active`)
        .then((response) => {
          let renderedData;
          renderedData = response.data.data.map((timeclock, i) => ({
            id: timeclock.employeeID + i,
            mongoObjectID: timeclock._id,
            employeeID: timeclock.employeeID,
            startTime: timeclock.startTime,
            endTime: timeclock.endTime,
            referenceDate: timeclock.referenceDate,
            creditedTime: timeclock.creditedTime,
            deviceName: timeclock.deviceName,
            fullName: timeclock.fullName,
            project: timeclock.project,
            dayOfTheWeek: timeclock.dayOfTheWeek,
            status: timeclock.status,
          }));

          setTimeClock(renderedData);
        });
    } catch (error) {}
  };

  // Update Post - url, data, config
  const updateTimeClock = async (details) => {
    try {
      await api.put('/api/v1/timeclock', details);
    } catch (error) {}
  };

  //Error Handling
  const checkErrors = (newEntry) => {
    setProject1Error(false);
    let error = false;
    //check if project empty
    if (newEntry.project === '') {
      setProject1Error(true);
      seterrState(true);
      seterrMsg('Please select a project ');
      error = true;
    } else if (
      //check if display empty
      newEntry.employeeID === '' ||
      newEntry.employeeID === undefined ||
      newEntry.employeeID === null
    ) {
      seterrState(true);
      seterrMsg('Please Enter An ID ');
      error = true;
    } else if (status === 'Closed') {
      seterrState(true);
      seterrMsg('Timeclock is now closed');
    } else {
      const result = timeClock.find(
        (elem) =>
          elem.employeeID === Number(display) && elem.status === 'Active'
      );

      if (result) {
        seterrState(true);
        seterrMsg(
          'There is already an active timeclock with that Employee ID '
        );
        error = true;
      }
    }
    return error;
  };

  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{ minHeight: '100vh' }}>
      <Grid item xs={3}>
        <Box>
          <PivotBox>
            <EditPayrollPopOver
              details={timecardPopoverDetails}
              anchorElPopOut={anchorElEditPayrollPopOut}
              openPopOut={openEditPayrollPopOut}
              idPopOut={idEditPayrollPopOut}
              setAnchorElPopOut={setanchorElEditPayrollPopOut}
              user={user}
            />

            <EditTimeClockModal
              // details={timeclockPopoverDetails}
              // anchorElPopOut={anchorElEditTimeclockPopOut}
              // openPopOut={openEditTimeclockPopOut}
              // idPopOut={idEditTimeclockPopOut}
              // setAnchorElPopOut={setanchorElEditTimeclockPopOut}
              // user={user}
              open={open}
              handleClose={handleClose}
              details={timeclockPopoverDetails}
            />

            <EditTimeCardModal
              open={openTC}
              handleClose={handleCloseTC}
              details={timeclockPopoverDetails}
            />

            <Stack
              style={{
                justifyContent: 'center',
                width: isMobile ? '100%' : '240px',
              }}>
              <Box mt='0.5rem'>
                <Box sx={{ border: 'solid', p: 1 }}>
                  <Stack direction='row' spacing={1}>
                    <TimeClockLabels />
                    <Box sx={{ width: isMobile ? '30%' : '70%' }}>
                      <Stack direction='row>' justifyContent='left'>
                        {' '}
                        <Box textAlign='center'>
                          <CircleIcon
                            fontSize='small'
                            sx={{ color: dot ? 'green' : 'red' }}></CircleIcon>
                        </Box>
                        <Typography> iPad </Typography>{' '}
                      </Stack>
                      <CreateDropdownComponent
                        error={project1Error}
                        data={projectList}
                        state={project1}
                        label='Project'
                        callBack={setProject1}
                        id='project1'
                      />
                      <Typography> {today} </Typography>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            {...projectStartTime}
                            value={projectStartTime}
                            renderInput={(params) => {
                              return <TextField {...params} />;
                            }}
                            slots={{
                              OpenPickerIcon: (props) => (
                                <AccessTimeIcon {...props} />
                              ),
                            }}
                            referenceDate={projectStartTime}
                            slotsProps={{
                              actionBar: {
                                actions: ['clear'],
                              },
                            }}
                            onChange={(e) => {
                              const obj = { type: 'Start', value: e };
                              changeStatus(obj);
                              setprojectStartTime(e);
                            }}
                            sx={{
                              '& .MuiInputBase-input': {
                                m: 0,
                                p: 0,
                                color: 'black',
                              },
                              '& .MuiOutlinedInput-input': {
                                color: colors.greenAccent[300],
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            {...projectEndTime}
                            value={projectEndTime}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  sx={{
                                    svg: { color: colors.redAccent[500] },
                                    input: { color: colors.redAccent[500] },
                                    label: { color: colors.redAccent[500] },
                                  }}
                                />
                              );
                            }}
                            slots={{
                              OpenPickerIcon: (props) => (
                                <AccessTimeIcon {...props} />
                              ),
                            }}
                            referenceDate={projectEndTime}
                            slotsProps={{
                              actionBar: {
                                actions: ['clear'],
                              },
                            }}
                            onChange={(e) => {
                              const obj = { type: 'End', value: e };
                              changeStatus(obj);
                              setprojectEndTime(e);
                            }}
                            sx={{
                              '& .MuiInputBase-input': {
                                m: 0,
                                p: 0,
                                color: 'black',
                              },
                              '& .MuiOutlinedInput-input': {
                                color: colors.redAccent[300],
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Typography> {weekEnding} </Typography>
                      <Typography> 8 Hours </Typography>
                      <TimeClockDisplay
                        sx={{
                          color:
                            isWorkingHours === true
                              ? colors.greenAccent[500]
                              : colors.redAccent[500],
                        }}
                      />
                      <Typography
                        sx={{
                          color:
                            isWorkingHours === true
                              ? colors.greenAccent[500]
                              : colors.redAccent[500],
                        }}>
                        {isWorkingHours === true ? 'Active' : 'Closed'}
                      </Typography>
                      <Typography> {display} </Typography>
                    </Box>
                    <Box sx={{ width: isMobile ? '30%' : '0px' }}></Box>
                  </Stack>
                </Box>
                <TimeClockNumberPad
                  color={colors.greenAccent[500]}
                  onSubmit={onSubmit}
                />{' '}
              </Box>
            </Stack>

            <Box mt='0.5rem' height='450px' width={isMobile ? '100%' : '240px'}>
              <DataGrid
                rowHeight={35}
                columnHeaderHeight={25}
                hideFooter={true}
                rows={timeClock}
                columns={timeclockColumns}
              />
              <TimeClockOptionsPopover
                details={popoverDetails}
                anchorElPopOut={anchorElPopOut}
                openPopOut={openPopOut}
                idPopOut={idPopOut}
                setAnchorElPopOut={setAnchorElPopOut}
                handleOpen={handleOpen}
                settimeclockPopoverDetails={settimeclockPopoverDetails}
                //timeclock={timeclock}
              />
            </Box>

            <Box margin='auto'>
              <TimeCardOptionsPopover
                details={timecardPopoverDetails}
                anchorElPopOut={anchorElTimecardPopOut}
                openPopOut={openTimecardPopOut}
                idPopOut={idTimecardPopOut}
                setAnchorElPopOut={setAnchorElTimecardPopOut}
                handlePopOutClickEditPayroll={handlePopOutClickEditPayroll}
                //timeclock={timeclock}
              />
            </Box>
          </PivotBox>

          <Box sx={{ py: 1, height: '300px', width: '500px' }}>
            <DataGrid
              rowHeight={35}
              columnHeaderHeight={25}
              hideFooter={true}
              rows={timecards}
              columns={timecardColumns}
            />
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography>To Do List: </Typography>
            <Typography>1. Re Do Edit Timecard Modal </Typography>
            <Typography>2. projects total </Typography>
            <Typography>3. submit new timecard , update analytics</Typography>
          </Box>

          {/* <CssBaseline /> */}

          <Stack
            spacing={1}
            direction='column'
            sx={{
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '500px',
            }}>
            {timecards.length > 0 &&
              timecards.map((timecard, index) => (
                <TimecardCard
                  key={index}
                  category={'Pending Timecard'}
                  title={timecard.fullName}
                  rareFind={timecard.paid}
                  liked={timecard.paid}
                  regHours={timecard.regHours}
                  overtimeHours={timecard.overtimeHours}
                  satHours={timecard.satHours}
                  sunHours={timecard.sunHours}
                  approved={timecard.approved}
                  fullName={timecard.fullName}
                  firstName={timecard.firstName}
                  lastName={timecard.lastName}
                  weekEnd={timecard.weekEnd.format('MM/DD/YYYY')}
                  image={
                    'https://images.unsplash.com/photo-1537726235470-8504e3beef77?auto=format&fit=crop&w=400'
                  }
                  handleOpen={handleOpenTC}
                />
              ))}
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TimeClock;
