import { Box, Typography, useTheme } from "@mui/material";
import Modal from "@mui/material/Modal";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import TwoToneInlineText from "./TwoToneInlineText";
// import { tokens } from "../theme";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { useAuth } from "../context/AuthProvider";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const calculateElapsedTime = (details) => {
	let hours;
	if (details.startTime) {
		const start = dayjs(details.startTime);
		const end = dayjs(details.endTime);
		const diff = end.diff(start); //milliseconds
		hours = diff / 3600000;
	}

	return hours;
};

const EditTimeClockModal = ({ open, handleClose, details }) => {
	const [formatted, setFormatted] = useState({});
	const { api } = useAuth();
	const handleUpdateData = async (id) => {
		try {
			const result = await updateData(id, `/api/v1/timeclock/complete/`, {
				formatted,
			});
			console.log("Update Successful:", result);
		} catch (error) {
			console.error("Error updating data:", error);
			// Show user-friendly error message
		}
	};

	useEffect(() => {
		if (details) {
			setFormatted({
				...details, // Copy all fields from details
				startTime: dayjs(details.startTime),
				endTime: dayjs(details.startTime).add(8.5, "hour"),
				creditedTime: 8,
				referenceDate: dayjs(details.referenceDate),
				dayOfTheWeek: dayjs(details.referenceDate).day(),
			});
		}
	}, [details]); // Run this effect whenever details changes

	useEffect(() => {
		if (formatted.endTime) {
			const hoursChange = calculateElapsedTime(formatted);
			setFormatted((prev) => ({
				...prev,
				creditedTime: hoursChange,
			}));
		}
	}, [formatted.endTime]);

	const updateData = async (id, url, data) => {
		const path = url + id;
		try {
			const response = await api.put(path, data);
			console.log(response.data);
			return response.data;
		} catch (error) {
			// Centralized error handling
			throw error.response?.data || error.message;
		}
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography id="modal-modal-title" variant="h6" component="h2">
					Set Ending Time
				</Typography>

				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DemoContainer
						components={["TimePicker", "TimePicker", "TimePicker"]}
					>
						<DemoItem label={'"hours", "minutes"'}>
							<TimePicker
								views={["hours", "minutes"]}
								onChange={(e) => {
									const date = e;

									const hoursChange = calculateElapsedTime(formatted);
									// Immediately apply the update
									setFormatted((prev) => ({
										...prev,
										creditedTime: hoursChange,
										endTime: date,
									}));
								}}
								referenceDate={dayjs(details.startTime).add(8.5, "hour")}
								defaultValue={dayjs(details.startTime).add(8.5, "hour")}
							/>
						</DemoItem>
					</DemoContainer>
				</LocalizationProvider>

				<TwoToneInlineText
					text1="Name"
					text2={formatted?.personnel?.fullName}
				/>
				<TwoToneInlineText text1="Project" text2={formatted?.project} />
				<TwoToneInlineText
					text1="Start Time"
					text2={" " + formatted.startTime?.format("MM/DD/YYYY hh:mm a")}
				/>
				<TwoToneInlineText
					text1="End Time"
					text2={" " + formatted.endTime?.format("MM/DD/YYYY hh:mm a")}
				/>
				<TwoToneInlineText
					text1="Gross Time"
					text2={" " + formatted?.creditedTime?.toFixed(2) + " hours"}
				/>
				<TwoToneInlineText text1="Break" text2="30 Min" />

				<Button
					variant="contained"
					// sx={{ backgroundColor: colors.greenAccent[500], m: 1 }}
					disabled={details._id ? false : true}
					onClick={() => handleUpdateData(details._id)}
					endIcon={<SendIcon />}
				>
					Mark Complete
				</Button>
			</Box>
		</Modal>
	);
};

export default EditTimeClockModal;
