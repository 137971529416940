import { useState, Fragment, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TopBarAdmin from './../components/TopBarAdmin';
import ChooseTimeCardsForPayroll from './../components/RunPayroll/ChooseTimeCardsForPayroll';
import SetRatesTimeCard from './../components/RunPayroll/SetRatesTimeCard';
import { useAuth } from '../context/AuthProvider';

import dayjs from 'dayjs';

const steps = ['Choose Timecards For Payroll', 'Select Rate Types', 'Approve'];

const RunPayrollPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [notificationsNumber, setNotificationsNumber] = useState();
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const { api } = useAuth();
  const isoWeek = require('dayjs/plugin/isoWeek');
  dayjs.extend(isoWeek);
  const [timecards, setTimecards] = useState([]);
  const lastWeekend = dayjs().day() === 6 ? dayjs() : dayjs().day(6 - 7);
  const [searchFilters, setSearchFilters] = useState({
    approved: false,
    status: 'Incomplete',
    project: 'All Projects',
    weekNumberStart: dayjs().subtract(12, 'week').isoWeek(),
    weekStartString: dayjs().subtract(4, 'week').day(6).format('MM/DD/YYYY'),
    yearStart: dayjs().subtract(4, 'week').year(),
    weekNumberEnd: lastWeekend.isoWeek(),
    weekEndString: lastWeekend.day(6).format('MM/DD/YYYY'),
    yearEnd: lastWeekend.year(),
  });
  const [filteredTimecards, setFilteredTimecards] = useState([]);
  const [selectedDataID, setselectedDataID] = useState([]);
  const [errMsg, seterrMsg] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    totaldocuments: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  //snackbar position
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [listData, setListData] = useState(null);
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleClick = (newState) => () => {
    setState({ ...newState, open: true });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    // Check for null or undefined arrays
    if (timecards && selectedDataID) {
      const filteredArray = timecards.filter(
        (obj) => obj && obj.id !== undefined && selectedDataID.includes(obj.id)
      );
      setFilteredTimecards(filteredArray);
    } else {
      console.log('One of the arrays is null or undefined.');
    }
  }, [selectedDataID, timecards]);

  useEffect(() => {
    setIsLoading(true);
    let renderedData = [];
    if (api.authenticated) {
      api

        .get(
          `/api/v1/timecardsv3/mytimecards/?page=1&limit=20&approved=${searchFilters.approved}&status=${searchFilters.status}&project=${searchFilters.project}&weekNumberStart=${searchFilters.weekNumberStart}&yearStart=${searchFilters.yearStart}&weekNumberEnd=${searchFilters.weekNumberEnd}&yearEnd=${searchFilters.yearEnd}`
        )
        .then((response) => {
          if (response?.data?.data[0]?.results) {
            renderedData = response?.data?.data[0].results.map((timecard) => {
              const formattedTimecard = {
                ...timecard,
                id: timecard._id,
              };
              return formattedTimecard;
            });

            setPagination((prev) => ({
              ...prev,
              page: response.data.data[0].page,
              limit: response.data.data[0].limit,
              totaldocuments: response.data.data[0].totaldocuments,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
          setTimecards([]);
          setPagination((prev) => ({
            ...prev,
            page: 1,
            totaldocuments: 0,
          }));
        })
        .finally(() => {
          setIsLoading(false);
          setTimecards(renderedData);
          if (renderedData.length === 0) {
            setError(true);
            seterrMsg('No Timecards Found');
            setPagination((prev) => ({
              ...prev,
              page: 1,
              totaldocuments: 0,
            }));
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, searchFilters]);

  function checkFailed(then) {
    return function (responses) {
      const someFailed = responses.some((response) => response?.error);

      if (someFailed) {
        throw responses;
      }

      return then(responses);
    };
  }

  useEffect(() => {
    setIsLoading(true);
    if (api.authenticated) {
      Promise.all([
        api.get('/api/v1/personnel?sort=fullName&limit=1000'),
        api.get('/api/v1/projects/list'),
      ])
        .then(
          checkFailed(([personnel, projects]) => {
            setListData((prev) => ({
              ...prev,
              personnel,
              projects,
            }));
          })
        )
        .catch((err) => {
          console.log('FAIL', err);
          setError(true);
          seterrMsg('Error Loading Data');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <Box sx={{ alignItems: 'center', width: '100%', height: '100%', p: 1 }}>
      <TopBarAdmin notificationsNumber={notificationsNumber} />
      <Box sx={{ margin: 'auto', width: '80%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant='caption'>Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 && (
          <Box sx={{ margin: 'auto', width: '100%', height: '500px' }}>
            <ChooseTimeCardsForPayroll
              timecards={timecards}
              isLoading={isLoading}
              selectedDataID={selectedDataID}
              setselectedDataID={setselectedDataID}
            />
          </Box>
        )}
        {activeStep === 1 && (
          <Box sx={{ margin: 'auto', width: '100%', height: '500px' }}>
            {filteredTimecards?.length > 0 &&
              filteredTimecards.map((timecard, index) => (
                <SetRatesTimeCard
                  key={index}
                  category={timecard.status}
                  title={timecard.fullName}
                  rareFind={timecard.paid}
                  liked={timecard.paid}
                  regHours={timecard.calculations.regHours}
                  overtimeHours={timecard.calculations.overtimeHours}
                  satHours={timecard.calculations.satHours}
                  sunHours={timecard.calculations.sunHours}
                  approved={timecard.approved}
                  fullName={timecard.fullName}
                  firstName={timecard.firstName}
                  lastName={timecard.lastName}
                  status={timecard.status}
                  weekEnd={timecard.weekEndingString}
                  image={
                    'https://images.unsplash.com/photo-1537726235470-8504e3beef77?auto=format&fit=crop&w=400'
                  }
                  timecard={timecard}
                  listData={listData}
                  handleSnackbar={handleClick}
                  timecards={timecards}
                  setTimecards={setTimecards}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              ))}
          </Box>
        )}
        {activeStep === steps.length ? (
          <Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <Typography sx={{ mt: 4, mb: 1 }}>Step {activeStep + 1}</Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color='inherit'
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {isStepOptional(activeStep) && (
                <Button color='inherit' onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

export default RunPayrollPage;
