import { Box, Typography, Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';

const EditTimeCardWeekLabels = ({
  show,
  height,
  weekEnding,
  project,
  handleChange,
  touched,
  projectList,
  isMobile,
}) => {
  const [displayShown, setDisplayShown] = useState({});
  const [sunday, setSunday] = useState(
    dayjs(weekEnding).day(0).format('MM/DD')
  );
  useEffect(() => {
    setDisplayShown(show === true ? 'block' : 'none');
  }, [show]);

  useEffect(() => {
    setSunday(dayjs(weekEnding).day(0).format('MM/DD'));
  }, [weekEnding]);

  return (
    <Box sx={{ mt: '1rem', display: displayShown }}>
      <Stack spacing={1.3} sx={{ height: height, pr: 1 }}>
        <FormControl sx={{ width: isMobile ? '120px' : '100%' }} size='small'>
          <InputLabel id='demo-select-small-label'>
            {isMobile ? 'Project' : 'Select A Project'}
          </InputLabel>

          <Select
            labelId='demo-select-small-label'
            id='demo-select-small'
            value={project}
            label='Age'
            onChange={handleChange}>
            {projectList?.length > 0 &&
              projectList.map((proj, index) => (
                <MenuItem key={index} value={proj}>
                  {proj}
                </MenuItem>
              ))}
            <MenuItem value={null}>
              <em>Please Select A Project</em>
            </MenuItem>
          </Select>
        </FormControl>
        <Box>
          <Typography>Sunday</Typography>
          <Typography>{sunday}</Typography>
        </Box>
        <Box>
          <Typography>Monday</Typography>
          <Typography>{dayjs(weekEnding).day(1).format('MM/DD')}</Typography>
        </Box>
        <Box>
          <Typography>Tuesday</Typography>
          <Typography>{dayjs(weekEnding).day(2).format('MM/DD')}</Typography>
        </Box>
        <Box>
          <Typography>Wednesday</Typography>
          <Typography>{dayjs(weekEnding).day(3).format('MM/DD')}</Typography>
        </Box>
        <Box>
          <Typography>Thursday</Typography>
          <Typography>{dayjs(weekEnding).day(4).format('MM/DD')}</Typography>
        </Box>
        <Box>
          <Typography>Friday</Typography>
          <Typography>{dayjs(weekEnding).day(5).format('MM/DD')}</Typography>
        </Box>
        <Box>
          <Typography>Saturday</Typography>
          <Typography>{dayjs(weekEnding).day(6).format('MM/DD')}</Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default EditTimeCardWeekLabels;
