//mui typography two tone inline text
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const TwoToneInlineText = ({ text1, text2 }) => {
	return (
		<Box>
			<Box display="inline">
				<Typography variant="body1" component="span" color="primary">
					{text1}
				</Typography>
				<Typography variant="body1" component="span" color="primary">
					{": "}
				</Typography>
				<Typography variant="body1" component="span" color="secondary">
					{text2}
				</Typography>
			</Box>
		</Box>
	);
};

export default TwoToneInlineText;
